import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import { useForm } from "../util/hooks";
import { LOGIN_CUSTOMER } from "./AccountDetails";

function Terms({ initialUserType }) {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Terms | StashCampus";
  }, []);
  const { guest, logoutGuest } = useContext(GuestAuthContext);

  const {
    customer,
    logoutCustomer,
    loginCustomer: loginCust,
  } = useContext(CustomerAuthContext);

  const { values } = useForm(loginCallback, {
    email: "",
    password: "",
    payPalEmail: "",
    shouldReceivePromoEmails: false,
  });
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER, {
    refetchQueries: [],
    update(proxy, { data: { loginCustomer: loggedInCust } }) {
      console.log("Login success!");

      logoutGuest();
      loginCust(loggedInCust);
    },
    onError(err) {
      console.log("loginCustomer() Error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        // setErrors({
        //   ...err.graphQLErrors[0].extensions.errors,
        // });
      }
    },
    variables: values,
    client: guest ? guestClient : customerClient,
  });

  function loginCallback() {
    loginCustomer();
  }

  const [openFAQBoxes, setOpenFAQBoxes] = useState([]);

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar page="faq" />

      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
      {[1, 2, 3, 4, 5].map((_, index) => (
        <button
          className={`${
            index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
          } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
          key={index}
        >
          <img
            src={profilePic}
            className="border-white border-2 box-border rounded-full "
          />
        </button>
      ))}
      <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
        <p className="text-3xl text-black font-semibold">Someboody Sum</p>
        <p className="text-lg text-gray-600">
          Spacer since 06/22 - Earns US$100/mo
        </p>
      </div>
    </div> */}
      <div className="w-full flex flex-1 flex-col items-center justify-start">
        <div className="flex flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start pb-8 sm:py-14 ">
          <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start pb-8 sm:py-14 ">
            <div className="w-full flex flex-col">
              <div className="w-full z-10 flex flex-col">
                <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                  Terms and Conditions
                </p>

                <div className="flex items-start justify-start space-y-4 w-full flex-col mt-6">
                  <div
                    className={`${
                      openFAQBoxes.includes("Contractual Relationship")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (
                          !openFAQBoxes.includes("Contractual Relationship")
                        ) {
                          setOpenFAQBoxes([
                            ...openFAQBoxes,
                            "Contractual Relationship",
                          ]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex = openFAQBoxes.indexOf(
                            "Contractual Relationship"
                          );
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        Contractual Relationship
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("Contractual Relationship")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("Contractual Relationship")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>User Agreement:</b>
                      <br />
                      By accessing or using StashCampus ("the Platform"), you
                      agree to be bound by these Terms of Service. <br />
                      <b>Eligibility:</b>
                      <br />
                      Users must be students of a verified college or university
                      to access and use the Platform.
                      <br /> <b>Contracting Entity:</b>
                      <br />
                      The contractual relationship is between the user and
                      StashCampus.
                    </div>
                  </div>
                  <div
                    className={`${
                      openFAQBoxes.includes("Arbitration Agreement")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (!openFAQBoxes.includes("Arbitration Agreement")) {
                          setOpenFAQBoxes([
                            ...openFAQBoxes,
                            "Arbitration Agreement",
                          ]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex = openFAQBoxes.indexOf(
                            "Arbitration Agreement"
                          );
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        Arbitration Agreement
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("Arbitration Agreement")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("Arbitration Agreement")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>Dispute Resolution:</b>
                      <br /> Any disputes or claims arising from or related to
                      the use of the Platform will be resolved through binding
                      arbitration. <br />
                      <b>Waiver of Class Action:</b>
                      <br />
                      Users waive their right to participate in class-action
                      lawsuits and agree to individual arbitration.
                    </div>
                  </div>
                  <div
                    className={`${
                      openFAQBoxes.includes("The Spaces Platform")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (!openFAQBoxes.includes("The Spaces Platform")) {
                          setOpenFAQBoxes([
                            ...openFAQBoxes,
                            "The Spaces Platform",
                          ]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex = openFAQBoxes.indexOf(
                            "The Spaces Platform"
                          );
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        The Spaces Platform
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("The Spaces Platform")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("The Spaces Platform")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>Listing Spaces:</b>
                      <br />
                      Users can list their living spaces for rent on the
                      Platform.
                      <br />
                      <b>Booking and Payments:</b>
                      <br />
                      Users can book spaces for storage and make payments
                      through the Platform.
                      <br />
                      <b>Pickup/Delivery:</b>
                      <br />
                      StashCampus staff will manage pickup and delivery,
                      ensuring a secure and convenient process.
                    </div>
                  </div>
                  <div
                    className={`${
                      openFAQBoxes.includes("Access and use")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (!openFAQBoxes.includes("Access and use")) {
                          setOpenFAQBoxes([...openFAQBoxes, "Access and use"]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex =
                            openFAQBoxes.indexOf("Access and use");
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        Access and use
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("Access and use")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("Access and use")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>User Obligations:</b>
                      <br />
                      Users must provide accurate information, abide by
                      community guidelines, and comply with all applicable laws.{" "}
                      <br /> <b>Prohibited Conduct:</b>
                      <br /> Users may not engage in activities that violate the
                      law or infringe on the rights of others. <br />{" "}
                      <b>Account Security:</b> <br />
                      Users are responsible for maintaining the security of
                      their accounts.
                    </div>
                  </div>
                  <div
                    className={`${
                      openFAQBoxes.includes("Disclaimers")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (!openFAQBoxes.includes("Disclaimers")) {
                          setOpenFAQBoxes([...openFAQBoxes, "Disclaimers"]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex = openFAQBoxes.indexOf("Disclaimers");
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        Disclaimers
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("Disclaimers")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("Disclaimers")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>Use at Own Risk:</b>
                      <br />
                      Users use the Platform at their own risk, and StashCampus
                      is not responsible for the actions of users. <br />{" "}
                      <b>Limitation of Liability: </b>
                      <br />
                      StashCampus is not liable for any direct, indirect,
                      incidental, special, consequential, or exemplary damages.
                    </div>
                  </div>

                  <div
                    className={`${
                      openFAQBoxes.includes("Privacy Policy")
                        ? "shadow-xl"
                        : "h-24 shadow-sm"
                    } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                  >
                    <button
                      onClick={(e) => {
                        if (!openFAQBoxes.includes("Privacy Policy")) {
                          setOpenFAQBoxes([...openFAQBoxes, "Privacy Policy"]);
                        } else {
                          var copiedFAQBoxes = [...openFAQBoxes];
                          const valIndex =
                            openFAQBoxes.indexOf("Privacy Policy");
                          if (valIndex > -1) {
                            // only splice array when item is found
                            copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                          }
                          setOpenFAQBoxes(copiedFAQBoxes);
                        }
                      }}
                      className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                    >
                      <h1 className="text-xl sm:text-2xl font-semibold">
                        Privacy Policy
                      </h1>
                      <svg
                        className={`${
                          openFAQBoxes.includes("Privacy Policy")
                            ? "rotate-180"
                            : ""
                        } transform transition-all h-2 fill-current`}
                        viewBox="0 0 24 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${
                        openFAQBoxes.includes("Privacy Policy")
                          ? "scale-y-100 px-4 sm:px-12"
                          : "scale-y-0 "
                      } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                    >
                      <b>Information Collection:</b>
                      <br /> StashCampus collects user information for account
                      creation, communication, and service improvement. <br />{" "}
                      <b>Information Use:</b>
                      <br /> User information is used to facilitate bookings,
                      improve services, and ensure a secure environment. <br />{" "}
                      <b>Information Sharing:</b>
                      <br /> User information is shared with StashCampus staff
                      for pickup/delivery and with relevant parties for dispute
                      resolution. StashCampus maitains privacy between students.
                      <br />
                      <b>Security:</b>
                      <br /> StashCampus employs industry-standard security
                      measures to protect user information. <br />
                      <b>Cookies:</b>
                      <br /> The Platform uses cookies for functionality and
                      analytics. <br /> <b>Changes to thePrivacy Policy:</b>
                      <br /> Users will be notified of any changes to the
                      Privacy Policy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logoutCustomer}
        keepDefaultColor={true}
      />
    </div>
  );
}

export default Terms;
