import React from "react";
import klarnaLogo from "../images/klarna.jpeg";

export default function PaymentPlanSelection({
  title,
  description,
  errors,
  values,
  setValues,
  disabled,
}) {
  // const error = 1 ? null : '- Required!'
  return (
    <div className="w-full relative">
      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          Payment plan
          {errors && errors["paymentPlan"] ? (
            <span className="text-red-600 ml-1">{errors["paymentPlan"]}</span>
          ) : (
            ""
          )}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="w-full h-12  gap-2 sm:gap-3 grid grid-cols-7">
        <button
          disabled={disabled}
          onClick={(e) => {
            setValues({ ...values, paymentPlan: "LATER" });
          }}
          className={`${
            values.paymentPlan === "LATER"
              ? "bg-purple-600 text-white"
              : !disabled
              ? "bg-white hover:bg-purple-500 hover:text-white"
              : "bg-white"
          } ${
            disabled && "bg-opacity-70"
          } flex items-center justify-center gap-2 col-span-3 h-full px-2 shrink-0 text-center transition-all rounded shadow-sm`}
        >
          <p>Later </p>{" "}
          <img
            src={klarnaLogo}
            className="transition-all object-cover w-5 rounded-md"
            alt="Klarna Logo"
          />
        </button>
        <button
          disabled={disabled}
          onClick={(e) => {
            setValues({ ...values, paymentPlan: "NOW" });
          }}
          className={`${
            values.paymentPlan === "NOW"
              ? "bg-purple-600 text-white"
              : !disabled
              ? "bg-white hover:bg-purple-500 hover:text-white"
              : "bg-white"
          } ${
            disabled && "bg-opacity-70"
          } h-full col-span-4 px-5 shrink-0 text-center  transition-all rounded shadow-sm`}
        >
          Upfront
        </button>
      </div>
    </div>
  );
}
