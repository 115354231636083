import React from "react";
import { SIZE_TO_MONTHLY_RATE, getNumOfMonthsBetween } from "../util/hooks";

export default function PaymentDetails({
  payment,
  values,
  targetCustomer,
  bookingStep,
}) {
  const startDate = new Date(values.startDate);
  const endDate = new Date(values.endDate);

  const lengthOfBookingInMonths = getNumOfMonthsBetween(startDate, endDate);

  var monthlyRate = SIZE_TO_MONTHLY_RATE[values.size];
  const subtotal = Math.round(monthlyRate * lengthOfBookingInMonths);

  const referreeDiscount = 0;
  const referrerDiscount =
    payment && payment.referreeDiscount
      ? payment.referreeDiscount
      : targetCustomer
      ? targetCustomer.pendingReferrals > 0
        ? 10 * 100
        : 0
      : 0;
  const tax = Math.round(subtotal * 0.13);
  const tip = 0;
  const total = subtotal + tip + tax - referreeDiscount - referrerDiscount;

  return (
    <>
      <div className="flex px-6 sm:px-10 mt-6 text-gray-500 text-base items-center justify-between space-x-2">
        <p>Subtotal</p>
        <p className="font-semibold">{`${
          (bookingStep === "booking-details" ||
            bookingStep === "student-info") &&
          subtotal >= 0
            ? "$" + (subtotal / 100).toFixed(2)
            : payment?.subtotal
            ? "$" + (payment.subtotal / 100).toFixed(2)
            : "—"
        }`}</p>
      </div>

      <div className="flex mt-4 px-6 sm:px-10 text-gray-500 text-base items-center justify-between space-x-2">
        <p>Taxes</p>
        <p className="font-semibold">{`${
          (bookingStep === "booking-details" ||
            bookingStep === "student-info") &&
          tax >= 0
            ? "$" + (tax / 100).toFixed(2)
            : payment?.tax
            ? "$" + (payment.tax / 100).toFixed(2)
            : "—"
        }`}</p>
      </div>

      {/* <div className="flex mt-4 px-6 sm:px-10 text-gray-500 text-base items-center justify-between space-x-2">
        <p>Tip</p>
        <p className="font-semibold">{`${
          payment?.tip ? "$" + payment.tip.toFixed(2) : "—"
        }`}</p>
      </div> */}

      {payment?.referreeDiscount !== undefined &&
        (payment.referreeDiscount !== 0 || payment.referrerDiscount !== 0) && (
          <div className="flex px-6 sm:px-10 flex-col text-green-500 text-base space-y-1">
            <div className="flex mt-4  items-center justify-between space-x-2">
              <p>Discount</p>
              <p className="font-semibold">{`${
                (bookingStep === "booking-details" ||
                  bookingStep === "student-info") &&
                referrerDiscount >= 0
                  ? "$" + (referrerDiscount / 100).toFixed(2)
                  : payment?.referrerDiscount
                  ? "$" + (payment.referrerDiscount / 100).toFixed(2)
                  : "-"
              }`}</p>
            </div>
            {payment?.referrerDiscount !== undefined &&
              payment.referrerDiscount !== 0 && (
                <p className="text-xs">-$10.00 for inviting a friend</p>
              )}
            {payment?.referreeDiscount !== undefined &&
              payment.referreeDiscount !== 0 && (
                <p className="text-xs">-$10.00 for using an invite code</p>
              )}
          </div>
        )}

      <div className="flex px-6 sm:px-10 mb-6 mt-4 text-black text-base items-center justify-between space-x-2">
        <p>Total</p>
        <p className="font-semibold">{`${
          (bookingStep === "booking-details" ||
            bookingStep === "student-info") &&
          total >= 0
            ? "$" + (total / 100).toFixed(2)
            : payment?.total
            ? "$" + (payment.total / 100).toFixed(2)
            : "—"
        }`}</p>
      </div>
    </>
  );
}
