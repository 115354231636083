import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import stashLogoWhite from "../images/stash_logo_white.svg";

// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import stashClosetImage from "../images/stash_closet.png";
import stashCornerImage from "../images/stash_corner.png";
import stashDormImage from "../images/stash_dorm.png";
import { useForm, useWindowSize } from "../util/hooks";
import { LOGIN_CUSTOMER } from "./AccountDetails";
import { FETCH_CUSTOMER } from "./BookSpace";
import {
  GET_BOOKINGS_BY_STEPS,
  GET_BOOKING_REQUESTS,
  GET_REFERRAL_CREDITS_BY_CUSTOMER,
  GET_SPACES_BY_CUSTOMER,
  GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
  GET_TOTAL_EARNINGS_BY_CUSTOMER,
} from "./Dashboard";

function Login({ initialUserType }) {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Sign in | StashCampus";
  }, []);
  const { guest, logoutGuest } = useContext(GuestAuthContext);

  const {
    customer,
    logoutCustomer,
    loginCustomer: loginCust,
  } = useContext(CustomerAuthContext);
  useWindowSize();

  useEffect(() => {
    if (customer) {
      navigate("/dashboard");
    }
  }, [customer, navigate]);

  const { values, setValues, onSubmit, onChange } = useForm(loginCallback, {
    email: "",
    password: "",
  });
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      { query: GET_SPACES_BY_CUSTOMER },
      { query: GET_TOTAL_EARNINGS_BY_CUSTOMER },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: ["CONFIRMED", "PICKED", "PAID_OWNER_HALF"],
      },
      { query: GET_BOOKING_REQUESTS },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: { steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"] },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: [
            "CREATED",
            "BOOKED",
            "PAID_OWNER_HALF",
            "CONFIRMED",
            "PICKED",
          ],
        },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["DROPPED", "CANCELLED"],
        },
      },
      { query: GET_REFERRAL_CREDITS_BY_CUSTOMER },
    ],
    update(proxy, { data: { loginCustomer: loggedInCust } }) {
      console.log("Login success!");

      logoutGuest();
      loginCust(loggedInCust);

      navigate("/dashboard");
    },
    onError(err) {
      console.log("loginCustomer() Error!");
      // console.log(values)
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: guest ? guestClient : customerClient,
  });

  function loginCallback() {
    loginCustomer();
  }

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar page="login" />

      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
      {[1, 2, 3, 4, 5].map((_, index) => (
        <button
          className={`${
            index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
          } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
          key={index}
        >
          <img
            src={profilePic}
            className="border-white border-2 box-border rounded-full "
          />
        </button>
      ))}
      <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
        <p className="text-3xl text-black font-semibold">Someboody Sum</p>
        <p className="text-lg text-gray-600">
          Spacer since 06/22 - Earns US$100/mo
        </p>
      </div>
    </div> */}
      <div className="w-full flex-1 flex flex-col items-center justify-start">
        <div className="flex mb-20 sm:mb-10 sm:flex-row flex-col items-start z-10 w-full max-w-5xl  space-y-6 sm:space-y-0 sm:space-x-10 xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="w-full flex flex-col px-4 sm:px-0">
            <div className="w-full flex flex-col space-y-4">
              <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                Sign in
              </p>
              <TextInputField
                onChange={onChange}
                title="School email"
                name="email"
                values={values}
                setValues={setValues}
                errors={errors}
              />
              <TextInputField
                onChange={onChange}
                title="Password"
                name="password"
                values={values}
                setValues={setValues}
                errors={errors}
                type={"password"}
              />
            </div>
            <SubmitButton
              onClickFunc={onSubmit}
              title={"Submit"}
              // errors={errors}
            />
            {/* TODO: Fix this and bring back. */}
            <p className="w-full mt-4 text-center font-semibold  text-purple-700">
              Forgot your password?{" "}
              <span
                className="hover:text-black font-bold cursor-pointer transition-all"
                onClick={(e) => {
                  navigate("/forgotPassword");
                }}
              >
                Reset it here
              </span>
            </p>
          </div>
          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
              <img
                src={
                  values.size === "CORNER"
                    ? stashCornerImage
                    : values.size === "CLOSET"
                    ? stashClosetImage
                    : stashDormImage
                }
                className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                alt={"Stash Room"}
              />
              {values && values.campusName ? (
                <img
                  src={
                    values.size === "CORNER"
                      ? values.cornerImageUrl
                      : values.size === "CLOSET"
                      ? values.closetImageUrl
                      : values.dormImageUrl
                  }
                  className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={values.campusName}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
              <img
                src={values.campusLogoUrl || stashLogoWhite}
                className="object-contain w-full shrink-0 h-full"
                alt={values.campusName || "StashCampus"}
              />
            </div>
            <div className="flex px-4 sm:px-10 my-6 text-gray-500 text-base items-center justify-center space-x-2">
              <p>TIP: Invite a friend to get $10 off your next booking.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logoutCustomer}
        keepDefaultColor={true}
      />
    </div>
  );
}

export default Login;
