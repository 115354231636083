import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { customerClient } from "../GraphqlApolloClients";
import { GET_CAMPUS_BY_ID, GET_PAYMENT_BY_BOOKING } from "../pages/BookSpace";
import ActionButton from "./ActionButton";
import BookingStatus from "./BookingStatus";

export function isDateStringToday(dateString) {
  const today = new Date();
  const dateToCheck = new Date(dateString);

  return (
    today.getFullYear() === dateToCheck.getFullYear() &&
    today.getMonth() === dateToCheck.getMonth() &&
    today.getDate() === dateToCheck.getDate()
  );
}

export const SLOT_TO_TIME = {
  MORNING: "9am-12pm",
  EARLY_AFTERNOON: "12-3pm",
  LATE_AFTERNOON: "3-6pm",
  EVENING: "6-9PM",
};

export function formatAddress(inputAddress) {
  const commaIndex = inputAddress.indexOf(",");
  if (commaIndex !== -1) {
    return inputAddress.substring(0, commaIndex).trim();
  }
  return inputAddress.trim(); // If there is no comma, return the whole string trimmed
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { month: "short", day: "numeric" };
  const dateFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = dateFormatter.format(date);
  const [month, day] = formattedDate.split(" ");

  // Convert the month abbreviation to the desired format (e.g., "Sept.")
  var monthAbbreviation = month.slice(0, 3);
  if (month !== "May") {
    monthAbbreviation += ".";
  }

  return `${monthAbbreviation} ${day}`;
}

export default function BookingBox({
  navigate,
  booking,
  openBookingBoxes,
  setOpenBookingBoxes,
  isPast,
  values,
  setValues,
}) {
  // TODO: Move more calculations to server.

  /* Any booking that is shown here is necessarily CREATED and BOOKED (i.e. paid for). */
  const { data: { getCampusById: campus } = {} } = useQuery(GET_CAMPUS_BY_ID, {
    variables: { campusId: booking.campusId },
    client: customerClient,
  });

  const {
    data: { getPaymentByBooking: payment } = {},
    // loading: loadingPayment,
    // refetch: refetchGetPaymentByBooking,
  } = useQuery(GET_PAYMENT_BY_BOOKING, {
    client: customerClient,
    variables: { bookingId: booking.id },
  });

  const { data: { getStatusesByBooking: statuses } = {} } = useQuery(
    GET_STATUSES_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
    }
  );

  const { data: { getBookingTimeline: timeline } = {} } = useQuery(
    GET_BOOKING_TIMELINE,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
    }
  );

  const { data: { getLastStatusByBooking: lastStatus } = {} } = useQuery(
    GET_LAST_STATUS_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
      pollInterval: 1000,
    }
  );

  // Note: For the intents of this page, the CANCELLED, PAID_OWNER_HALF, and PAID_OWNER_FULL states are ignored. The last state is DROPPED.

  function getDescriptionByStep(step, completed) {
    const status = completed
      ? statuses.find((status) => status.step === step)
      : null;

    switch (step) {
      case "CREATED":
        /* Necessarily completed. */
        return `Created ${booking.size.toLowerCase()} booking on ${formatDate(
          status.createdAt
        )}`;
      case "BOOKED":
        return `${booking.paymentPlan === "NOW" ? "Paid" : "Starting paying"}
              $${(payment.total / 100).toFixed(2)}
              ${booking.paymentPlan === "LATER" ? "via Klarna" : "in full"} ${
          completed ? "on " + formatDate(booking.createdAt) : ""
        }`;
      case "CONFIRMED":
        return "Space for your belongings is ready";
      case "PICKED":
        return `Pick up from ${formatAddress(
          booking.pickupFullStreetAddress
        )} ${
          completed
            ? "between " +
              SLOT_TO_TIME[booking.startTime] +
              " on " +
              formatDate(booking.startDate)
            : ""
        }`;
      case "DROPPED":
        return `Drop off at ${formatAddress(
          booking.dropoffFullStreetAddress
        )} ${
          completed
            ? "between " +
              SLOT_TO_TIME[booking.endTime] +
              " on " +
              formatDate(booking.endDate)
            : ""
        }`;
      default:
        break;
    }
  }

  useEffect(() => {
    if (campus && !values.campusName) {
      setValues({
        ...values,
        campusName: campus.name,
        campusLogoUrl: campus.logoUrl,
        dormImageUrl: campus.dormImageUrl,
        closetImageUrl: campus.closetImageUrl,
        cornerImageUrl: campus.cornerImageUrl,
      });
    }
  }, [campus, values, setValues]);

  const steps = ["CREATED", "BOOKED", "CONFIRMED", "PICKED", "DROPPED"];

  // TODO: Maybe add date to summary view in case they make identical bookings.
  return statuses && campus && timeline && lastStatus && payment && booking ? (
    <div
      className={`${
        openBookingBoxes.includes(booking.id) ? "shadow-xl" : "h-24 shadow-sm"
      } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
    >
      <button
        onClick={(e) => {
          if (!openBookingBoxes.includes(booking.id)) {
            setOpenBookingBoxes([...openBookingBoxes, booking.id]);
          } else {
            var copiedBookingBoxes = [...openBookingBoxes];
            const valIndex = openBookingBoxes.indexOf(booking.id);
            if (valIndex > -1) {
              // only splice array when item is found
              copiedBookingBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
            }
            setOpenBookingBoxes(copiedBookingBoxes);
          }
        }}
        className={`flex text-left shrink-0 relative items-center p-2 pr-3 sm:pr-6 text-purple-950  rounded justify-between w-full h-24 bg-light-beige hover:bg-opacity-80 transition-all`}
      >
        {isDateStringToday(lastStatus.createdAt) && (
          <p className="px-1 right-2 top-2 absolute font-semibold text-white rounded-sm bg-purple-950 tracking-wide uppercase text-xs">
            New
          </p>
        )}
        <div className="h-full aspect-square bg-white rounded">
          {campus && (
            <img
              className="flex-grow-0 w-full h-full top-0 object-cover p-1 z-10"
              src={
                booking.size === "DORM"
                  ? campus.dormImageUrl
                  : booking.size === "CLOSET"
                  ? campus.closetImageUrl
                  : campus.cornerImageUrl
              }
              alt={"Campus URL"}
            />
          )}
        </div>
        <div className="flex items-start relative justify-center flex-col ml-2 sm:ml-3 w-full truncate flex-1">
          <h1 className="text-xl sm:text-2xl leading-tight font-semibold truncate w-full sm:leading-tight">
            {campus?.name}
          </h1>
          <p className="sm:text-base text-sm leading-tight truncate w-full">
            <b>
              <i>{booking.size}</i> •
            </b>{" "}
            {isPast ? "Completed" : timeline[0] + " till " + timeline[1]}
          </p>
        </div>
        <svg
          className={`${
            openBookingBoxes.includes(booking.id) ? "rotate-180" : ""
          } transform transition-all h-2 fill-current shrink-0`}
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z" />
        </svg>
      </button>
      <div
        className={`${
          openBookingBoxes.includes(booking.id) ? "scale-y-100" : "scale-y-0 "
        } text-left flex flex-col sm:text-lg text-gray-600 transform origin-top rounded-b w-full px-2 pt-4 bg-white transition-all`}
      >
        <p className="sm:italic tracking-wide text-sm sm:text-base leading-tight mb-4">
          <b>Booking:</b> {booking.id}
        </p>
        <div className="flex flex-col items-start justify-start w-full h-full sm:pb-0 relative">
          <div className="w-0.5 bg-purple-950 absolute left-[0.7rem] top-2 bottom-7"></div>
          {/* We can go through each known status and show it. */}
          {steps.map((step, index) => (
            <div key={index}>
              <BookingStatus
                text={getDescriptionByStep(
                  step,
                  statuses.some((status) => status.step === step) // true if completed, false otherwise
                )}
                completed={statuses.some((status) => status.step === step)} // true if completed, false otherwise
                last={step === "DROPPED"}
              />
              {step === "PICKED" && (
                // Show additional state if the item has been picked
                <BookingStatus
                  text={"StashCampus stores your belongings with care"}
                  completed={statuses.some((status) => status.step === step)}
                  last={false}
                />
              )}
            </div>
          ))}
        </div>
        <div className="w-full pb-2 items-center justify-center grid grid-cols-2 gap-1">
          <ActionButton
            onClickFunc={(e) => {
              navigate(
                `/${
                  lastStatus.step === "CREATED" ? "bookSpace" : "editBooking"
                }/${booking.id}`
              );
            }}
            text={"Edit"}
            type={0}
            // text={lastStatus.step === 'CREATED' ? 'Complete' : 'Edit'}
          />

          <ActionButton
            onClickFunc={(e) => {
              window.location = "mailto:stashcampus@gmail.com";
            }}
            text="Contact"
            type={3}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export const GET_BOOKING_TIMELINE = gql`
  query getBookingTimeline($bookingId: String!) {
    getBookingTimeline(bookingId: $bookingId)
  }
`;
export const GET_LAST_STATUS_BY_BOOKING = gql`
  query getLastStatusByBooking($bookingId: String!) {
    getLastStatusByBooking(bookingId: $bookingId) {
      id
      bookingId
      step
      createdAt
    }
  }
`;

export const GET_STATUSES_BY_BOOKING = gql`
  query getStatusesByBooking($bookingId: String!) {
    getStatusesByBooking(bookingId: $bookingId) {
      id
      bookingId
      step
      amount
      createdAt
      description
    }
  }
`;
