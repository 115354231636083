import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";
import stashLogoWhite from "../images/stash_logo_white.svg";
import usePageTracking from "../util/usePageTracking";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AddressField from "../components/AddressField";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
import CodeForm from "../components/CodeForm";
import Footer from "../components/Footer";
import IsAvailableSelection from "../components/IsAvailableSelection";
import Loading from "../components/Loading";
import MediaInputField from "../components/MediaInputField";
import NavBar from "../components/NavBar";
import SpaceSelection from "../components/SpaceSelection";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import { CustomerAuthContext } from "../context/customerAuth";
import { customerClient } from "../GraphqlApolloClients";
import { useForm, useWindowSize } from "../util/hooks";
import { FETCH_CUSTOMER, GET_CAMPUS_BY_ID } from "./BookSpace";
import { SEND_VERIFICATION_EMAIL } from "./CreateSpace";
import {
  GET_SPACES_BY_CUSTOMER,
  GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
} from "./Dashboard";

export default function EditSpace(props) {
  const { spaceId, spaceStep } = useParams();

  let navigate = useNavigate();

  usePageTracking();

  const resourcesRef = useRef();
  const productsRef = useRef();
  const pricingRef = useRef();
  const pageTopRef = useRef();

  useEffect(() => {
    document.title = "Space details | StashCampus";
  }, []);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  useWindowSize();

  const [isVerificationCodeBoxOpen, setIsVerificationCodeBoxOpen] =
    useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  // TODO: Change this default image.
  const [previewPhotoURL, setPreviewPhotoURL] = useState(null);
  const [previewVideoURL, setPreviewVideoURL] = useState(null);

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const { values, setValues, onChange, onChangeMedia } = useForm(
    editSpaceCallback,
    {
      spaceId,
      campusId: "",
      imageUrl: "",
      size: "CLOSET",
      isAvailable: true,
      payPalEmail: "",
      campusLogoUrl: "",
      campusName: "",
      fullStreetAddress: "",
      province: "",
      country: "",
      postalCode: "",
      line2: "",
      specialInstructions: "",
      city: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      verificationKey: "",
      verified: false,
      fieldsToEdit: [],
      imageFile: null,
    }
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (targetCustomer && values.email !== targetCustomer.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        payPalEmail: targetCustomer.payPalEmail,
      });
    }
  }, [targetCustomer, setValues, values]);

  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL, {
    update() {
      console.log("sendVerificationEmail() success!");
      setIsVerificationCodeBoxOpen(!isVerificationCodeBoxOpen);
    },
    onError(err) {
      console.log("sendVerificationEmail() error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });

  const [editSpace, { loading: loadingEditSpace }] = useMutation(EDIT_SPACE, {
    refetchQueries: [
      {
        query: GET_SPACES_BY_CUSTOMER,
      },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["CONFIRMED", "PICKED", "PAID_OWNER_HALF"],
        },
      },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: { steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"] },
      },
    ],
    update() {
      console.log("editSpace() success!");
      navigate(`/dashboard/earnings`);
    },
    onError(err) {
      console.log("editSpace() error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
      if (!errors.payPalEmail) {
        // If error has to do with PayPal email, stay on this page, otherwise go back.
        navigate(`/editSpace/${values.spaceId}`);
      }
    },
    variables: {
      ...values,
    },
    client: customerClient,
  });
  function editSpaceCallback() {
    editSpace();
  }

  const { data: { getSpaceById: space } = {}, loading: loadingSpace } =
    useQuery(GET_SPACE_BY_ID, {
      variables: { spaceId },
      client: customerClient,
      onCompleted() {
        console.log("successfully completed this query!");
      },
      onError(err) {
        console.log("getSpaceById() Error!");
        console.log(err);
      },
    });

  const { data: { getCampusById: campus } = {}, refetch: refetchCampusById } =
    useQuery(GET_CAMPUS_BY_ID, {
      variables: { campusId: values.campusId },
      client: customerClient,
      onCompleted() {
        console.log("Successfully completed getCampusById()!");
      },
      onError(err) {
        console.log("getCampusById() Error!");
        console.log(err);
      },
    });

  useEffect(() => {
    if (space) {
      setValues((prevValues) => ({
        ...prevValues,
        size: space.size,
        imageUrl: space.imageUrl,
        fullStreetAddress: space.fullStreetAddress,
        province: space.province,
        postalCode: space.postalCode,
        country: space.country,
        line2: space.line2,
        specialInstructions: space.specialInstructions,
        isAvailable: space.isAvailable,
        campusId: space.campusId,
      }));
      if (space.imageUrl) {
        setPreviewPhotoURL(space.imageUrl);
      }
      refetchCampusById({ campusId: space.campusId });
    }
  }, [space, setValues, refetchCampusById]);

  useEffect(() => {
    if (campus) {
      setValues((prevValues) => ({
        ...prevValues,
        campusName: campus.name,
        campusLogoUrl: campus.logoImageUrl,
      }));
    }
  }, [campus, setValues]);

  // console.log("queried space:");
  // console.log(space);

  return loadingSpace ? (
    <Loading />
  ) : (
    <div
      ref={pageTopRef}
      className="w-screen relative bg-light-beige overflow-x-hidden h-full flex flex-col min-h-screen"
    >
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      <NavBar
        page="editSpace"
        productsRef={productsRef}
        resourcesRef={resourcesRef}
        pricingRef={pricingRef}
        pageTopRef={pageTopRef}
      />

      <CodeForm
        title={"Verification code"}
        name={"verificationKey"}
        isCodeBoxOpen={isVerificationCodeBoxOpen}
        setIsCodeBoxOpen={setIsVerificationCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={0}
        setErrors={setErrors}
      />

      <div className="w-full flex-1 flex flex-col items-center justify-start">
        <div className="flex flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="flex mb-20 sm:mb-10 w-full flex-col space-y-6 sm:space-y-0 sm:space-x-10 sm:flex-row items-start justify-start">
            <div className="w-full flex flex-col">
              {spaceStep === "space-details" || !spaceStep ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                    Space details
                  </p>

                  <CampusSearchDropdown
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <SpaceSelection
                    isForSpaces={true}
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                  />
                  <AddressField
                    values={values}
                    setValues={setValues}
                    setErrors={setErrors}
                    errors={errors}
                    onChange={onChange}
                    typeOfAddress={2}
                  />
                  <IsAvailableSelection
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                  />
                </div>
              ) : spaceStep === "student-info" ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate(`/editSpace/${values.spaceId}`);
                      }}
                      className="group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Student info
                    </p>
                  </div>

                  {/* Important: we only let them verify their emails, and that too ONLY if they change the isAvailable field. */}
                  <TextInputField
                    title="School email"
                    name="email"
                    values={values}
                    onChange={onChange}
                    setValues={setValues}
                    overlayBtnText="Verify"
                    overlayBtnLoading={false}
                    errors={errors}
                    overlayDivText={
                      values.verified ||
                      !values.fieldsToEdit.includes("isAvailable")
                        ? "Verified"
                        : null
                    }
                    disabled={
                      values.verified ||
                      !values.fieldsToEdit.includes("isAvailable")
                    }
                    onClickOverlay={(e) => {
                      e.preventDefault();
                      setErrors({});
                      sendVerificationEmail();
                      setIsVerificationCodeBoxOpen(true);
                    }}
                  />
                  <div className="grid grid-cols-2 gap-2 sm:gap-3">
                    <TextInputField
                      onChange={onChange}
                      title="First name"
                      name="firstName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      disabled={true}
                    />
                    <TextInputField
                      onChange={onChange}
                      title="Last name"
                      name="lastName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      disabled={true}
                    />
                  </div>
                  <TextInputField
                    onChange={onChange}
                    title="Phone number"
                    name="phoneNumber"
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    disabled={true}
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate(`/editSpace/${values.spaceId}/student-info`);
                      }}
                      className="group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Payment configuration
                    </p>
                  </div>

                  <TextInputField
                    onChange={onChange}
                    title="PayPal email"
                    name="payPalEmail"
                    values={values}
                    setValues={setValues}
                    errors={errors}
                  />
                </div>
              )}
            </div>

            <div className="w-full text-lg relative bg-white rounded-xl shadow p-1  space-y-1">
              <div className="bg-gray-100 rounded-lg w-full h-64">
                <MediaInputField
                  name={"imageFile"}
                  value={values.imageUrl}
                  previewPhotoURL={previewPhotoURL}
                  setPreviewPhotoURL={setPreviewPhotoURL}
                  onChangeMedia={onChangeMedia}
                  setPreviewVideoURL={setPreviewVideoURL}
                  previewVideoURL={previewVideoURL}
                  type={1}
                />
                {errors["imageFile"] && (
                  <span className="text-red-600 mt-1">
                    {errors["imageFile"]}
                  </span>
                )}
              </div>
              <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
                <img
                  src={values.campusLogoUrl || stashLogoWhite}
                  className="object-contain w-full shrink-0 h-full"
                  alt={values.campusName || "StashCampus"}
                />
              </div>
              <SubmitButton
                isNotLoading={!loadingEditSpace && !loadingCustomerInfo}
                disabled={
                  spaceStep === "student-info"
                    ? !values.verified &&
                      values.fieldsToEdit.includes("isAvailable")
                    : loadingEditSpace
                }
                onClickFunc={(e) => {
                  e.preventDefault();
                  console.log("have these values");
                  console.log(values);
                  if (!spaceStep || spaceStep === "space-details") {
                    if (
                      values.fieldsToEdit.includes("isAvailable") &&
                      !values.verified
                    ) {
                      navigate(`/editSpace/${values.spaceId}/student-info`);
                    } else {
                      navigate(`/editSpace/${values.spaceId}/payment-info`);
                    }
                  } else if (spaceStep === "student-info") {
                    navigate(`/editSpace/${values.spaceId}/payment-info`);
                  } else {
                    editSpace();
                  }
                }}
                isFixedButton={true}
                title={
                  spaceStep === "payment-info" ? "Confirm space" : "Continue"
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
      />
    </div>
  );
}

const EDIT_SPACE = gql`
  mutation editSpace(
    $spaceId: String!
    $fieldsToEdit: [String]!
    $fullStreetAddress: String
    $province: String
    $country: String
    $postalCode: String
    $campusId: String
    $isAvailable: Boolean
    $imageFile: Upload
    $payPalEmail: String
    $line2: String
    $specialInstructions: String
    $size: Size
    $city: String
  ) {
    editSpace(
      spaceId: $spaceId
      fieldsToEdit: $fieldsToEdit
      fullStreetAddress: $fullStreetAddress
      province: $province
      country: $country
      postalCode: $postalCode
      campusId: $campusId
      isAvailable: $isAvailable
      city: $city
      imageFile: $imageFile
      payPalEmail: $payPalEmail
      line2: $line2
      specialInstructions: $specialInstructions
      size: $size
    ) {
      id
      campusId
      customerId
      size
      fullStreetAddress
      province
      country
      postalCode
      imageUrl
      line2
      city
      specialInstructions
      capacity
      capacityLastUpdatedAt
      isAvailable
      createdAt
    }
  }
`;

const GET_SPACE_BY_ID = gql`
  query getSpaceById($spaceId: String!) {
    getSpaceById(spaceId: $spaceId) {
      id
      campusId
      customerId
      size
      fullStreetAddress
      province
      country
      postalCode
      imageUrl
      capacity
      city
      capacityLastUpdatedAt
      isAvailable
      createdAt
    }
  }
`;
