import React, { useContext } from "react";
import { CustomerAuthContext } from "../context/customerAuth";
import { AdminAuthContext } from "../context/adminAuth";
import { adminClient, customerClient } from "../GraphqlApolloClients";

export default function Logout({ initialUserType }) {
    const customerContext = useContext(CustomerAuthContext);
    const adminContext = useContext(AdminAuthContext);

    adminContext.logoutAdmin();
    customerContext.logoutCustomer();

    adminClient.cache.reset();
    customerClient.cache.reset();

    // if (customerContext.customer) {
    //     console.log("customer");
    // } else if (adminContext.admin) {
    //     console.log("admin");
    // }

    return (
        <div className="h-full flex-1 flex mx-8 sm:mx-24 md:mx-32 lg:mx-48 mb-8">
            Logging out...
        </div>
    );
}