import React from 'react'

export default function TextInputField({
  values,
  errors,
  setValues,
  title,
  isInPopUp,
  onChange,
  name,
  id,
  someRef,
  overlayBtnText,
  onClickOverlay,
  overlayBtnLoading,
  overlayDivText,
  disabled,
  type,
}) {
  // console.log("have these errors here:")
  // console.log(overlayBtnLoading)
  // console.log(errors)
  return (
    <div className={`${disabled ? 'opacity-50' : ''} w-full relative`}>
      <div className="flex items-center font-semibold justify-between text-sm sm:text-base">
        <label
          htmlFor={name}
          className={isInPopUp ? 'text-purple-900' : 'text-black'}
        >
          {title}{' '}
          {errors[name] && <span className="text-red-600">{errors[name]}</span>}
        </label>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>

      <input
        ref={someRef || null}
        id={id || name}
        className={`sm:text-lg mt-1 h-12 w-full shadow-sm  flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-purple-500 focus:ring-purple-500`}
        type={type ?? 'text'}
        name={name}
        value={values[name] || ''}
        onChange={onChange}
        placeholder={''}
        disabled={
          disabled
          //  ||
          // (values.fieldsToEdit && !values.fieldsToEdit.includes("inputValue"))
        }
        maxLength={200}
      />

      {overlayDivText ? (
        <div
          className={` bg-black overflow-hidden transition-all group shadow rounded flex items-center justify-center space-x-1 h-8 uppercase text-white px-3 sm:px-6 absolute bottom-2 right-2`}
        >
          <div
            className={`${
              !overlayBtnLoading
                ? 'motion-safe:animate-fadeOutUpFast'
                : 'motion-safe:animate-fadeDownFast'
            } absolute inset-0 flex items-center justify-center`}
          >
            <svg
              className={`w-4 motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>

          {/* <p
            className={`${
              overlayBtnLoading
                ? "motion-safe:animate-fadeOutFast"
                : "motion-safe:animate-fadeUpFast"
            } font-bold`}
          >
            {overlayDivText}
          </p> */}
          <svg
            className={`${
              overlayBtnLoading
                ? 'motion-safe:animate-fadeOutFast'
                : 'motion-safe:animate-fadeUpFast'
            } h-3 fill-current`}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z" />
          </svg>
        </div>
      ) : overlayBtnText ? (
        <button
          onClick={onClickOverlay}
          className={`${
            !overlayBtnLoading && errors[name]
              ? 'motion-safe:animate-shake '
              : ''
          } ${
            overlayBtnLoading ? 'bg-green-300' : 'bg-red-700 hover:bg-red-800'
          } bg-red-700 overflow-hidden hover:bg-black transition-all group shadow rounded flex items-center justify-center space-x-1 h-8 uppercase text-white short:px-1 px-3 sm:px-6 absolute bottom-2 right-2`}
          disabled={overlayBtnLoading}
          type={'button'}
        >
          <div
            className={`${
              !overlayBtnLoading && errors[name]
                ? 'motion-safe:animate-fadeOut bg-red-600'
                : ''
            } inset-0 absolute`}
          ></div>
          <div
            className={`${
              !overlayBtnLoading
                ? 'motion-safe:animate-fadeOutUpFast'
                : 'motion-safe:animate-fadeDownFast'
            } absolute inset-0 flex items-center justify-center`}
          >
            <svg
              className={`w-4 motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>

          <p
            className={`${
              overlayBtnLoading
                ? 'motion-safe:animate-fadeOutFast'
                : 'motion-safe:animate-fadeUpFast'
            } font-bold short:text-xs`}
          >
            {overlayBtnText}
          </p>
          <svg
            className={`${
              overlayBtnLoading
                ? 'motion-safe:animate-fadeOutFast'
                : 'motion-safe:animate-fadeUpFast'
            } h-2.5 short:h-2 fill-current`}
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className=" -translate-x-1 group-hover:translate-x-0 transition-all"
              d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
            />
            <rect
              className="opacity-0 group-hover:opacity-100 transition-all"
              y="4"
              width="9"
              height="2"
            />
          </svg>
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}
