import { CardElement } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { useWindowSize } from "../util/hooks";

export default function CardInputField({
  cardElemIsFocused,
  setCardElemIsFocused,

  setIsCardComplete,
  cardElem,
  setCardElem,
  elements,
  errors,
  setErrors,
  title,
}) {
  const size = useWindowSize();

  useEffect(() => {
    console.log(elements);
    if (elements) {
      console.log("setting card element");
      setCardElem(elements.getElement(CardElement));
    }
  }, [elements, setCardElem]);

  return (
    <div className="block w-full bg-white h-12 shadow-sm mt-1 overflow-hidden flex-shrink-0 px-3 py-3 border rounded-md border-gray-200 focus:border-indigo-500 focus:ring-indigo-500">
      <CardElement
        options={{
          hidePostalCode: true,

          style: {
            base: {
              color: "black",
              fontFamily: "sans-serif",

              fontSize: size.width >= 640 ? "18px" : "16px",

              "::placeholder": {
                color: "#9ca3af",
              },
              iconColor: "#6b7280",
            },
            complete: {
              color: "black",
              fontFamily: "sans-serif",

              fontSize: size.width >= 640 ? "18px" : "16px",

              "::placeholder": {
                color: "#9ca3af",
              },
              iconColor: "black",
            },
            invalid: {
              color: "#991b1b",
              iconColor: "#991b1b",
            },
          },
        }}
        id="paymentInfo"
        onFocus={() => {
          setCardElemIsFocused(true);
        }}
        onBlur={() => {
          setCardElemIsFocused(false);
        }}
        onChange={(e) => {
          if (e.complete) {
            console.log("in 1");
            setIsCardComplete(true);

            // if (error === "Required") {
            //   setHideErrorMsg(true);
            // }
          }
          if (!e.complete) {
            console.log("in 2");
            setIsCardComplete(false);

            setErrors({});
            // setHideErrorMsg(false);
          }
          if (e.invalid) {
            console.log("in 3");
            console.log("show invalid tag");
          }
          if (e.error) {
            setErrors({ ...errors, card: "- Invalid!" });
          }
        }}
        //   className="w-full"
      />
    </div>
  );
}
