import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  TYPE_TO_CITY,
  TYPE_TO_COUNTRY,
  TYPE_TO_FULL_STREET_ADDRESS,
  TYPE_TO_LINE2,
  TYPE_TO_POSTAL_CODE,
  TYPE_TO_PROVINCE,
  TYPE_TO_SPECIAL_INSTRUCTIONS,
} from "./AddressField";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";

const TYPE_TO_FIELD_NAMES = {
  0: [
    "pickupFullStreetAddress",
    "pickupProvince",
    "pickupCity",
    "pickupCountry",
    "pickupPostalCode",
  ],
  1: [
    "dropoffFullStreetAddress",
    "dropoffProvince",
    "dropoffCity",
    "dropoffCountry",
    "dropoffPostalCode",
  ],
  2: ["fullStreetAddress", "province", "city", "country", "postalCode"],
};

export default function AddressForm({
  title,
  setIsAddressBoxOpen,
  errors,
  setValues,
  values,
  isAddressBoxOpen,
  onChange,
  typeOfAddress,
  setErrors,
}) {
  const [hasBeenOnOnce, setHasBeenOnOnce] = useState(false);

  useEffect(() => {
    if (isAddressBoxOpen && !hasBeenOnOnce) {
      setHasBeenOnOnce(true);
    }
  }, [isAddressBoxOpen, hasBeenOnOnce]);

  function getProvinceFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("administrative_area_level_1") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getCityFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("locality") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getCountryFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("country") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  function getPostalCodeFromAddressComponents(components) {
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf("postal_code") !== -1) {
        return component.long_name;
      }
    }
    return null;
  }
  const [initializedAddressDropdown, setInitializedAddressDropdown] =
    useState(false);

  const handlePlaceChanged = useCallback(
    (tempStreetAddressAutocomplete) => {
      if (tempStreetAddressAutocomplete) {
        var place = tempStreetAddressAutocomplete.getPlace();

        if (place && place.address_components) {
          var province = getProvinceFromAddressComponents(
            place.address_components
          );
          var country = getCountryFromAddressComponents(
            place.address_components
          );
          var city = getCityFromAddressComponents(place.address_components);
          var postalCode = getPostalCodeFromAddressComponents(
            place.address_components
          );

          // Important: the onChange() hook does not handle the changing of the address, so this must be done to add these fields. if any of these fields are changed, all of them must be changed.
          const fieldNames = TYPE_TO_FIELD_NAMES[typeOfAddress];

          const newFieldsToEdit = values.fieldsToEdit
            ? [...values.fieldsToEdit]
            : [];

          fieldNames.forEach((fieldName) => {
            if (!newFieldsToEdit.includes(fieldName)) {
              newFieldsToEdit.push(fieldName);
            }
          });

          setValues({
            ...values,
            fieldsToEdit: newFieldsToEdit,
            [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]:
              place.formatted_address,
            [TYPE_TO_PROVINCE[typeOfAddress]]: province || values.province,
            [TYPE_TO_CITY[typeOfAddress]]: city || values.city,
            [TYPE_TO_COUNTRY[typeOfAddress]]: country || values.country,
            [TYPE_TO_POSTAL_CODE[typeOfAddress]]:
              postalCode || values.postalCode,
          });
        }
      }
    },
    [values, setValues, typeOfAddress]
  );
  const streetAddressRef = useRef();

  useEffect(() => {
    if (!initializedAddressDropdown && isAddressBoxOpen) {
      if (streetAddressRef.current) {
        streetAddressRef.current.focus();
      }

      // Initialize the Autocomplete objects
      var tempStreetAddressAutocomplete =
        new window.google.maps.places.Autocomplete(
          document.getElementById(TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]),
          {
            types: ["address"],
            componentRestrictions: { country: ["us", "ca"] },
          }
        );

      // Set the types to retrieve only cities and provinces/states
      // tempStreetAddressAutocomplete.setTypes(["(address)"]);

      // Use handlePlaceChanged directly in addListener
      tempStreetAddressAutocomplete.addListener("place_changed", function () {
        handlePlaceChanged(tempStreetAddressAutocomplete);
      });

      setInitializedAddressDropdown(true);
    }
  }, [
    initializedAddressDropdown,
    isAddressBoxOpen,
    typeOfAddress,
    handlePlaceChanged,
    streetAddressRef,
  ]);

  return (
    <div
      className={` pointer-events-none fixed pt-16 inset-0 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsAddressBoxOpen(false);
        }}
        className={`${
          isAddressBoxOpen
            ? "motion-safe:animate-fadeInFast"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutFast"
            : "hidden"
        } pointer-events-auto bg-black/60 hide-button-flash overscroll-contain motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute`}
      />
      <div
        className={`${
          isAddressBoxOpen
            ? "motion-safe:animate-fadeUpFast pointer-events-auto"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutDownFast pointer-events-none"
            : "hidden pointer-events-none"
        }  overflow-x-hidden max-h-full flex-shrink overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl short:p-4 p-8 sm:p-10 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            setIsAddressBoxOpen(false);
          }}
          className="w-full pt-4 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button> */}
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsAddressBoxOpen(false);
          }}
          className="p-2 short:p-1 right-4 short:top-2 short:right-2 top-4 absolute bg-purple-100 hover:bg-purple-200 transition-all rounded-full z-20"
        >
          <svg
            className="w-4 fill-current text-gray-700"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
          </svg>
        </button>

        <p className="text-purple-900 text-4xl font-bold w-full">{title}</p>

        <svg
          className="w-full z-0 absolute pointer-events-none inset-x-0 bottom-0"
          viewBox="0 0 568 460"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 432H5C12.1797 432 18 437.82 18 445V450C18 454.971 13.9706 459 9 459V459C4.02944 459 0 454.971 0 450V432Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 17C470 7.61116 477.611 0 487 0H568V192H487C477.611 192 470 184.389 470 175V17Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="367"
            y="77"
            width="95.3484"
            height="176.71"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="303"
            y="261"
            width="158.818"
            height="197.88"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="230"
            y="344"
            width="65.32"
            height="115.212"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="82"
            y="401"
            width="140.087"
            height="57.606"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="26"
            y="423"
            width="48.4074"
            height="35.5517"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 217C470 207.611 477.611 200 487 200H568V442C568 451.389 560.389 459 551 459H487C477.611 459 470 451.389 470 442V217Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
        </svg>

        <div className="w-full flex flex-col space-y-4 z-40 items-start justify-start mt-6">
          <TextInputField
            someRef={streetAddressRef}
            name={TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]}
            onChange={onChange}
            title="Street Address"
            values={values}
            setValues={setValues}
            errors={errors}
            isInPopUp={true}
          />
          <TextInputField
            name={TYPE_TO_LINE2[typeOfAddress]}
            title="Apartment or dorm number (optional)"
            values={values}
            setValues={setValues}
            errors={errors}
            onChange={onChange}
            isInPopUp={true}
          />
          <TextInputField
            name={TYPE_TO_SPECIAL_INSTRUCTIONS[typeOfAddress]}
            title="Special instructions (optional)"
            values={values}
            setValues={setValues}
            errors={errors}
            onChange={onChange}
            isInPopUp={true}
          />
        </div>

        <SubmitButton
          onClickFunc={(e) => {
            if (!values[TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]) {
              setErrors({
                ...errors,
                [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]: "- Required",
              });
            } else if (!values[TYPE_TO_PROVINCE[typeOfAddress]]) {
              setErrors({
                ...errors,
                [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]:
                  "- Province required",
              });
            } else if (!values[TYPE_TO_CITY[typeOfAddress]]) {
              setErrors({
                ...errors,
                [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]: "- City required",
              });
            } else if (!values[TYPE_TO_COUNTRY[typeOfAddress]]) {
              setErrors({
                ...errors,
                [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]:
                  "- Country required",
              });
            } else if (!values[TYPE_TO_POSTAL_CODE[typeOfAddress]]) {
              setErrors({
                ...errors,
                [TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]:
                  "- Postal code required",
              });
            } else {
              setErrors({});

              /* Autocomplete dropoff if it isn't designated yet and pickup is. */
              if (typeOfAddress === 0) {
                if (!values[TYPE_TO_FULL_STREET_ADDRESS[1]]) {
                  setValues({
                    ...values,
                    dropoffFullStreetAddress: values.pickupFullStreetAddress,
                    dropoffProvince: values.pickupProvince,
                    dropoffCity: values.pickupCity,
                    dropoffCountry: values.pickupCountry,
                    dropoffPostalCode: values.pickupPostalCode,
                    dropoffLine2: values.pickupLine2,
                    dropoffSpecialInstructions:
                      values.pickupSpecialInstructions,
                  });
                }
              }
              setIsAddressBoxOpen(false);
            }
          }}
          title={"Save"}
          errors={errors}
        />

        <p className="text-center leading-tight w-full text-purple-900 text-sm sm:text-base z-40 mt-4 sm:mb-0 mb-2">
          Please keep your things ready at the door
        </p>
      </div>
    </div>
  );
}
