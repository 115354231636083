import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { customerClient } from '../GraphqlApolloClients'

import ActionButton from './ActionButton'

export default function ReferralDisplay({
  title,
  isReferralDisplayOpen,
  setIsReferralDisplayOpen,
  referralId,
  errors,
  setErrors,
}) {
  const [hasBeenOnOnce, setHasBeenOnOnce] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)
        // Optionally reset the "copied" state after a delay
        setTimeout(() => setIsCopied(false), 10000)
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard', error)
      })
  }

  const { data: { getReferralById: referral } = {} } = useQuery(
    GET_REFERRAL_BY_ID,
    {
      client: customerClient,
      onError(err) {
        if (err.graphQLErrors[0]) {
          console.log('have some proper errors')
          console.log(err.graphQLErrors[0])
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          })
        }
      },
      variables: { referralId },
    },
  )

  const firstCharRef = useRef()
  const secondCharRef = useRef()
  const thirdCharRef = useRef()
  const fourthCharRef = useRef()
  const fifthCharRef = useRef()
  const sixthCharRef = useRef()
  const inputRefs = [
    firstCharRef,
    secondCharRef,
    thirdCharRef,
    fourthCharRef,
    fifthCharRef,
    sixthCharRef,
  ]

  useEffect(() => {
    if (isReferralDisplayOpen && !hasBeenOnOnce) {
      setHasBeenOnOnce(true)
    }
  }, [isReferralDisplayOpen, hasBeenOnOnce])

  return (
    <div
      className={`pointer-events-none fixed pt-24 inset-0 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault()
          setIsReferralDisplayOpen(false)
        }}
        className={`${
          isReferralDisplayOpen
            ? 'motion-safe:animate-fadeInFast'
            : hasBeenOnOnce
            ? 'motion-safe:animate-fadeOutFast'
            : 'hidden'
        } pointer-events-auto bg-black/60 hide-button-flash overscroll-contain motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute`}
        disabled={false}
      />
      <div
        className={`${
          isReferralDisplayOpen
            ? 'motion-safe:animate-fadeUpFast pointer-events-auto'
            : hasBeenOnOnce
            ? 'motion-safe:animate-fadeOutDownFast pointer-events-none'
            : 'hidden pointer-events-none'
        } h-auto  overflow-x-hidden max-h-full flex-shrink overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl short:p-4 p-8 sm:p-10 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault()
            setIsReferralDisplayOpen(false)
          }}
          className="p-2 short:p-1 right-4 short:top-2 short:right-2 top-4 absolute bg-purple-100 hover:bg-purple-200 transition-all rounded-full z-20"
        >
          <svg
            className="w-4 fill-current text-gray-700"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
          </svg>
        </button>

        <p className="text-purple-900 z-10 text-4xl text-center font-bold w-full">
          {title.split(' (optional)')[0]}
        </p>
        <p
          className={`text-gray-600 max-w-xs m-auto text-center leading-tight w-full  text-sm sm:text-base z-40 mt-2 sm:mb-0`}
        >
          Copy and share this code:
        </p>

        <svg
          className="w-full z-0 absolute pointer-events-none inset-x-0 bottom-0"
          viewBox="0 0 568 460"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 432H5C12.1797 432 18 437.82 18 445V450C18 454.971 13.9706 459 9 459V459C4.02944 459 0 454.971 0 450V432Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 17C470 7.61116 477.611 0 487 0H568V192H487C477.611 192 470 184.389 470 175V17Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="367"
            y="77"
            width="95.3484"
            height="176.71"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="303"
            y="261"
            width="158.818"
            height="197.88"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="230"
            y="344"
            width="65.32"
            height="115.212"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="82"
            y="401"
            width="140.087"
            height="57.606"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="26"
            y="423"
            width="48.4074"
            height="35.5517"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 217C470 207.611 477.611 200 487 200H568V442C568 451.389 560.389 459 551 459H487C477.611 459 470 451.389 470 442V217Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
        </svg>

        {referral && (
          <>
            <div className="flex flex-row uppercase items-start z-40 justify-center space-x-2 sm:space-x-4 mx-auto w-full my-8">
              {inputRefs.map((inputRef, index) => (
                <div className="w-16 h-16" key={index}>
                  <input
                    className="w-full h-full flex flex-col uppercase items-center justify-center text-center short:px-2 px-4 sm:px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-purple-700"
                    type="text"
                    name=""
                    id=""
                    ref={inputRef}
                    value={referral.code[index]}
                    disabled={true}
                  />
                </div>
              ))}
            </div>

            <ActionButton
              onClickFunc={(e) => {
                handleCopyClick(referral.code)
                setIsCopied(true)
              }}
              type={2}
              disabled={isCopied}
              text={!isCopied ? 'Copy to Clipboard' : 'Copied!'}
            />
          </>
        )}
        <p
          className={`${
            errors && errors[referralId] ? 'text-red-600' : 'text-base'
          } text-center leading-tight w-full  text-sm sm:text-base z-40 mt-6 sm:mb-0 mb-2`}
        >
          {errors[referralId]
            ? "We weren't able to retrieve your invite code. Please try again later."
            : 'Your friend will get $10 off for using this at checkout, and $10 off will be auto-applied to your next order too'}
        </p>
      </div>
    </div>
  )
}

export const GET_REFERRAL_BY_ID = gql`
  query getReferralById($referralId: String!) {
    getReferralById(referralId: $referralId) {
      code
      customerIds
      id
      createdAt
    }
  }
`
