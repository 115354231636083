// import { Datepicker } from "flowbite-react";
import moment from 'moment'
import React, { useState } from 'react'
// const customTheme = {
//   accordion: {
//     root: {
//       base: 'relative',
//     },
//     popup: {
//       root: {
//         base: 'absolute top-10 z-50 block pt-2',
//         inline: 'relative top-0 z-auto',
//         inner:
//           'inline-block rounded-none bg-white p-4 shadow-lg dark:bg-gray-700',
//       },
//     },
//   },
// }

export default function DateTimeField({
  title,
  description,
  values,
  setValues,
  onChange,
  typeOfDate,
  errors,
}) {
  // const [isAddressBoxOpen, setIsAddressBoxOpen] = useState(false)

  const [isInputFocused, setIsInputFocused] = useState(false)

  return (
    <div className="w-full">
      <div className="flex mb-1 items-center justify-between font-semibold">
        <p>
          {typeOfDate ? 'Drop off' : 'Pick up'} date
          {errors ? (
            <span className="text-red-600 ml-1">
              {!typeOfDate ? errors.startDate : errors.endDate}
            </span>
          ) : (
            ''
          )}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-3">
        <input
          min={
            typeOfDate
              ? moment(values['startDate']).format('YYYY-MM-DD')
              : moment().add(2, 'days').format('YYYY-MM-DD')
          }
          max={
            typeOfDate
              ? moment().add(1, 'year').format('YYYY-MM-DD')
              : moment(values['endDate']).format('YYYY-MM-DD')
          }
          type={'date'}
          name={(typeOfDate ? 'end' : 'start') + 'Date'}
          onChange={onChange}
          value={moment(values[(typeOfDate ? 'end' : 'start') + 'Date']).format(
            'YYYY-MM-DD',
          )}
          className={`h-12 w-full sm:col-span-2 shrink-0 shadow-sm  flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-purple-500 focus:ring-purple-500`}
        />
        <div className="group relative w-full sm:col-span-3">
          <button
            onFocus={(e) => {
              setIsInputFocused(true)
            }}
            className={`${
              isInputFocused ? '' : 'hover:bg-purple-500 hover:text-white'
            } w-full h-12 py-2 bg-white px-4  rounded group-focus-within:rounded-b-none shadow-sm flex items-center justify-between`}
          >
            <p className="text-left truncate w-full leading-tight flex-1 shrink-0">
              <span className="sm:inline-block hidden mr-1">{`${values[
                (typeOfDate ? 'end' : 'start') + 'Time'
              ]
                .split('_')
                .join(' ')} |`}</span>
              {`${
                values[(typeOfDate ? 'end' : 'start') + 'Time'] === 'MORNING'
                  ? '7am to 12pm'
                  : values[(typeOfDate ? 'end' : 'start') + 'Time'] ===
                    'EARLY_AFTERNOON'
                  ? '12pm to 3pm'
                  : values[(typeOfDate ? 'end' : 'start') + 'Time'] ===
                    'LATE_AFTERNOON'
                  ? '3pm to 6pm'
                  : '6pm to 9pm'
              }`}
            </p>

            <svg
              className="w-3 fill-current mt-0.5 shrink-0"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0.5L5 5.5L10 0.5H0Z" />
            </svg>
          </button>
          {isInputFocused && (
            <div className="w-full group-focus-within:block hidden top-full absolute bg-gray-50 overflow-hidden z-20 shadow-xl inset-x-0 rounded-b">
              {['MORNING', 'EARLY_AFTERNOON', 'LATE_AFTERNOON', 'EVENING'].map(
                (timeOption, index) => (
                  <button
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      setIsInputFocused(false)
                      const name = (typeOfDate ? 'end' : 'start') + 'Time'

                      var newFieldsToEdit = null
                      if (values.fieldsToEdit) {
                        newFieldsToEdit = [...values.fieldsToEdit]

                        if (!newFieldsToEdit.includes(name)) {
                          newFieldsToEdit.push(name)
                        }
                      }

                      setValues({
                        ...values,
                        [name]: timeOption,
                        fieldsToEdit: newFieldsToEdit,
                      })
                    }}
                    className={`${
                      values[(typeOfDate ? 'end' : 'start') + 'Time'] ===
                      timeOption
                        ? 'bg-purple-500 text-white'
                        : ''
                    } flex px-4 items-center hover:bg-purple-800 hover:text-white w-full transition-all justify-start p-2 space-x-3`}
                  >
                    <p className="text-left w-full leading-tight">
                      <span className="sm:inline-block hidden mr-1">{`${timeOption
                        .split('_')
                        .join(' ')} | `}</span>
                      {`${
                        timeOption === 'MORNING'
                          ? '7am to 12pm'
                          : timeOption === 'EARLY_AFTERNOON'
                          ? '12pm to 3pm'
                          : timeOption === 'LATE_AFTERNOON'
                          ? '3pm to 6pm'
                          : '6pm to 9pm'
                      }`}
                    </p>
                  </button>
                ),
              )}
            </div>
          )}
        </div>
      </div>
      {/* {campuses && campuses.length > 0 && isInputFocused && (
        <div className="w-full group-focus-within:block hidden top-full absolute bg-gray-50 overflow-hidden z-10 shadow-xl inset-x-0 rounded-b max-h-48">
          {campuses.map((campus, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setTempSearchTerm(campus.name);
                setIsInputFocused(false);
                setValues({
                  ...values,
                  campusId: campus.id,
                  campusLogoUrl: campus.logoImageUrl,
                  campusName: campus.name,
                });
              }}
              className="flex items-center hover:bg-purple-800 hover:text-white w-full transition-all justify-start p-2 space-x-3"
            >
              <div className="rounded-full bg-black flex items-center justify-center p-2">
                <img
                  src={campus.logoImageUrl}
                  className="object-cover w-4 aspect-square "
                  alt={campus.name}
                />
              </div>
              <p className="text-xl">{campus.name}</p>
            </button>
          ))}
        </div>
      )} */}
    </div>
  )
}
