import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";
import stashLogoWhite from "../images/stash_logo_white.svg";
import usePageTracking from "../util/usePageTracking";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { customerClient } from "../GraphqlApolloClients";
import AddressField from "../components/AddressField";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
import CodeForm from "../components/CodeForm";
import Footer from "../components/Footer";
import IsAvailableSelection from "../components/IsAvailableSelection";
import MediaInputField from "../components/MediaInputField";
import NavBar from "../components/NavBar";
import SpaceSelection from "../components/SpaceSelection";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import { CustomerAuthContext } from "../context/customerAuth";
import { useForm, useWindowSize } from "../util/hooks";
import { FETCH_CUSTOMER } from "./BookSpace";
import {
  GET_BOOKINGS_BY_STEPS,
  GET_BOOKING_REQUESTS,
  GET_REFERRAL_CREDITS_BY_CUSTOMER,
  GET_RELEVANT_BOOKINGS_BY_CUSTOMER,
  GET_SPACES_BY_CUSTOMER,
  GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
} from "./Dashboard";

// Not allowing them to edit customer, since that will be on the account page.
// TODO: See if we want to save their progress so that when they reload they're back where they started.
export default function CreateSpace(props) {
  const { spaceStep } = useParams();

  let navigate = useNavigate();

  usePageTracking();

  const resourcesRef = useRef();
  const productsRef = useRef();
  const pricingRef = useRef();
  const pageTopRef = useRef();

  useEffect(() => {
    document.title = "Create space | StashCampus";
  }, []);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  useWindowSize();
  const [isVerificationCodeBoxOpen, setIsVerificationCodeBoxOpen] =
    useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  // TODO: Change this default image.
  const [previewPhotoURL, setPreviewPhotoURL] = useState(null);
  const [previewVideoURL, setPreviewVideoURL] = useState(null);

  useEffect(() => {
    if (!customer) {
      navigate("/signup");
    }
  }, [customer, navigate]);

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const { values, setValues, onChange, onChangeMedia } = useForm(
    createSpaceCallback,
    {
      campusId: "",
      imageUrl: previewPhotoURL,
      size: "CLOSET",
      isAvailable: true,
      payPalEmail: "",
      campusLogoUrl: "",
      campusName: "",
      fullStreetAddress: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      line2: "",
      specialInstructions: "",
      email: "",
      firstName: "",
      imageFile: null,
      lastName: "",
      phoneNumber: "",
      verificationKey: "",
      verified: false,
    }
  );
  const [errors, setErrors] = useState({});
  const [createSpace, { loading: loadingCreateSpace }] = useMutation(
    CREATE_SPACE,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        {
          query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
          variables: {
            steps: ["CONFIRMED", "PICKED", "PAID_OWNER_HALF"],
          },
        },
        {
          query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
          variables: {
            steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"],
          },
        },
        {
          query: GET_BOOKINGS_BY_STEPS,
          variables: {
            steps: [
              "CREATED",
              "BOOKED",
              "PAID_OWNER_HALF",
              "CONFIRMED",
              "PICKED",
            ],
          },
        },
        {
          query: GET_BOOKINGS_BY_STEPS,
          variables: {
            steps: ["DROPPED", "CANCELLED"],
          },
        },
        {
          query: GET_BOOKING_REQUESTS,
        },
        {
          query: GET_REFERRAL_CREDITS_BY_CUSTOMER,
        },
        {
          query: GET_RELEVANT_BOOKINGS_BY_CUSTOMER,
          variables: { limit: 2 },
        },
        { query: GET_SPACES_BY_CUSTOMER },
      ],
      update() {
        console.log("createSpace() success!");
        navigate(`/dashboard/earnings`);
      },
      onError(err) {
        console.log("createSpace() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
        if (!errors.payPalEmail && !errors.imageFile) {
          // If error has to do with PayPal email or image, stay on this page, otherwise go back.
          navigate(`/shareSpace/`);
        }
      },
      variables: {
        ...values,
        fullStreetAddress: values.fullStreetAddress,
        province: values.province,
        country: values.country,
        postalCode: values.postalCode,
        line2: values.line2,
        specialInstructions: values.specialInstructions,
      },
      client: customerClient,
    }
  );
  function createSpaceCallback() {
    createSpace();
  }

  useEffect(() => {
    if (targetCustomer && values.email !== targetCustomer.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        payPalEmail: targetCustomer.payPalEmail,
      });
    }
  }, [targetCustomer, setValues, values]);

  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL, {
    update() {
      console.log("sendVerificationEmail() success!");
      setIsVerificationCodeBoxOpen(!isVerificationCodeBoxOpen);
    },
    onError(err) {
      console.log("sendVerificationEmail() error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });

  // console.log(targetCustomer)

  return (
    <div
      ref={pageTopRef}
      className="w-screen relative bg-light-beige overflow-x-hidden h-full flex flex-col min-h-screen"
    >
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      <NavBar
        page="createSpace"
        productsRef={productsRef}
        resourcesRef={resourcesRef}
        pricingRef={pricingRef}
        pageTopRef={pageTopRef}
        shouldHideLogin={
          errors && errors.email && errors.email === "- Please sign in first"
            ? false
            : true
        }
      />

      <CodeForm
        title={"Verification code"}
        name={"verificationKey"}
        isCodeBoxOpen={isVerificationCodeBoxOpen}
        setIsCodeBoxOpen={setIsVerificationCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={0}
        setErrors={setErrors}
      />

      {/* We only show the verification page if the customer needs to be verified.
       * But, if they still somehow end up on that page, it'll just say they are already verified and not let them click past.
       */}

      <div className="w-full flex-1 flex flex-col items-center justify-start">
        <div className="flex flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="flex mb-20 z-10 sm:mb-10 w-full flex-col space-y-6 sm:space-y-0 sm:space-x-10 sm:flex-row items-start justify-start">
            <div className="w-full flex flex-col">
              {spaceStep === "space-details" || !spaceStep ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate("/");
                      }}
                      className=" group absolute hidden sm:right-full sm:mr-8 sm:flex items-center justify-center rounded-full aspect-square hover:bg-white/20 p-4  transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Space details
                    </p>
                  </div>
                  <CampusSearchDropdown
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <SpaceSelection
                    isForSpaces={true}
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                  />
                  <AddressField
                    values={values}
                    setValues={setValues}
                    setErrors={setErrors}
                    errors={errors}
                    onChange={onChange}
                    typeOfAddress={2}
                  />
                  <IsAvailableSelection
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                  />
                </div>
              ) : spaceStep === "student-info" ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate(`/shareSpace/`);
                      }}
                      className="group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Student info
                    </p>
                  </div>

                  <TextInputField
                    title="School email"
                    name="email"
                    values={values}
                    onChange={onChange}
                    setValues={setValues}
                    overlayBtnText="Verify"
                    overlayDivText={values.verified ? "Verified" : null}
                    overlayBtnLoading={false}
                    errors={errors}
                    disabled={values.verified}
                    onClickOverlay={(e) => {
                      e.preventDefault();
                      setErrors({});
                      sendVerificationEmail();
                      setIsVerificationCodeBoxOpen(true);
                    }}
                  />
                  <div className="grid grid-cols-2 gap-2 sm:gap-3">
                    <TextInputField
                      onChange={onChange}
                      title="First name"
                      name="firstName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      disabled={true}
                    />
                    <TextInputField
                      onChange={onChange}
                      title="Last name"
                      name="lastName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      disabled={true}
                    />
                  </div>
                  <TextInputField
                    onChange={onChange}
                    title="Phone number"
                    name="phoneNumber"
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    disabled={true}
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate(`/shareSpace/student-info`);
                      }}
                      className="group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Payment configuration
                    </p>
                  </div>

                  <TextInputField
                    onChange={onChange}
                    title="PayPal email"
                    name="payPalEmail"
                    values={values}
                    setValues={setValues}
                    errors={errors}
                  />
                </div>
              )}
            </div>

            <div className="w-full text-lg relative bg-white rounded-xl shadow p-1 space-y-2">
              <div className=" relative rounded-lg w-full h-64 flex flex-col">
                <MediaInputField
                  name={"imageFile"}
                  value={values.imageUrl}
                  previewPhotoURL={previewPhotoURL}
                  setPreviewPhotoURL={setPreviewPhotoURL}
                  onChangeMedia={onChangeMedia}
                  setPreviewVideoURL={setPreviewVideoURL}
                  previewVideoURL={previewVideoURL}
                  type={1}
                />
                {errors["imageFile"] && (
                  <span className="text-red-600 mt-1 text-base font-semibold">
                    {errors["imageFile"]}
                  </span>
                )}
                <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
                  <img
                    src={values.campusLogoUrl || stashLogoWhite}
                    className="object-contain w-full shrink-0 h-full"
                    alt={values.campusName || "StashCampus"}
                  />
                </div>
              </div>

              <SubmitButton
                isNotLoading={!loadingCreateSpace && !loadingCustomerInfo}
                disabled={
                  spaceStep === "student-info"
                    ? !values.verified
                    : spaceStep === "payment-info"
                    ? !values.payPalEmail || values.payPalEmail === ""
                    : loadingCreateSpace
                }
                onClickFunc={(e) => {
                  e.preventDefault();

                  console.log("have these values");
                  console.log(values);
                  if (!spaceStep || spaceStep === "space-details") {
                    if (values.verified) {
                      navigate("/shareSpace/payment-info");
                    } else {
                      navigate("/shareSpace/student-info");
                    }
                  } else if (spaceStep === "student-info") {
                    navigate(`/shareSpace/payment-info`);
                  } else {
                    createSpace();
                  }
                }}
                isFixedButton={true}
                title={
                  spaceStep === "payment-info" ? "Confirm space" : "Continue"
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
      />
    </div>
  );
}

const CREATE_SPACE = gql`
  mutation createSpace(
    $imageFile: Upload
    $size: Size!
    $campusId: String!
    $isAvailable: Boolean!
    $city: String!
    $payPalEmail: String!
    $fullStreetAddress: String!
    $province: String!
    $country: String!
    $postalCode: String!
    $specialInstructions: String
    $line2: String
  ) {
    createSpace(
      imageFile: $imageFile
      size: $size
      campusId: $campusId
      isAvailable: $isAvailable
      payPalEmail: $payPalEmail
      fullStreetAddress: $fullStreetAddress
      province: $province
      country: $country
      postalCode: $postalCode
      specialInstructions: $specialInstructions
      line2: $line2
      city: $city
    ) {
      id
      campusId
      customerId
      size
      fullStreetAddress
      province
      city
      country
      postalCode
      imageUrl
      capacity
      capacityLastUpdatedAt
      isAvailable
      createdAt
      line2
      specialInstructions
    }
  }
`;

export const SEND_VERIFICATION_EMAIL = gql`
  mutation sendVerificationEmail($email: String!) {
    sendVerificationEmail(email: $email)
  }
`;
