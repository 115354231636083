import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { customerClient } from '../GraphqlApolloClients'
import stashLogoWhite from '../images/stash_logo_white.svg'
function CampusSearchDropdown({
  title,
  description,
  values,
  setValues,
  errors,
  setErrors,
  disabled,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [tempSearchTerm, setTempSearchTerm] = useState(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const {
    data: { getCampusesBySearchTerm: campuses } = {},
    loading: loadingCampus,
  } = useQuery(GET_CAMPUSES_BY_SEARCH_TERM, {
    variables: { searchTerm: searchTerm ?? '' },
    client: customerClient,
    onError(err) {
      console.log('getCampusesBySearchTerm() Error!')
      console.log(err)
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchTerm(tempSearchTerm)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [tempSearchTerm, searchTerm, setSearchTerm])

  const error =
    errors['campusId'] ??
    errors['campusName'] ??
    errors['campusLogoUrl'] ??
    null

  useEffect(() => {
    if (
      tempSearchTerm === null &&
      values.campusName &&
      tempSearchTerm !== values.campusName
    ) {
      setTempSearchTerm(values.campusName)
    }
  }, [tempSearchTerm, isInputFocused, values.campusName])

  return (
    <div className="w-full group relative">
      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          Campus
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div
        className={`${
          disabled ? 'bg-gray-200' : 'bg-white'
        } w-full h-12 py-2 px-2 rounded group-focus-within:rounded-b-none shadow-sm flex items-center justify-start space-x-3`}
      >
        <div className="rounded-full bg-black shrink-0 aspect-square flex items-center justify-center p-2">
          {loadingCampus ? (
            <svg
              className={`w-4 m-auto stroke-current text-white motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          ) : values.campusLogoUrl ? (
            <img
              src={values.campusLogoUrl || stashLogoWhite}
              className="object-cover w-4 h-4  "
              alt={values.campusName || 'StashCampus'}
            />
          ) : (
            <svg
              className="w-4 text-white fill-current"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5508 8.83346C16.5508 12.6075 13.4913 15.6669 9.71731 15.6669C5.9433 15.6669 2.88385 12.6075 2.88385 8.83346C2.88385 5.05945 5.9433 2 9.71731 2C13.4913 2 16.5508 5.05945 16.5508 8.83346ZM14.5529 16.2271C13.1637 17.1375 11.5023 17.6669 9.71731 17.6669C4.83873 17.6669 0.88385 13.7121 0.88385 8.83346C0.88385 3.95488 4.83873 0 9.71731 0C14.5959 0 18.5508 3.95488 18.5508 8.83346C18.5508 11.204 17.617 13.3564 16.0973 14.943L21.484 20.3297L20.0697 21.7439L14.5529 16.2271Z"
              />
            </svg>
          )}
        </div>
        <input
          className={`${
            disabled ? 'bg-gray-200' : 'bg-white'
          } text-xl sm:text-2xl w-full focus:outline-none`}
          onChange={(e) => {
            setTempSearchTerm(e.target.value)
          }}
          onFocus={(e) => {
            setIsInputFocused(true)
          }}
          disabled={disabled}
          value={tempSearchTerm || ''}
        />
      </div>
      {campuses && campuses.length > 0 && isInputFocused && !disabled && (
        <div className="w-full group-focus-within:block hidden top-full absolute bg-gray-50 overflow-hidden z-10 shadow-xl inset-x-0 rounded-b max-h-48">
          {campuses.map((campus, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                setTempSearchTerm(campus.name)
                setIsInputFocused(false)

                setValues({
                  ...values,
                  campusId: campus.id,
                  campusLogoUrl: campus.logoImageUrl,
                  campusName: campus.name,
                  dormImageUrl: campus.dormImageUrl,
                  closetImageUrl: campus.closetImageUrl,
                  cornerImageUrl: campus.cornerImageUrl,
                })
              }}
              className={`${
                values.campusId === campus.id ? 'bg-purple-500 text-white' : ''
              } flex items-center hover:bg-purple-800 hover:text-white w-full transition-all justify-start p-2 space-x-3`}
            >
              <div className="rounded-full aspect-square shrink-0 bg-black flex items-center justify-center p-2 ">
                <img
                  src={campus.logoImageUrl}
                  className="object-contain w-4 shrink-0 h-4 aspect-square"
                  alt={campus.name}
                />
              </div>
              <p className="text-xl text-left truncate">{campus.name}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export const GET_CAMPUSES_BY_SEARCH_TERM = gql`
  query getCampusesBySearchTerm($searchTerm: String, $limit: Int) {
    getCampusesBySearchTerm(searchTerm: $searchTerm, limit: $limit) {
      id
      address
      name
      shortForm
      numOfUniqueStudents
      totalRevenue
      logoImageUrl
      campusImageUrls
      dormImageUrl
      closetImageUrl
      cornerImageUrl
      spaceImageUrls
      textImageUrl
      createdAt
      lastUpdatedAt
    }
  }
`

export default CampusSearchDropdown
