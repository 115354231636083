import React, { useContext, useEffect, useRef, useState } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import AddressField from "../components/AddressField";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
import stashClosetImage from "../images/stash_closet.png";
import stashCornerImage from "../images/stash_corner.png";
import stashDormImage from "../images/stash_dorm.png";
import stashLogoWhite from "../images/stash_logo_white.svg";
import usePageTracking from "../util/usePageTracking";
// import CodeForm from '../components/CodeForm'
import DateTimeField from "../components/DateTimeField";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import NavBar from "../components/NavBar";
import PaymentDetails from "../components/PaymentDetails";
import PaymentPlanSelection from "../components/PaymentPlanSelection";
import SpaceSelection from "../components/SpaceSelection";
import SubmitButton from "../components/SubmitButton";
// import TextInputField from '../components/TextInputField'
import { GET_LAST_STATUS_BY_BOOKING } from "../components/BookingBox";
import { CustomerAuthContext } from "../context/customerAuth";
import { customerClient } from "../GraphqlApolloClients";
import { useForm, useWindowSize } from "../util/hooks";
import {
  FETCH_CUSTOMER,
  GET_BOOKING_BY_ID,
  GET_CAMPUS_BY_ID,
  GET_PAYMENT_BY_BOOKING,
} from "./BookSpace";
import { GET_BOOKINGS_BY_STEPS } from "./Dashboard";

export default function EditBooking(props) {
  const { bookingId, bookingStep } = useParams();

  let navigate = useNavigate();

  usePageTracking();

  const resourcesRef = useRef();
  const productsRef = useRef();
  const pricingRef = useRef();
  const pageTopRef = useRef();

  useEffect(() => {
    document.title = "Booking details | Bookings";
  }, []);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  useWindowSize();

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
      navigate("/login");
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
    navigate,
  ]);

  const { data: { getLastStatusByBooking: lastStatus } = {} } = useQuery(
    GET_LAST_STATUS_BY_BOOKING,
    {
      variables: { bookingId },
      client: customerClient,
    }
  );

  const { values, setValues, onChange, onChangeDate } = useForm(
    editBookingCallback,
    {
      bookingId,
      pickupFullStreetAddress: "",
      pickupProvince: "",
      pickupCity: "",
      pickupCountry: "",
      pickupPostalCode: "",
      pickupLine2: "",
      pickupSpecialInstructions: "",
      dropoffFullStreetAddress: "",
      dropoffProvince: "",
      dropoffCity: "",
      dropoffCountry: "",
      dropoffPostalCode: "",
      dropoffLine2: "",
      dropoffSpecialInstructions: "",
      startDate: "",
      startTime: "",
      endDate: "",
      dormImageUrl: "",
      campusName: "",
      closetImageUrl: "",
      cornerImageUrl: "",
      firstName: "",
      phoneNumber: "",
      lastName: "",
      email: "",
      endTime: "",
      size: "",
      campusId: "",
      fieldsToEdit: [],
    }
  );

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (lastStatus && lastStatus.step === "CREATED") {
      navigate(`/bookSpace/${bookingId}`);
    }
  }, [lastStatus, navigate, bookingId]);

  useEffect(() => {
    if (targetCustomer && values.email !== targetCustomer.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
      });
    }
  }, [targetCustomer, setValues, values]);

  const [editBooking, { loading: loadingEditBooking }] = useMutation(
    EDIT_BOOKING,
    {
      refetchQueries: [
        {
          query: GET_BOOKINGS_BY_STEPS,
          variables: {
            steps: [
              "CREATED",
              "BOOKED",
              "PAID_OWNER_HALF",
              "CONFIRMED",
              "PICKED",
            ],
          },
        },
        {
          query: GET_BOOKINGS_BY_STEPS,
          variables: { steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"] },
        },
      ],
      update() {
        console.log("editBooking() success!");
        navigate(`/dashboard`);
      },
      onError(err) {
        console.log("editBooking() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
        navigate(`/editBooking/${values.bookingId ?? "-"}`);
      },
      variables: { ...values },
      client: customerClient,
    }
  );
  function editBookingCallback() {
    editBooking();
  }

  const { data: { getCampusById: campus } = {}, refetch: refetchCampusById } =
    useQuery(GET_CAMPUS_BY_ID, {
      variables: { campusId: values.campusId },
      client: customerClient,
      onCompleted() {
        console.log("Successfully completed getCampusById()!");
      },
      onError(err) {
        console.log(values);
        console.log("getCampusById() Error!");
        console.log(err);
      },
    });

  const { data: { getBookingById: booking } = {} } = useQuery(
    GET_BOOKING_BY_ID,
    {
      variables: { bookingId },
      client: customerClient,
      onCompleted() {
        console.log("getBookingById() completed successfully!");
        console.log(booking);
      },
      onError(err) {
        console.log(bookingId);
        console.log("getBookingById() Error!");
        console.log(err);
      },
    }
  );

  useEffect(() => {
    if (booking) {
      setValues((prevValues) => ({
        ...prevValues,
        paymentPlan: booking.paymentPlan,
        pickupFullStreetAddress: booking.pickupFullStreetAddress,
        pickupProvince: booking.pickupProvince,
        pickupCity: booking.pickupCity,
        pickupCountry: booking.pickupCountry,
        pickupPostalCode: booking.pickupPostalCode,
        pickupLine2: booking.pickupLine2,
        pickupSpecialInstructions: booking.pickupSpecialInstructions,
        dropoffFullStreetAddress: booking.dropoffFullStreetAddress,
        dropoffProvince: booking.dropoffProvince,
        dropoffCity: booking.dropoffCity,
        dropoffCountry: booking.dropoffCountry,
        dropoffPostalCode: booking.dropoffPostalCode,
        dropoffLine2: booking.dropoffLine2,
        dropoffSpecialInstructions: booking.dropoffSpecialInstructions,
        startDate: booking.startDate,
        startTime: booking.startTime,
        endDate: booking.endDate,
        endTime: booking.endTime,
        size: booking.size,
        campusId: booking.campusId,
      }));
      refetchCampusById({ campusId: booking.campusId });
    }
  }, [booking, setValues, refetchCampusById]);

  useEffect(() => {
    if (campus) {
      setValues((prevValues) => ({
        ...prevValues,
        campusName: campus.name,
        campusLogoUrl: campus.logoUrl,
        dormImageUrl: campus.dormImageUrl,
        closetImageUrl: campus.closetImageUrl,
        cornerImageUrl: campus.cornerImageUrl,
      }));
    }
  }, [campus, setValues]);

  const { data: { getPaymentByBooking: payment } = {} } = useQuery(
    GET_PAYMENT_BY_BOOKING,
    {
      client: customerClient,
      variables: { bookingId: values.bookingId },
      onError(err) {
        console.log("getPaymentByBooking() error!");
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
    }
  );

  return !loadingEditBooking ? (
    <div
      ref={pageTopRef}
      className="w-screen relative bg-light-beige overflow-x-hidden h-full flex flex-col min-h-screen"
    >
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      <NavBar
        page="editBooking"
        productsRef={productsRef}
        resourcesRef={resourcesRef}
        pricingRef={pricingRef}
        pageTopRef={pageTopRef}
      />

      <div className="w-full flex-1 flex flex-col items-center justify-start">
        <div className="flex mb-20 sm:mb-10 sm:flex-row flex-col items-start z-10 w-full max-w-5xl  space-y-6 sm:space-y-0 sm:space-x-10 xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="flex w-full flex-col space-y-6 sm:space-y-0 sm:space-x-10 sm:flex-row items-start justify-start px-4 sm:px-0">
            <div className="w-full flex flex-col">
              <div className="w-full flex flex-col pb-4 sm:pb-10 px-4 sm:px-0 space-y-4">
                <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                  Booking details
                </p>

                <CampusSearchDropdown
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  setErrors={setErrors}
                  disabled={true}
                />
                <SpaceSelection
                  values={values}
                  setValues={setValues}
                  onChange={onChange}
                  disabled={true}
                />
                <AddressField
                  values={values}
                  setValues={setValues}
                  setErrors={setErrors}
                  errors={errors}
                  onChange={onChange}
                  typeOfAddress={0}
                />
                <DateTimeField
                  values={values}
                  setValues={setValues}
                  onChange={onChangeDate}
                  typeOfDate={0}
                  errors={errors}
                  setErrors={setErrors}
                />
                <AddressField
                  values={values}
                  setValues={setValues}
                  setErrors={setErrors}
                  errors={errors}
                  onChange={onChange}
                  typeOfAddress={1}
                />
                <DateTimeField
                  values={values}
                  setValues={setValues}
                  onChange={onChangeDate}
                  typeOfDate={1}
                />

                <p className="text-black font-bold text-3xl sm:text-4xl leading-heading pt-10">
                  Payment info
                </p>

                <div className="w-full">
                  <PaymentPlanSelection
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                    errors={errors}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
              <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
                <img
                  src={
                    values.size === "CORNER"
                      ? stashCornerImage
                      : values.size === "CLOSET"
                      ? stashClosetImage
                      : stashDormImage
                  }
                  className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={"Stash Room"}
                />
                {values && values.campusName ? (
                  <img
                    src={
                      values.size === "CORNER"
                        ? values.cornerImageUrl
                        : values.size === "CLOSET"
                        ? values.closetImageUrl
                        : values.dormImageUrl
                    }
                    className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                    alt={values.campusName}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
                <img
                  src={values.campusLogoUrl || stashLogoWhite}
                  className="object-contain w-full shrink-0 h-full"
                  alt={values.campusName || "StashCampus"}
                />
              </div>
              <PaymentDetails payment={payment} values={values} />
              <SubmitButton
                isNotLoading={!loadingEditBooking && !loadingCustomerInfo}
                disabled={
                  values.fieldsToEdit.length === 0 || loadingEditBooking
                }
                onClickFunc={(e) => {
                  e.preventDefault();
                  console.log(values);
                  if (!bookingStep || bookingStep === "booking-details") {
                    console.log("moving to student-info, have values");
                    console.log(values);
                    editBooking();
                  }
                }}
                isFixedButton={true}
                title={
                  bookingStep === "student-info"
                    ? "Confirm booking"
                    : "Edit booking"
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
      />
    </div>
  ) : (
    <Loading />
  );
}

const EDIT_BOOKING = gql`
  mutation editBooking(
    $fieldsToEdit: [String]!
    $bookingId: String
    $pickupFullStreetAddress: String
    $pickupCity: String
    $pickupProvince: String
    $pickupCountry: String
    $pickupLine2: String
    $pickupPostalCode: String
    $dropoffProvince: String
    $dropoffCity: String
    $pickupSpecialInstructions: String
    $dropoffFullStreetAddress: String
    $dropoffLine2: String
    $dropoffCountry: String
    $dropoffPostalCode: String
    $dropoffSpecialInstructions: String
    $startTime: Slot
    $startDate: DateTime
    $endDate: DateTime
    $endTime: Slot
    $size: Size
    $paymentPlan: String
    $campusId: String
  ) {
    editBooking(
      fieldsToEdit: $fieldsToEdit
      bookingId: $bookingId
      pickupFullStreetAddress: $pickupFullStreetAddress
      pickupCity: $pickupCity
      pickupProvince: $pickupProvince
      pickupCountry: $pickupCountry
      pickupLine2: $pickupLine2
      pickupPostalCode: $pickupPostalCode
      dropoffProvince: $dropoffProvince
      dropoffCity: $dropoffCity
      pickupSpecialInstructions: $pickupSpecialInstructions
      dropoffFullStreetAddress: $dropoffFullStreetAddress
      dropoffLine2: $dropoffLine2
      dropoffCountry: $dropoffCountry
      dropoffPostalCode: $dropoffPostalCode
      dropoffSpecialInstructions: $dropoffSpecialInstructions
      startTime: $startTime
      startDate: $startDate
      endDate: $endDate
      endTime: $endTime
      size: $size
      paymentPlan: $paymentPlan
      campusId: $campusId
    ) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      lastUpdatedAt
      createdAt
    }
  }
`;
