import React, { useState } from 'react'
import CodeForm from './CodeForm'

export default function CodeField({
  title,
  description,
  values,
  setValues,
  onChange,
  typeOfCode,
  disabled,
  name,
}) {
  const [isCodeBoxOpen, setIsCodeBoxOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const error = 1 ? null : '- Required!'

  return (
    <div className="w-full">
      <CodeForm
        title={title}
        name={name}
        isCodeBoxOpen={isCodeBoxOpen}
        setIsCodeBoxOpen={setIsCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={typeOfCode}
        setErrors={setErrors}
      />

      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          {title}
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="w-full h-12 flex items-center justify-start space-x-3">
        <button
          onClick={(e) => {
            setIsCodeBoxOpen(true)
          }}
          disabled={disabled}
          className={`h-full uppercase w-full cursor-text text-left  px-3 transition-all rounded shadow-sm ${
            disabled ? 'bg-gray-200' : 'bg-white'
          }`}
        >
          <p>
            {values[name] && values[name].split(' ').join('').length === 6
              ? values[name]
              : ''}
          </p>
        </button>
      </div>
    </div>
  )
}
