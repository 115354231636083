import { split } from "@apollo/client";
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";

const backendURI =
  process.env.NODE_ENV === "production"
    ? "https://stash-b21893a85830.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";

const wsURI =
  process.env.NODE_ENV === "production"
    ? `wss://stash-b21893a85830.herokuapp.com/subscriptions`
    : "ws://localhost:4000/subscriptions";

const httpLink = createUploadLink({
  uri: backendURI,
  // credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsURI,

    connectionParams: () => ({
      GuestAuth: `Bearer ${localStorage.getItem("guestJwtToken")}`,
      AdminAuth: `Bearer ${localStorage.getItem("adminJwtToken")}`,
      CustomerAuth: `Bearer ${localStorage.getItem("customerJwtToken")}`,
    }),
  })
);

const adminAuthLink = setContext(() => {
  const adminToken = localStorage.getItem("adminJwtToken");
  return {
    headers: {
      Authorization: adminToken ? `Bearer ${adminToken}` : "",
    },
  };
});
const adminLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  adminAuthLink.concat(httpLink)
);
// export const adminClient = null;
export const adminClient = new ApolloClient({
  link: adminLink,
  // link: adminAuthLink.concat(httpLink),
  uri: backendURI,

  cache: new InMemoryCache({ addTypename: false }),
  resolvers: {},
});

const customerAuthLink = setContext(() => {
  const customerToken = localStorage.getItem("customerJwtToken");

  return {
    headers: {
      Authorization: customerToken ? `Bearer ${customerToken}` : "",
    },
  };
});
const customerLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  customerAuthLink.concat(httpLink)
);

export const customerClient = new ApolloClient({
  link: customerLink,

  uri: backendURI,

  cache: new InMemoryCache({ addTypename: false }),
  resolvers: {},
});

const guestAuthLink = setContext(() => {
  const guestToken = localStorage.getItem("guestJwtToken");
  return {
    headers: {
      Authorization: guestToken ? `Bearer ${guestToken}` : "",
    },
  };
});
const guestLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  guestAuthLink.concat(httpLink)
);

export const guestClient = new ApolloClient({
  link: guestLink,

  uri: backendURI,

  cache: new InMemoryCache({ addTypename: false }),
  resolvers: {},
});
