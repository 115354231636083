import React from 'react'

export default function IsAvailableSelection({
  title,
  description,
  errors,
  setErrors,
  values,
  setValues,
}) {
  const error = !errors || !errors['isAvailable'] ? null : '- Required!'
  function getNewFieldsToEdit(val) {
    var newFieldsToEdit = []
    if (values.fieldsToEdit) {
      newFieldsToEdit = [...values.fieldsToEdit]
      if (!newFieldsToEdit.includes(val)) {
        newFieldsToEdit.push(val)
      }
    }
    return newFieldsToEdit
  }
  return (
    <div className="w-full relative">
      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          Is this space available right now?{' '}
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="w-full h-12  gap-2 sm:gap-3 grid grid-cols-5">
        <button
          onClick={(e) => {
            setValues({
              ...values,
              isAvailable: true,
              fieldsToEdit: getNewFieldsToEdit('isAvailable'),
            })
          }}
          className={`${
            values.isAvailable === true
              ? 'bg-purple-600 text-white'
              : 'bg-white hover:bg-purple-500 hover:text-white '
          } col-span-3 h-full px-2 shrink-0 text-center    hover:text-white transition-all rounded shadow-sm`}
        >
          Yes
        </button>
        <button
          onClick={(e) => {
            setValues({
              ...values,
              isAvailable: false,
              fieldsToEdit: getNewFieldsToEdit('isAvailable'),
            })
          }}
          className={`${
            values.isAvailable === false
              ? 'bg-purple-600 text-white'
              : 'bg-white hover:bg-purple-500 hover:text-white '
          } h-full col-span-2 px-5 shrink-0 text-center  transition-all rounded shadow-sm`}
        >
          No
        </button>
      </div>
    </div>
  )
}
