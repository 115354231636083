import React from 'react'
import { useDropzone } from 'react-dropzone'
// import  backVid from "../../videos/background-vid.mp4";

export default function MediaInputField({
  value,
  name,
  onChangeMedia,
  disabled,
  previewVideoURL,
  setPreviewVideoURL,
  previewPhotoURL,
  setPreviewPhotoURL,
  shouldEdit,
  editFunc,
  type,
}) {
  const onDrop = async (files) => {
    if (!disabled && files && files.length > 0) {
      var fileType = files[0].type

      onChangeMedia(name, files[0], fileType)

      // console.log('have thisss')
      // console.log(name)
      // console.log(files[0])
      // console.log(fileType)

      if (fileType.includes('image')) {
        const imageTempUrl = URL.createObjectURL(files[0])
        setPreviewPhotoURL(imageTempUrl)
        setPreviewVideoURL(null)
      } else {
        const videoTempUrl = URL.createObjectURL(files[0])
        setPreviewVideoURL(videoTempUrl)
        setPreviewPhotoURL(null)
      }

      if (shouldEdit) {
        editFunc({ variables: { [name]: files[0], fieldsToEdit: [name] } })
      }
    }
  }

  const {
    getRootProps,
    getInputProps,
    isdragactive,
    isdragaccept,
    isdragreject,
  } = useDropzone({
    onDrop,
    accept: 'video/mp4,image/jpg,image/jpeg,image/png',
    // maxSize: 104857600, //10 MB
    multiple: false,
  })

  // useEffect(() => {
  //   if (value && type === 0) {
  //     setPreviewVideoURL(value)
  //   } else if (value && type === 1) {
  //     setPreviewPhotoURL(value)
  //   }
  // }, [value, type, setPreviewVideoURL, setPreviewPhotoURL])

  return (
    <button
      {...getRootProps({
        isdragactive,
        isdragaccept,
        isdragreject,
      })}
      className={`${
        isdragactive ? 'bg-purple-700 animate-pulse' : ''
      } flex-shrink flex-grow-0 overflow-hidden group relative w-full h-full bg-white hover:bg-gray-50 transition-all rounded-xl shadow border-gray-200 border`}
      type="button"
    >
      <input {...getInputProps()} />

      {!(previewVideoURL || previewPhotoURL || value) && (
        <div className="flex-col inset-0 z-0 m-auto items-center flex justify-center absolute opacity-100 transition-opacity">
          <svg
            className="h-10 fill-current text-gray-500"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.204 8.662V16.662H12.796V8.662L15.204 11.069L16.5 9.772L12 5.272L7.5 9.772L8.796 11.069L11.204 8.662ZM20 19.852H4V21.852H20V19.852Z"
            />
          </svg>
          <p className="text-gray-500 mt-1 text-base">Image of your space</p>
        </div>
      )}
      {(previewVideoURL || previewPhotoURL || value) && (
        <div className="inset-0 m-auto z-10 items-center flex justify-center absolute group-hover:opacity-100 transition-opacity opacity-0 ">
          <svg
            className="h-10 m-auto fill-current text-gray-200"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        </div>
      )}

      {(previewVideoURL || (value && type === 0)) && (
        <video
          className="flex-grow-0 w-full h-full top-0 object-cover z-10"
          controls={false}
          autoPlay={true}
          muted={true}
          loop={true}
        >
          <source src={previewVideoURL || value} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {(previewPhotoURL || (value && type === 1)) && (
        <img
          className="flex-grow-0 w-full h-full top-0 object-cover z-10"
          src={previewPhotoURL || value}
          alt={name}
        />
      )}
    </button>
  )
}
