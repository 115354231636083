import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { customerClient } from '../GraphqlApolloClients'

function SpaceSearchDropdown({
  title,
  description,
  values,
  setValues,
  errors,
  setErrors,
  disabled,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [tempSearchTerm, setTempSearchTerm] = useState(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const {
    data: { getSpacesBySearchTerm: spaces } = {},
    loading: loadingSpaces,
  } = useQuery(GET_SPACES_BY_SEARCH_TERM, {
    variables: { searchTerm: searchTerm ?? '' },
    client: customerClient,
    onError(err) {
      console.log('getSpacesBySearchTerm() Error!')
      console.log(err)
      console.log(searchTerm)

      if (err.graphQLErrors[0]) {
        console.log('have some proper errors')
        console.log(err.graphQLErrors[0])
        // if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
        //   navigate('/login')
        // }
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        })
      }
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchTerm(tempSearchTerm)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [tempSearchTerm, searchTerm, setSearchTerm])

  const error = errors['spaceId'] ?? errors['bookingId'] ?? null

  useEffect(() => {
    if (
      tempSearchTerm === null &&
      values.fullStreetAddress &&
      tempSearchTerm !== values.fullStreetAddress
    ) {
      setTempSearchTerm(values.fullStreetAddress)
    }
  }, [tempSearchTerm, isInputFocused, values.fullStreetAddress])

  return (
    <div className="w-full group relative mt-3 mb-4">
      <div className="leading-none flex mb-1 items-center justify-between font-semibold">
        <p>
          Space
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div
        className={`${
          disabled ? 'bg-gray-200' : 'bg-light-white'
        } w-full h-12 py-2 px-2 rounded group-focus-within:rounded-b-none shadow-sm flex items-center justify-start space-x-3 focus:ring-1 border-light-beige border-2`}
      >
        <div className="rounded-full bg-purple-950 shrink-0 aspect-square flex items-center justify-center p-2">
          {loadingSpaces ? (
            <svg
              className={`w-4 m-auto stroke-current text-white motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="w-4 text-white fill-current"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5508 8.83346C16.5508 12.6075 13.4913 15.6669 9.71731 15.6669C5.9433 15.6669 2.88385 12.6075 2.88385 8.83346C2.88385 5.05945 5.9433 2 9.71731 2C13.4913 2 16.5508 5.05945 16.5508 8.83346ZM14.5529 16.2271C13.1637 17.1375 11.5023 17.6669 9.71731 17.6669C4.83873 17.6669 0.88385 13.7121 0.88385 8.83346C0.88385 3.95488 4.83873 0 9.71731 0C14.5959 0 18.5508 3.95488 18.5508 8.83346C18.5508 11.204 17.617 13.3564 16.0973 14.943L21.484 20.3297L20.0697 21.7439L14.5529 16.2271Z"
              />
            </svg>
          )}
        </div>
        <input
          className={`${
            disabled ? 'bg-gray-200' : 'bg-light-white'
          } text-xl sm:text-2xl w-full focus:outline-none`}
          onChange={(e) => {
            setTempSearchTerm(e.target.value)
          }}
          onFocus={(e) => {
            setIsInputFocused(true)
          }}
          disabled={disabled}
          value={tempSearchTerm || ''}
        />
      </div>
      {spaces && spaces.length > 0 && isInputFocused && !disabled && (
        <div className="w-full group-focus-within:block hidden top-full absolute bg-gray-50 overflow-hidden z-20 shadow-xl inset-x-0 rounded-b max-h-48 overflow-y-auto">
          {spaces.map((space, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                setTempSearchTerm(space.fullStreetAddress)
                setIsInputFocused(false)

                setValues({
                  ...values,
                  spaceId: space.id,
                  fullStreetAddress: space.fullStreetAddress,
                })
              }}
              className={`${
                values.spaceId === space.id ? 'bg-purple-500 text-white' : ''
              } flex items-center hover:bg-purple-800 hover:text-white w-full transition-all justify-start p-2 space-x-3`}
            >
              {/* <div className="rounded-full aspect-square shrink-0 bg-black flex items-center justify-center p-2">
                <img
                  src={space.logoImageUrl}
                  className="object-contain w-4 shrink-0 h-4 aspect-square"
                  alt={space.name}
                />
              </div> */}
              <p className="text-xl text-left">
                {space.size === 'DORM'
                  ? 'Dorm'
                  : space.size === 'CLOSET'
                  ? 'Closet'
                  : 'Corner'}{' '}
                • {space.fullStreetAddress}
              </p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export const GET_SPACES_BY_SEARCH_TERM = gql`
  query getSpacesBySearchTerm($searchTerm: String) {
    getSpacesBySearchTerm(searchTerm: $searchTerm) {
      id
      campusId
      customerId
      size
      fullStreetAddress
      province
      country
      city
      postalCode
      imageUrl
      line2
      specialInstructions
      capacity
      capacityLastUpdatedAt
      isAvailable
      createdAt
    }
  }
`

export default SpaceSearchDropdown
