import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { customerClient } from '../GraphqlApolloClients'
import ActionButton from './ActionButton'
import { formatAddress, isDateStringToday } from './BookingBox'

export default function SpaceBox({
  navigate,
  space,
  openSpaceBoxes,
  setOpenSpaceBoxes,
}) {
  const { data: { getSpacePercentOccupied: percentOccupied } = {} } = useQuery(
    GET_SPACE_PERCENT_OCCUPIED,
    {
      variables: { spaceId: space.id },
      client: customerClient,
    },
  )

  return space && percentOccupied !== undefined ? (
    <div
      className={`${
        openSpaceBoxes.includes(space.id) ? 'shadow-xl' : 'h-24 shadow-sm'
      } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
    >
      <button
        onClick={(e) => {
          if (!openSpaceBoxes.includes(space.id)) {
            setOpenSpaceBoxes([...openSpaceBoxes, space.id])
          } else {
            var copiedSpaceBoxes = [...openSpaceBoxes]
            const valIndex = openSpaceBoxes.indexOf(space.id)
            if (valIndex > -1) {
              // only splice array when item is found
              copiedSpaceBoxes.splice(valIndex, 1) // 2nd parameter means remove one item only
            }
            setOpenSpaceBoxes(copiedSpaceBoxes)
          }
        }}
        className={`flex relative text-left shrink-0 items-center p-2 pr-3 sm:pr-6 text-purple-950  rounded justify-between w-full h-24 bg-light-beige hover:bg-opacity-80 transition-all`}
      >
        {isDateStringToday(space.createdAt) && (
          <p className="px-1 right-2 top-2 absolute font-semibold text-white rounded-sm bg-purple-950 tracking-wide uppercase text-xs">
            New
          </p>
        )}
        <div className="h-full aspect-square bg-white rounded">
          {space.imageUrl && (
            <img
              className="flex-grow-0 w-full h-full top-0 object-cover z-10"
              src={space.imageUrl}
              alt={'Space'}
            />
          )}
        </div>
        <div className="flex items-start justify-center flex-col ml-2 sm:ml-3 w-full truncate flex-1">
          <h1 className="text-xl sm:text-2xl leading-tight font-semibold truncate w-full sm:leading-tight">
            {formatAddress(space.fullStreetAddress)}
          </h1>
          <p className="sm:text-base text-sm leading-tight w-full">
            <b>
              <i>{space.size}</i> •
            </b>{' '}
            {space.isAvailable ? 'Available' : 'Unavailable'}
            {' | '} {`${percentOccupied.toFixed(0)}% occupied`}
          </p>
        </div>
        <svg
          className={`${
            openSpaceBoxes.includes(space.id) ? 'rotate-180' : ''
          } transform transition-all h-2 fill-current`}
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z" />
        </svg>
      </button>

      <div
        className={`${
          openSpaceBoxes.includes(space.id) ? 'scale-y-100' : 'scale-y-0 '
        } text-left flex flex-col sm:text-lg text-gray-600 transform origin-top rounded-b w-full px-2 pt-4 bg-white transition-all`}
      >
        <p className="sm:italic tracking-wide text-sm sm:text-base leading-tight mb-4">
          <b>Space:</b> {space.id}
        </p>
        <div className="w-full pb-2 items-center justify-center grid grid-cols-2 gap-1">
          <ActionButton
            onClickFunc={(e) => {
              navigate(`/editSpace/${space.id}`)
            }}
            text={'Edit'}
            type={0}
          />

          <ActionButton
            onClickFunc={(e) => {
              window.location = 'mailto:stashcampus@gmail.com'
            }}
            text="Contact"
            type={3}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export const GET_LAST_STATUS_BY_SPACE = gql`
  query getLastStatusBySpace($spaceId: String!) {
    getLastStatusBySpace(spaceId: $spaceId) {
      id
      spaceId
      step
      createdAt
    }
  }
`

export const GET_STATUSES_BY_SPACE = gql`
  query getStatusesBySpace($spaceId: String!) {
    getStatusesBySpace(spaceId: $spaceId) {
      id
      spaceId
      step
      amount
      createdAt
      description
    }
  }
`

export const GET_SPACE_PERCENT_OCCUPIED = gql`
  query getSpacePercentOccupied($spaceId: String!) {
    getSpacePercentOccupied(spaceId: $spaceId)
  }
`
