import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { CustomerAuthProvider } from "./context/customerAuth";
import { GuestAuthProvider } from "./context/guestAuth";
import BookSpace from "./pages//BookSpace";
import CreateSpace from "./pages//CreateSpace";
import Dashboard from "./pages//Dashboard";
import EditBooking from "./pages//EditBooking";
import EditSpace from "./pages//EditSpace";
import FAQ from "./pages//FAQ";
import ForgotPassword from "./pages//ForgotPassword";
import Login from "./pages//Login";
import Logout from "./pages//Logout";
import Signup from "./pages//Signup";
import Terms from "./pages//Terms";
import Welcome from "./pages//Welcome";
import AccountDetails from "./pages/AccountDetails";
import CustomerAuthRoute from "./util/CustomerAuthRoute";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/logout"
            element={
              <CustomerAuthRoute>
                <Logout />
              </CustomerAuthRoute>
            }
          />
        </Routes>
      </Router>
      <GuestAuthProvider>
        <CustomerAuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/:divId" element={<Welcome />} />
              <Route path="/shareSpace" element={<CreateSpace />} />
              <Route path="/shareSpace/:spaceStep" element={<CreateSpace />} />
              <Route path="/editSpace/:spaceId" element={<EditSpace />} />
              <Route
                path="/editSpace/:spaceId/:spaceStep"
                element={<EditSpace />}
              />
              <Route path="/editBooking/:bookingId" element={<EditBooking />} />
              <Route
                path="/editBooking/:bookingId/:bookingStep"
                element={<EditBooking />}
              />
              <Route path="/bookSpace" element={<BookSpace />} />
              <Route path="/bookSpace/:bookingId" element={<BookSpace />} />
              <Route
                path="/bookSpace/:bookingId/:bookingStep"
                element={<BookSpace />}
              />
              <Route path="/terms" element={<Terms />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/bookings" element={<Dashboard />} />
              <Route path="/dashboard/earnings" element={<Dashboard />} />
              <Route
                path="/account"
                element={
                  // <CustomerAuthRoute>
                  <AccountDetails />
                  // </CustomerAuthRoute>
                }
              />
              <Route path="/faq" element={<FAQ />} />
              <Route
                path="/login"
                element={
                  // <CustomerAuthRoute>
                  <Login initialUserType={"Customer"} />
                  // </CustomerAuthRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  // <CustomerAuthRoute>
                  <Signup initialUserType={"Customer"} />
                  // </CustomerAuthRoute>
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  // <CustomerAuthRoute>
                  <ForgotPassword initialUserType={"Customer"} />
                  // </CustomerAuthRoute>
                }
              />
            </Routes>
          </Router>
        </CustomerAuthProvider>
      </GuestAuthProvider>
    </>
  );
}

export default App;
