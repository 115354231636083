import React from 'react'

export default function BookingStatus({ text, completed, last }) {
  return (
    <div className="flex px-1 mb-3 sm:mb-4 items-start leading-tight justify-start w-full relative">
      {completed ? (
        <svg
          className="w-4 shrink-0 z-10"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44 84C66.0914 84 84 66.0914 84 44C84 21.9086 66.0914 4 44 4C21.9086 4 4 21.9086 4 44C4 66.0914 21.9086 84 44 84Z"
            fill="#3B0764"
            stroke="#3B0764"
            strokeWidth="8"
          />
          <path
            d="M28 46L39 57L62 34"
            stroke="white"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          className="w-4 shrink-0 z-10"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44 84C66.0914 84 84 66.0914 84 44C84 21.9086 66.0914 4 44 4C21.9086 4 4 21.9086 4 44C4 66.0914 21.9086 84 44 84Z"
            fill="white"
            stroke="#3B0764"
            strokeWidth="8"
          />
        </svg>
      )}
      {last && <div className="bg-white w-8 bottom-0 top-2 absolute"></div>}
      <p className="-mt-1 ml-2 z-10">{text}</p>
    </div>
  )
}
