import React from 'react'

export default function SubmitButton({
  onClickFunc,
  disabled,
  title,
  isRed,
  errors,
  isNotLoading,
  isFixedButton,
}) {
  return (
    <button
      onClick={onClickFunc}
      className={`${
        !disabled && errors && Object.keys(errors).length > 0
          ? 'motion-safe:animate-shake '
          : ''
      } ${
        isRed
          ? disabled
            ? 'bg-green-400'
            : 'bg-red-700 hover:bg-red-800'
          : disabled
          ? 'bg-green-400'
          : 'bg-red-700 hover:bg-red-800'
      } ${
        isFixedButton ? 'mt-0' : 'mt-10'
      } shrink-0 overflow-hidden shadow-lg group flex items-center justify-center text-lg relative font-bold  uppercase sm:text-xl w-full transition-all rounded-lg  text-white px-2`}
      disabled={disabled}
      type="submit"
    >
      <div
        className={`${
          !disabled && errors && Object.keys(errors).length > 0
            ? 'motion-safe:animate-fadeOut bg-red-600'
            : ''
        } inset-0 absolute`}
      ></div>
      <div
        className={`${
          !disabled || isNotLoading
            ? 'motion-safe:animate-fadeOutUpFast'
            : 'motion-safe:animate-fadeDownFast'
        } absolute inset-0 flex items-center justify-center`}
      >
        <svg
          className={`w-6 motion-safe:animate-spin`}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.636 3.636A9 9 0 0119 10"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
          />
        </svg>
      </div>

      <p
        className={`${
          disabled && !isNotLoading
            ? 'motion-safe:animate-fadeOutFast'
            : 'motion-safe:animate-fadeUpFast'
        } h-full py-4 font-semibold`}
      >
        {title}
      </p>
      <svg
        className={`${
          disabled && !isNotLoading
            ? 'motion-safe:animate-fadeOutFast'
            : 'motion-safe:animate-fadeUpFast'
        } h-3 mt-[0.05rem] fill-current ml-2`}
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className=" -translate-x-1 group-disabled:group-hover:-translate-x-1 group-hover:translate-x-0 transition-all"
          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
        />
        <rect
          className="opacity-0 group-disabled:group-hover:opacity-0 group-hover:opacity-100 transition-all"
          y="4"
          width="9"
          height="2"
        />
      </svg>
    </button>
  )
}
