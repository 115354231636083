import React from "react";

export default function ActionButton({ disabled, onClickFunc, text, type }) {
  return (
    <button
      disabled={disabled}
      onClick={onClickFunc}
      className={`${
        type === 2 || type === 3
          ? disabled
            ? "bg-green-400"
            : "bg-red-700 hover:bg-red-800"
          : type === 1
          ? disabled
            ? "bg-gray-300 text-purple-950"
            : "bg-white hover:bg-gray-100 text-purple-950"
          : disabled
          ? "bg-green-400"
          : "bg-purple-950 hover:bg-purple-900"
      } ${type === 1 ? "text-purple-950" : "text-white"} ${
        type === 2
          ? ""
          : type === 3
          ? "border-2 border-red-700"
          : "border-2 border-purple-950"
      } shrink-0  overflow-hidden shadow-lg group flex items-center justify-center text-lg ${
        type === 2 ? "" : "short:text-sm"
      } relative font-bold  uppercase w-full transition-all rounded-lg  px-2`}
    >
      <div className={`inset-0 absolute`}></div>

      <p
        className={`h-full truncate ${
          type === 2 ? "py-4" : "py-3"
        } tracking-wide leading-tight font-semibold`}
      >
        {text}
      </p>
      {!disabled && (
        <svg
          className={`h-3 ${type === 2 ? "" : "short:h-2"} fill-current ml-1`}
          viewBox="0 0 11 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className=" -translate-x-1 group-disabled:group-hover:-translate-x-1 group-hover:translate-x-0 transition-all"
            d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
          />
          <rect
            className="opacity-0 group-disabled:group-hover:opacity-0 group-hover:opacity-100 transition-all"
            y="4"
            width="9"
            height="2"
          />
        </svg>
      )}
    </button>
  );
}
