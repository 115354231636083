import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";
import usePageTracking from "../util/usePageTracking";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { customerClient } from "../GraphqlApolloClients";
import BackgroundAnimation from "../components/BackgroundAnimation";
import { GET_CAMPUSES_BY_SEARCH_TERM } from "../components/CampusSearchDropdown";
import DiscountBar from "../components/DiscountBar";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { CustomerAuthContext } from "../context/customerAuth";
import { useWindowSize } from "../util/hooks";

export default function Welcome(props) {
  let navigate = useNavigate();

  usePageTracking();

  const resourcesRef = useRef();
  const productsRef = useRef();
  const pricingRef = useRef();
  const pageTopRef = useRef();
  const { divId } = useParams();

  useEffect(() => {
    document.title = "StashCampus";
  }, []);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const size = useWindowSize();

  const {
    data: { getCampusesBySearchTerm: campuses } = {},
    loading: loadingCampuses,
  } = useQuery(GET_CAMPUSES_BY_SEARCH_TERM, {
    variables: { searchTerm: "", limit: 8 },
    client: customerClient,
    onError(err) {
      // console.log("getCampusesBySearchTerm() Error!");
      // console.log(err);
    },
  });

  const [hoveredSpaceSize, setHoveredSpaceSize] = useState("Dorm");

  useEffect(() => {
    if (divId === "product" && productsRef && productsRef.current) {
      productsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else if (divId === "reviews" && resourcesRef && resourcesRef.current) {
      resourcesRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else if (divId === "pricing" && pricingRef && pricingRef.current) {
      pricingRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [divId, loadingCampuses, productsRef, pricingRef, resourcesRef]);

  return (
    <div
      ref={pageTopRef}
      className="w-screen relative overflow-x-hidden h-full min-h-screen"
    >
      {size.width < 768 && <DiscountBar />}

      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-200/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-200/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-200/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-200/30 "></div>
        <div className="border-l h-full border-purple-200/30 "></div>
      </div>

      <div className="w-screen relative overflow-x-hidden min-h-screen sm:min-h-[85vh] xl:min-h-screen pb-16 xl:pb-0">
        <BackgroundAnimation gradIndex={7} />
        {size.width >= 768 && <DiscountBar />}
        <div className="relative max-w-5xl mx-auto px-6 xl:px-0 sm:px-8 pb-10 lg:pb-0">
          <NavBar
            page="landing"
            productsRef={productsRef}
            resourcesRef={resourcesRef}
            pricingRef={pricingRef}
            pageTopRef={pageTopRef}
          />

          <div className="flex w-full absolute top-28 sm:top-48 z-10 space-x-10 sm:space-x-20 items-end justify-start">
            <div className=" flex-grow-0 whitespace-nowrap">
              <p className="font-bold hidden sm:block z-10 opacity-80 top-0 left-0 leading-none text-[2.4rem] sm:text-4xl xl:text-7xl mt-4">
                Store your things at a
                <br /> fellow student's place
                <br />
                <span className="text-purple-950 italic text-[2rem] sm:text-3xl xl:text-5xl">
                  Free pickup &amp; delivery
                </span>
              </p>

              <p className="font-bold whitespace-pre-wrap sm:hidden z-10 opacity-80 md:top-0 left-0 leading-none scale-[.93] sm:scale-100 origin-left text-[2.4rem] sm:text-4xl xl:text-7xl sm:mt-10">
                Store your
                <br />
                things at a<br />
                peer's place
                <br />
                <span className="text-purple-950 italic text-[2rem] sm:text-3xl xl:text-5xl">
                  FREE pickup &amp; delivery
                </span>
              </p>

              <div className="mt-8 flex sm:space-x-2 space-y-2 sm:space-y-0 flex-col sm:flex-row  sm:items-center items-start justify-start">
                <a
                  href="/bookSpace"
                  className="group flex sm:h-20 w-64 text-xl hover:bg-opacity-80 border-purple-950  border-2 transition-all items-center justify-center uppercase space-x-2 bg-purple-950 text-white rounded-lg backdrop-blur-sm pl-5 pr-4 sm:pl-12 sm:pr-11 py-5"
                >
                  <p className="font-bold">Book now</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </a>

                <a
                  href="/shareSpace"
                  className="group flex sm:h-20 w-64 text-xl hover:bg-opacity-80 transition-all items-center justify-center  space-x-2 bg-white text-purple-950 border-2 border-purple-950 rounded-lg backdrop-blur-sm pl-12 pr-11 py-3"
                >
                  <div className="flex leading-tight items-center justify-center flex-col">
                    <p className="text-base">Or share your space and</p>
                    <p className="font-bold italic">EARN $100/mo*</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
        {[1, 2, 3, 4, 5].map((_, index) => (
          <button
            className={`${
              index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
            } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
            key={index}
          >
            <img
              src={profilePic}
              className="border-white border-2 box-border rounded-full "
            />
          </button>
        ))}
        <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
          <p className="text-3xl text-black font-semibold">Someboody Sum</p>
          <p className="text-lg text-gray-600">
            Spacer since 06/22 - Earns US$100/mo
          </p>
        </div>
      </div> */}
      <div
        id="product"
        className="bg-gray-50 w-full flex flex-col items-center justify-start"
      >
        <div
          ref={productsRef}
          className="flex flex-col relative items-start max-w-5xl xl:px-0 px-6 sm:px-8 mx-auto justify-start py-16 sm:py-36"
        >
          <p className="text-purple-700 font-bold mb-10 text-lg pt-4 w-full">
            Built for students
          </p>
          <p className="text-black font-bold text-3xl sm:text-4xl leading-heading mb-10 sm:w-1/2">
            A custom storage solution for your busy schedule
          </p>

          <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl mx-auto justify-start mb-10">
            <p className="w-full text-lg text-gray-600">
              Just{" "}
              <a
                href="/bookSpace"
                className="transition-colors font-semibold text-purple-700 hover:text-black"
              >
                select your campus
              </a>
              , specify how much space you need, and set a pickup date. We'll
              store your things in a verified student's dorm/apartment and bring
              them back to you when you're ready.
            </p>
            <p className="w-full text-lg text-gray-600">
              While you're in school you can{" "}
              <a
                href="/shareSpace"
                className="transition-colors font-semibold text-purple-700 hover:text-black"
              >
                rent out your extra space
              </a>{" "}
              to fellow students and earn around $100/month. Our team will
              manage dropping off and picking up items based on your
              availability so you can just relax.
            </p>
          </div>

          <div className="flex items-center justify-start space-x-1 sm:space-x-2">
            <a
              href={"/bookSpace"}
              className="group mb-20 flex hover:bg-black transition-all items-center justify-center space-x-2 bg-purple-700 text-white rounded-lg backdrop-blur-sm sm:pl-4 pl-3 pr-2 sm:pr-3 py-1"
            >
              <p className="font-bold whitespace-nowrap">
                {size.width >= 640 ? "Start your booking" : "Start booking"}
              </p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </a>
            <a
              href="/shareSpace"
              className="group mb-20 flex transition-all items-center justify-center space-x-2 text-purple-600 hover:text-black rounded-lg backdrop-blur-sm sm:pl-4 pl-3 py-1"
            >
              <p className="font-bold whitespace-nowrap">
                {size.width >= 640 ? "Share your space" : "Share space"}
              </p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </a>
          </div>

          <div className="w-full select-none grid grid-cols-2 sm:grid-cols-4 gap-10 px-4 sm:gap-24">
            {campuses &&
              campuses.length > 0 &&
              campuses.map((campus, index) => (
                <div key={index} className="w-full h-14">
                  <img
                    src={campus.textImageUrl}
                    alt={campus.name}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row bg-purple-950 items-start justify-start relative">
        <div
          ref={pricingRef}
          className="flex items-start sm:flex-row flex-col max-w-5xl sm:space-y-0 space-y-10 sm:space-x-10 xl:px-0 px-6 sm:px-8 h-full mx-auto justify-start z-10 py-24 sm:py-36 w-full"
        >
          <div className="flex flex-col items-start justify-start sm:w-1/2">
            <p className="text-light-beige font-bold mb-7 sm:mb-10 text-lg">
              Best price guarantee
            </p>
            <p className="text-white font-bold text-3xl sm:text-4xl leading-heading mb-7 sm:mb-10">
              Skip the high fees of commercial storage while supporting other
              students
            </p>

            <p className="text-lg text-gray-300 mb-10">
              We take care of picking up your items, arranging them at a fellow
              student's place, and bringing them back to you—all at an
              affordable price. Demand increases near the end of each term, so
              book now to get 30% off!
              {/* the earlier you book, the more money you’ll save. */}
            </p>

            {/* <p className="text-lg text-gray-300 mt-3 mb-7 sm:mb-10">
              Tip: Share your invite code to unlock $10 off for both you and
              your friend.
            </p> */}

            <a
              href="/bookSpace"
              className="group flex hover:bg-white transition-all items-center justify-center space-x-2 bg-light-beige text-green-900 rounded-lg backdrop-blur-sm pl-3 sm:pl-4 pr-2 sm:pr-3 py-1"
            >
              <p className="font-bold">Book now</p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </a>
          </div>

          <div className=" select-none sm:w-1/2 w-full h-full flex-shrink-0 relative">
            <div className="bg-white shadow-xl -mr-4 sm:-mr-0 xl:-mr-6 -ml-4 sm:ml-0 rounded gap-1 p-1 h-full grid xl:grid-cols-2 grid-cols-1">
              <div className="flex items-start flex-col h-full w-full px-7 py-7 sm:py-14">
                <h2 className="text-3xl text-black font-semibold">
                  {hoveredSpaceSize}
                </h2>
                <p className=" text-xl mt-4">
                  {hoveredSpaceSize === "Corner"
                    ? "Fits 1 large U-Haul box or suitcase."
                    : hoveredSpaceSize === "Closet"
                    ? "Fits 3 large U-Haul boxes or suitcases."
                    : "Fits 6 large U-Haul boxes or suitcases."}
                </p>
                <div className="flex-1"></div>
                <a
                  href="/bookSpace"
                  className="group  border hidden xl:flex transition-all items-center justify-center space-x-2 text-purple-600 border-purple-200 hover:text-black rounded-lg backdrop-blur-sm pl-3 sm:pl-4 pr-2 sm:pr-3 py-1"
                >
                  <p className="font-bold">Select</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </a>
              </div>
              <div className=" items-center justify-center space-y-1 flex w-full flex-col">
                <div
                  onMouseOver={(e) => {
                    e.preventDefault();
                    setHoveredSpaceSize("Corner");
                  }}
                  className={`${
                    hoveredSpaceSize === "Corner" ? "bg-purple-200" : ""
                  } flex items-center justify-between bg-purple-100 hover:bg-purple-200 transition-all rounded w-full px-6 py-4 sm:py-6`}
                >
                  <p className="uppercase text-sm font-semibold">Corner</p>
                  <p className="font-bold whitespace-nowrap text-3xl sm:text-3xl">
                    <span className="opacity-20 line-through">$26</span>{" "}
                    <span className="">$19</span>
                    <span className="text-lg">/mo</span>
                  </p>
                </div>
                <div
                  onMouseOver={(e) => {
                    e.preventDefault();
                    setHoveredSpaceSize("Closet");
                  }}
                  className={`${
                    hoveredSpaceSize === "Closet" ? "bg-purple-200" : ""
                  } flex items-center justify-between bg-purple-100 hover:bg-purple-200 transition-all rounded w-full px-6 py-6 sm:py-10`}
                >
                  <p className="uppercase text-sm font-semibold">Closet</p>
                  <p className="font-bold whitespace-nowrap text-3xl sm:text-3xl">
                    <span className="opacity-20 line-through">$32</span>{" "}
                    <span className="">$24</span>
                    <span className="text-lg">/mo</span>
                  </p>
                </div>
                <div
                  onMouseOver={(e) => {
                    e.preventDefault();
                    setHoveredSpaceSize("Dorm");
                  }}
                  className={`${
                    hoveredSpaceSize === "Dorm" ? "bg-purple-200" : ""
                  } flex items-center justify-between bg-purple-100 hover:bg-purple-200 transition-all rounded w-full px-6 py-10 sm:py-14`}
                >
                  <p className="uppercase text-sm font-semibold">Dorm</p>
                  <p className="font-bold whitespace-nowrap text-3xl sm:text-3xl">
                    <span className="opacity-20 line-through">$42</span>{" "}
                    <span className="">$32</span>
                    <span className="text-lg">/mo</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-start justify-start pb-24 sm:pb-36 relative">
        <div
          ref={resourcesRef}
          className="flex items-start flex-col max-w-5xl sm:space-y-0 space-y-10 xl:px-0 px-6 sm:px-8 h-full mx-auto justify-start pt-24 sm:pt-36 z-10 w-full"
        >
          <div className="flex items-start sm:flex-row flex-col sm:space-x-2 ">
            <div className="flex flex-col mb-12 sm:mb-0  items-start justify-start ">
              <p className="text-3xl text-black leading-none font-semibold">
                Ready to get started?
              </p>

              <p className="w-full text-lg text-gray-600 mt-8 mb-8 sm:mb-10 pr-4">
                Make your booking now, or{" "}
                <a
                  href="/signup"
                  className="transition-colors font-semibold text-purple-700 hover:text-black"
                >
                  create a verified student account
                </a>{" "}
                and come back later. You can also rent out your own space in
                minutes using this account. Contact us if you have any
                questions!
              </p>
              <div className="flex space-x-2 items-center justify-start">
                <a
                  href="/bookSpace"
                  className={`bg-purple-700 group flex hover:bg-black transition-all items-center justify-center space-x-2 text-white rounded-lg backdrop-blur-sm pl-3 sm:pl-4 pr-2 sm:pr-3 py-1`}
                >
                  <p className="font-bold whitespace-nowrap">Book now</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </a>

                <a
                  href="/shareSpace"
                  className={`text-purple-700 group flex transition-all items-center justify-center space-x-2 hover:text-black rounded-lg pl-3 sm:pl-4 pr-2 sm:pr-3 py-1`}
                >
                  <p className="font-bold">
                    {size.width >= 640 ? "Share your space" : "Share space"}
                  </p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="sm:w-1/2 w-full h-full flex-shrink-0 relative">
              <div className=" -mr-4 sm:-mr-0 xl:-mr-6 -ml-4 sm:ml-0 rounded gap-8 sm:gap-4 px-2 h-full grid-flow-row-dense grid xl:grid-cols-2 grid-cols-1">
                <div className="flex flex-col items-start w-full">
                  <div className=" rounded-lg w-full flex-col h-full space-y-3 flex items-start justify-center pl-3 pr-6">
                    <svg
                      className="sm:w-8 aspect-square w-9"
                      viewBox="0 0 171 150"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M107.206 68.4581C111.733 71.6445 111.733 78.3555 107.206 81.542L12.6047 148.128C7.30434 151.859 1.611e-05 148.068 1.63933e-05 141.586L2.22144e-05 8.4139C2.24977e-05 1.93227 7.30435 -1.85876 12.6047 1.87194L107.206 68.4581Z"
                        className=" fill-current text-light-beige mix-blend-screen"
                      />
                      <g className=" fill-current text-purple-600 mix-blend-screen">
                        <path d="M167.206 68.4581C171.733 71.6445 171.733 78.3555 167.206 81.542L72.6047 148.128C67.3043 151.859 60 148.068 60 141.586L60 8.4139C60 1.93227 67.3043 -1.85876 72.6047 1.87194L167.206 68.4581Z" />
                      </g>
                    </svg>

                    <div className=" text-black font-semibold relative">
                      <div className="inset-y-0 w-1 border-l border-purple-800 h-full absolute -left-[1.27rem]"></div>
                      Bookings dashboard
                    </div>
                    <p className=" text-gray-600 ">
                      Track information about your booking and 24h support in
                      one place.
                    </p>
                    <a
                      href={"/dashboard/bookings"}
                      className={`text-purple-600 group flex transition-all items-center justify-center space-x-2 hover:text-black rounded-full py-1`}
                    >
                      <p className="font-bold">Try now</p>
                      <svg
                        className="h-2.5 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-end w-full">
                  <div className=" space-y-3 rounded-lg w-full flex-col flex items-start justify-center pl-3 pr-6">
                    <svg
                      className="sm:w-8 aspect-square w-9"
                      viewBox="0 0 111 107"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M45.5184 29.0608L74.0184 1.41132C75.9581 -0.470441 79.0419 -0.470439 80.9816 1.41132L109.482 29.0608C110.452 30.0024 111 31.2971 111 32.6494V101.034C111 103.795 108.761 106.034 106 106.034H49C46.2386 106.034 44 103.795 44 101.034V32.6494C44 31.2971 44.5478 30.0024 45.5184 29.0608ZM77.5 36.0336C82.1944 36.0336 86 32.2281 86 27.5336C86 22.8392 82.1944 19.0336 77.5 19.0336C72.8056 19.0336 69 22.8392 69 27.5336C69 32.2281 72.8056 36.0336 77.5 36.0336Z"
                        className=" fill-current  text-light-beige mix-blend-screen"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M53.7957 6.21395L93.0634 6.80878C95.7655 6.84971 97.9461 9.03033 97.987 11.7325L98.5819 51.0001C98.6024 52.3523 98.0742 53.6551 97.118 54.6114L49.2618 102.468C47.3092 104.42 44.1434 104.42 42.1908 102.468L2.32831 62.6051C0.375685 60.6524 0.375689 57.4866 2.32831 55.534L50.1845 7.67784C51.1407 6.72158 52.4435 6.19346 53.7957 6.21395ZM71.2945 33.5012C74.583 36.7897 79.9146 36.7897 83.203 33.5012C86.4915 30.2128 86.4915 24.8811 83.203 21.5927C79.9146 18.3043 74.5829 18.3043 71.2945 21.5927C68.0061 24.8811 68.0061 30.2128 71.2945 33.5012Z"
                        className=" fill-current text-purple-600 mix-blend-screen"
                      />
                    </svg>

                    <div className=" text-black font-semibold relative">
                      <div className="inset-y-0 w-1 border-l border-purple-800 h-full absolute -left-[1.27rem]"></div>
                      Earnings dashboard
                    </div>
                    <p className=" text-gray-600 ">
                      Manage how your spaces are rented and track your monthly
                      revenue.
                    </p>
                    <a
                      href={"/dashboard/earnings"}
                      className={`text-purple-600 group flex transition-all items-center justify-center space-x-2 hover:text-black rounded-full py-1`}
                    >
                      <p className="font-bold">Try now</p>
                      <svg
                        className="h-2.5 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="items-center max-w-[68rem] px-4 m-auto justify-end h-full w-full mt-16 grid sm:grid-cols-2 2xl:grid-cols-4 grid-flow-row-dense gap-4 sm:gap-4">
          <div className="bg-light-beige/50 rounded-lg w-full flex-col h-full flex items-start justify-center p-8">
            <p className="italic text-xl">
              “Honestly, the best storage experience I've ever had. Will be
              renting out my space when I get back from co-op!”
            </p>
            <div className="flex-1"></div>
            <div className="flex items-center justify-start mt-4">
              <div className="w-10 aspect-square rounded-full bg-white mr-2"></div>
              {[1, 2, 3, 4, 5].map((val, index) => (
                <svg
                  className="h-6 fill-current"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.5 17.77L17.4225 21.5L15.8508 14.47L21.0833 9.74L14.1929 9.13L11.5 2.5L8.80709 9.13L1.91667 9.74L7.14917 14.47L5.57751 21.5L11.5 17.77Z" />
                </svg>
              ))}
            </div>
            <p className="mt-2 text-sm">Katy Chung, UWaterloo 2034</p>
          </div>
          <div className="bg-purple-100 rounded-lg w-full flex-col h-full flex items-start justify-center p-8">
            <p className="italic text-xl">
              “Super easy. Booked in minutes, arrived on time, picked up and
              stored!”{" "}
            </p>
            <div className="flex-1"></div>
            <div className="flex items-center justify-start mt-4">
              <div className="w-10 aspect-square rounded-full bg-white mr-2"></div>
              {[1, 2, 3, 4, 5].map((val, index) => (
                <svg
                  className="h-6 fill-current"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.5 17.77L17.4225 21.5L15.8508 14.47L21.0833 9.74L14.1929 9.13L11.5 2.5L8.80709 9.13L1.91667 9.74L7.14917 14.47L5.57751 21.5L11.5 17.77Z" />
                </svg>
              ))}
            </div>
            <p className="mt-2 text-sm">Katy Chung, UWaterloo 2034</p>
          </div>
          <div className="bg-purple-100 rounded-lg w-full flex-col h-full flex items-start justify-center p-8">
            <p className="italic text-xl">
              “Best product out there for co-op students who need to rent out a
              space.”
            </p>
            <div className="flex-1"></div>

            <div className="flex items-center justify-start mt-4">
              <div className="w-10 aspect-square rounded-full bg-white mr-2"></div>
              {[1, 2, 3, 4, 5].map((val, index) => (
                <svg
                  className="h-6 fill-current"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.5 17.77L17.4225 21.5L15.8508 14.47L21.0833 9.74L14.1929 9.13L11.5 2.5L8.80709 9.13L1.91667 9.74L7.14917 14.47L5.57751 21.5L11.5 17.77Z" />
                </svg>
              ))}
            </div>
            <p className="mt-2 text-sm">Katy Chung, UWaterloo 2034</p>
          </div>
          <div className="bg-light-beige/50 rounded-lg w-full flex-col h-full flex items-start justify-center p-8">
            <p className="italic text-xl">
              “Gonna book again for my next co-op term. It’s amazing!”
            </p>
            <div className="flex-1"></div>

            <div className="flex items-center justify-start mt-4">
              <div className="w-10 aspect-square rounded-full bg-white mr-2"></div>
              {[1, 2, 3, 4, 5].map((val, index) => (
                <svg
                  className="h-6 fill-current"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.5 17.77L17.4225 21.5L15.8508 14.47L21.0833 9.74L14.1929 9.13L11.5 2.5L8.80709 9.13L1.91667 9.74L7.14917 14.47L5.57751 21.5L11.5 17.77Z" />
                </svg>
              ))}
            </div>
            <p className="mt-2 text-sm">Katy Chung, UWaterloo 2034</p>
          </div>
        </div> */}
      </div>
      <Footer
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
        isDiscountOn={true}
      />
    </div>
  );
}
