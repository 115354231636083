import React from 'react'

export default function SpaceSelection({
  title,
  description,
  errors,
  setErrors,
  values,
  setValues,
  disabled,
  isForSpaces,
}) {
  const error = !errors || !errors['size'] ? null : '- Required!'
  function getNewFieldsToEdit(val) {
    var newFieldsToEdit = []
    if (values.fieldsToEdit) {
      newFieldsToEdit = [...values.fieldsToEdit]
      if (!newFieldsToEdit.includes(val)) {
        newFieldsToEdit.push(val)
      }
    }
    return newFieldsToEdit
  }
  return (
    <div className="w-full relative">
      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          {isForSpaces ? 'Space available' : 'Space needed'}
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="w-full h-12 flex items-center justify-start space-x-2 sm:space-x-3">
        <button
          disabled={disabled}
          onClick={(e) => {
            setValues({
              ...values,
              size: 'CORNER',
              fieldsToEdit: getNewFieldsToEdit('size'),
            })
          }}
          className={`${
            values.size === 'CORNER'
              ? 'bg-purple-600 text-white'
              : !disabled
              ? 'bg-white hover:bg-purple-500 hover:text-white'
              : 'bg-white'
          } ${
            disabled && 'bg-opacity-70'
          } h-full col-span-4 px-5 shrink-0 text-center  transition-all rounded shadow-sm`}
        >
          Corner
        </button>
        <button
          onClick={(e) => {
            setValues({
              ...values,
              size: 'CLOSET',
              fieldsToEdit: getNewFieldsToEdit('size'),
            })
          }}
          disabled={disabled}
          className={`${
            values.size === 'CLOSET'
              ? 'bg-purple-600 text-white'
              : !disabled
              ? 'bg-white hover:bg-purple-500 hover:text-white'
              : 'bg-white'
          } ${
            disabled && 'bg-opacity-70'
          } h-full px-5 sm:w-1/3 shrink-0 text-center  transition-all rounded shadow-sm`}
        >
          Closet
        </button>
        <button
          disabled={disabled}
          onClick={(e) => {
            setValues({
              ...values,
              size: 'DORM',
              fieldsToEdit: getNewFieldsToEdit('size'),
            })
          }}
          className={`${
            values.size === 'DORM'
              ? 'bg-purple-600 text-white'
              : !disabled
              ? 'bg-white hover:bg-purple-500 hover:text-white'
              : 'bg-white'
          } ${
            disabled && 'bg-opacity-70'
          } h-full w-full text-center   transition-all rounded shadow-sm`}
        >
          Dorm
        </button>
      </div>
    </div>
  )
}
