import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  customer: null,
};

if (localStorage.getItem("customerJwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("customerJwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("customerJwtToken");
  } else {
    initialState.customer = decodedToken;
  }
}

const CustomerAuthContext = createContext({
  customer: null,
  loginCustomer: (customerData) => { },
  logoutCustomer: () => { },
});

function customerAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    case "LOGOUT_CUSTOMER":
      return {
        ...state,
        customer: null,
      };
    default:
      return state;
  }
}

function CustomerAuthProvider(props) {
  const [state, dispatch] = useReducer(customerAuthReducer, initialState);

  function loginCustomer(customerData) {
    console.log("INSIDE loginCustomer()");
    console.log(customerData);

    localStorage.setItem("customerJwtToken", customerData.token);
    dispatch({
      type: "LOGIN_CUSTOMER",
      payload: customerData,
    });
  }

  function logoutCustomer() {
    // Also remove any guest keys.
    localStorage.removeItem("guestJwtToken");

    localStorage.removeItem("customerJwtToken");
    dispatch({ type: "LOGOUT_CUSTOMER" });
  }

  return (
    <CustomerAuthContext.Provider
      value={{ customer: state.customer, loginCustomer, logoutCustomer }}
      {...props}
    />
  );
}

export { CustomerAuthContext, CustomerAuthProvider };
