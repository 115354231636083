import React, { useState } from "react";
import { useWindowSize } from "../util/hooks";
import ActionButton from "./ActionButton";
import BookingBox from "./BookingBox";

export default function Bookings({
  navigate,
  currentBookings,
  pastBookings,
  referralCredits,
  isReferralDisplayOpen,
  setIsReferralDisplayOpen,
  values,
  setValues,
}) {
  useWindowSize();
  const [openCurrentBookingBoxes, setOpenCurrentBookingBoxes] = useState([]);
  const [openPastBookingBoxes, setOpenPastBookingBoxes] = useState([]);

  
  return (
    <div className="w-full flex flex-col items-center min-h-screen justify-start">
      <div
        className={`flex z-10 flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start`}
      >
        <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start py-14 ">
          <div className="w-full flex flex-col space-y-10 mb-6 sm:mb-2">
            {currentBookings &&
              currentBookings.length === 0 &&
              pastBookings &&
              pastBookings.length === 0 && (
                <div className="w-full flex flex-col space-y-4">
                  <p className="text-white font-bold mb-2 text-4xl leading-heading">
                    Welcome to StashCampus!
                  </p>
                  <p className="text-lg text-white">
                    You'll be able to track your bookings here.
                  </p>
                </div>
              )}
            {currentBookings && currentBookings.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <p className="text-white font-bold mb-6 text-4xl leading-heading">
                  Active Bookings
                </p>
                {currentBookings.map((currentBooking, index) => (
                  <BookingBox
                    key={index}
                    values={values}
                    setValues={setValues}
                    navigate={navigate}
                    isPast={false}
                    booking={currentBooking}
                    openBookingBoxes={openCurrentBookingBoxes}
                    setOpenBookingBoxes={setOpenCurrentBookingBoxes}
                  />
                ))}
              </div>
            )}
            {pastBookings && pastBookings.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <p className="text-white font-bold mb-6 text-4xl leading-heading">
                  Past Bookings
                </p>
                {pastBookings.map((pastBooking, index) => (
                  <BookingBox
                    key={index}
                    values={values}
                    setValues={setValues}
                    isPast={true}
                    navigate={navigate}
                    booking={pastBooking}
                    openBookingBoxes={openPastBookingBoxes}
                    setOpenBookingBoxes={setOpenPastBookingBoxes}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            {/* <div className="bg-gray-100 rounded-lg w-full h-64"></div> */}

            {/* TODO: Show the correct statistic here (we don't have referral credits). */}
            <div className="flex text-gray-500 flex-col text-base items-center justify-center h-80 p-2 short:p-0">
              <h2 className="mt-4">Your credits</h2>

              {/* <button
                  onClick={(e) => {
                    navigate("/bookSpace");
                  }}
                  className="group flex sm:h-20 w-full text-xl hover:bg-opacity-80 border-purple-950  border-2 transition-all items-center justify-center uppercase space-x-2 bg-purple-950 text-white rounded-lg backdrop-blur-sm pl-5 pr-4 sm:pl-12 sm:pr-11 py-5"
                >
                  <p className="font-bold">Book for next term</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </button> */}
              <div className="flex motion-safe:animate-fadeInFast items-center justify-center leading-none flex-1 pb-6 font-extrabold text-purple-900 text-6xl">
                {referralCredits
                  ? "$" + (referralCredits / 100).toFixed(2)
                  : "-"}
              </div>

              <div className="w-full items-center justify-center flex flex-col space-y-2">
                <div className="w-full items-center justify-center grid grid-cols-2 gap-2">
                  <ActionButton
                    onClickFunc={(e) => {
                      navigate("/bookSpace");
                    }}
                    type={0}
                    text={"Book space"}
                  />

                  <ActionButton
                    onClickFunc={(e) => {
                      navigate("/shareSpace");
                    }}
                    type={1}
                    text="Share space"
                  />
                </div>
                <ActionButton
                  onClickFunc={(e) => {
                    setIsReferralDisplayOpen(true);
                  }}
                  type={2}
                  text="Invite a friend"
                />
              </div>
            </div>

            {/* <div className="flex mt-4 px-10 text-gray-500 text-base items-center justify-between space-x-2">
                <p>Taxes for this month</p>
                <p className="font-semibold">$5.52</p>
              </div>

              <div className="flex px-10 mt-4 text-gray-500 text-base items-center justify-between space-x-2">
                <p>Easter egg discount</p>
                <p className="font-semibold">$4.00</p>
              </div>

              <div className="flex px-10 mt-4 mb-6 text-black text-base items-center justify-between space-x-2">
                <p>Total</p>
                <p className="font-semibold">CA$14.00</p>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
