import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { customerClient } from '../GraphqlApolloClients'
import { GET_CAMPUS_BY_ID, GET_PAYMENT_BY_BOOKING } from '../pages/BookSpace'
import ActionButton from './ActionButton'
import {
  formatAddress,
  formatDate,
  GET_BOOKING_TIMELINE,
  GET_LAST_STATUS_BY_BOOKING,
  GET_STATUSES_BY_BOOKING,
  isDateStringToday,
  SLOT_TO_TIME,
} from './BookingBox'
import BookingStatus from './BookingStatus'

export function daysBetween(startDate, endDate) {
  return (
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
    (1000 * 3600 * 24)
  )
}

export function formatBookingWindow(startDate, endDate) {
  const days = daysBetween(startDate, endDate)

  if (days < 30) {
    return '~' + Math.floor(days) + '-day'
  }

  const months = Math.floor(days / 30)

  return '~' + months + '-month'
}

export default function SpaceBookingBox({
  space,
  booking,
  openBookingBoxes,
  setOpenBookingBoxes,
  isPast,
  navigate,
  values,
  setValues,
}) {
  const { data: { getPaymentByBooking: payment } = {} } = useQuery(
    GET_PAYMENT_BY_BOOKING,
    {
      client: customerClient,
      variables: { bookingId: booking.id },
      onCompleted() {
        console.log('Successfully completed getPaymentByBooking().')
      },
      onError(err) {
        console.log('getPaymentByBooking() error!')
        console.log(err)
      },
    },
  )

  /* Any booking that is shown here is necessarily CREATED and BOOKED (i.e. paid for). */
  const { data: { getCampusById: campus } = {} } = useQuery(GET_CAMPUS_BY_ID, {
    variables: { campusId: booking.campusId },
    client: customerClient,
  })

  const { data: { getStatusesByBooking: statuses } = {} } = useQuery(
    GET_STATUSES_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
      onCompleted() {
        console.log('Successfully completed getStatusesByBooking().')
      },
      onError(err) {
        console.log('getStatusesByBooking() error!')
        console.log(err)
      },
    },
  )

  const { data: { getBookingTimeline: timeline } = {} } = useQuery(
    GET_BOOKING_TIMELINE,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
      onError(err) {
        console.log('getBookingTimeline() error!')
        console.log(err)
      },
      onCompleted() {
        console.log('Successfully completed getBookingTimeline().')
      },
    },
  )

  const { data: { getLastStatusByBooking: lastStatus } = {} } = useQuery(
    GET_LAST_STATUS_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
      onCompleted() {
        console.log('Successfully completed getLastStatusByBooking().')
      },
      onError(err) {
        console.log('getLastStatusByBooking() error!')
        console.log(err)
      },
    },
  )

  useEffect(() => {
    if (campus && !values.campusName) {
      console.log(campus)
      setValues({
        ...values,
        campusName: campus.name,
        campusLogoUrl: campus.logoUrl,
        dormImageUrl: campus.dormImageUrl,
        closetImageUrl: campus.closetImageUrl,
        cornerImageUrl: campus.cornerImageUrl,
      })
    }
  }, [campus, values, setValues])

  const steps = [
    'CONFIRMED',
    'PICKED',
    'PAID_OWNER_HALF',
    'DROPPED',
    'PAID_OWNER_FULL',
  ]

  function getDescriptionByStep(step, completed) {
    const status = completed
      ? statuses.find((status) => status.step === step)
      : null

    switch (step) {
      case 'CONFIRMED':
        /* Necessarily completed. */
        return `${formatBookingWindow(booking.startDate, booking.endDate)}, $${(
          payment.ownerTotal / 100
        ).toFixed(2)} contract accepted ${
          completed ? 'on ' + formatDate(status.createdAt) : ''
        }`
      case 'PICKED':
        return `Items received between ${
          SLOT_TO_TIME[booking.startTime]
        } on ${formatDate(booking.startDate)}`
      case 'PAID_OWNER_HALF':
        return `First payment of $${(payment.ownerTotal / 2 / 100).toFixed(
          2,
        )} received ${completed ? 'on ' + formatDate(status.createdAt) : ''}`
      case 'PAID_OWNER_FULL':
        return `Final payment of $${(payment.ownerTotal / 2 / 100).toFixed(
          2,
        )} received ${completed ? 'on ' + formatDate(status.createdAt) : ''}`
      case 'DROPPED':
        return `Items returned between ${
          SLOT_TO_TIME[booking.startTime]
        } on ${formatDate(booking.startDate)}`
      default:
        break
    }
  }

  // TODO: Maybe add date to summary view in case they make identical bookings.
  return statuses && timeline && lastStatus && payment && booking && space ? (
    <div
      className={`${
        openBookingBoxes.includes(booking.id) ? 'shadow-xl' : 'h-24 shadow-sm'
      } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
    >
      <button
        onClick={(e) => {
          if (!openBookingBoxes.includes(booking.id)) {
            setOpenBookingBoxes([...openBookingBoxes, booking.id])
          } else {
            var copiedBookingBoxes = [...openBookingBoxes]
            const valIndex = openBookingBoxes.indexOf(booking.id)
            if (valIndex > -1) {
              // only splice array when item is found
              copiedBookingBoxes.splice(valIndex, 1) // 2nd parameter means remove one item only
            }
            setOpenBookingBoxes(copiedBookingBoxes)
          }
        }}
        className={`flex text-left relative shrink-0 items-center p-2 pr-3 sm:pr-6 text-purple-950  rounded justify-between w-full h-24 bg-light-beige hover:bg-opacity-80 transition-all`}
      >
        {isDateStringToday(lastStatus.createdAt) && (
          <p className="px-1 right-2 top-2 absolute font-semibold text-white rounded-sm bg-purple-950 tracking-wide uppercase text-xs">
            New
          </p>
        )}
        <div className="h-full aspect-square bg-white rounded">
          {campus && (
            <img
              className="flex-grow-0 w-full h-full top-0 object-cover p-1 z-10"
              src={
                booking.size === 'DORM'
                  ? campus.dormImageUrl
                  : booking.size === 'CLOSET'
                  ? campus.closetImageUrl
                  : campus.cornerImageUrl
              }
              alt={'Campus URL'}
            />
          )}
        </div>
        <div className="flex items-start justify-center flex-col ml-2 sm:ml-3 w-full truncate flex-1">
          <h1 className="text-xl sm:text-2xl leading-tight font-semibold truncate w-full sm:leading-tight">
            {formatAddress(space.fullStreetAddress)}
          </h1>
          <p className="sm:text-base text-sm leading-tight w-full">
            <b>
              <i>{booking.size}</i> •
            </b>{' '}
            {isPast
              ? 'Earned $' + (payment.ownerTotal / 100).toFixed(2)
              : '$' +
                (payment.ownerTotal / 2 / 100).toFixed(2) +
                ' payout in ' +
                timeline[0]}
          </p>
          {/* Note: The amount shown here is based on the fact that we're going to be paying them in two installments (i.e. exactly half each time). */}
        </div>
        <svg
          className={`${
            openBookingBoxes.includes(booking.id) ? 'rotate-180' : ''
          } transform transition-all h-2 fill-current`}
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z" />
        </svg>
      </button>
      <div
        className={`${
          openBookingBoxes.includes(booking.id) ? 'scale-y-100' : 'scale-y-0 '
        } text-left flex flex-col sm:text-lg text-gray-600 transform origin-top rounded-b w-full px-2 pt-4 bg-white transition-all`}
      >
        <p className="sm:italic tracking-wide text-sm sm:text-base leading-tight mb-4">
          <b>Booking:</b> {booking.id} • <b>Space:</b> {space.id}
        </p>
        <div className="flex flex-col items-start justify-start w-full h-full sm:pb-0 relative">
          <div className="w-0.5 bg-purple-950 absolute left-[0.7rem] top-2 bottom-7"></div>

          {/* We will show the payments that are recorded, otherwise we'll show them in the order we expect them. */}
          {steps.map((step, index) => (
            <div key={index}>
              <BookingStatus
                text={getDescriptionByStep(
                  step,
                  statuses.some((status) => status.step === step), // true if completed, false otherwise
                )}
                completed={statuses.some((status) => status.step === step)} // true if completed, false otherwise
                last={step === 'PAID_OWNER_FULL'}
              />
              {step === 'PAID_OWNER_HALF' && (
                // Show additional state if the item has been picked
                <BookingStatus
                  text={'Belongings stored with care'}
                  completed={statuses.some((status) => status.step === step)}
                  last={false}
                />
              )}
            </div>
          ))}
        </div>
        <div className="w-full pb-2 items-center justify-center grid grid-cols-2 gap-1">
          <ActionButton
            onClickFunc={(e) => {
              // TODO: See if we want this to take them somewhere else.
              navigate(`/editSpace/${space.id}`)
            }}
            type={0}
            text={'Edit'}
          />

          <ActionButton
            onClickFunc={(e) => {
              window.location = 'mailto:stashcampus@gmail.com'
            }}
            type={3}
            text="Contact"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
