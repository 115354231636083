import { useMutation, useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import SubmitButton from '../components/SubmitButton'
import TextInputField from '../components/TextInputField'
import { CustomerAuthContext } from '../context/customerAuth'
import { customerClient } from '../GraphqlApolloClients'
import { useForm, useWindowSize } from '../util/hooks'
import { FETCH_CUSTOMER } from './BookSpace'
import CodeForm from '../components/CodeForm'
import { SEND_VERIFICATION_EMAIL } from './CreateSpace'
import { EDIT_CUSTOMER } from './Signup'
import stashDormImage from '../images/stash_dorm.png'
import stashLogoWhite from '../images/stash_logo_white.svg'

function ForgotPassword({ initialUserType }) {
  const navigate = useNavigate()

  const [errors, setErrors] = useState({})

  useEffect(() => {
    document.title = 'Forgot password | StashCampus'
  }, [])

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext)
  useWindowSize()
  const [isVerificationCodeBoxOpen, setIsVerificationCodeBoxOpen] = useState(
    false,
  )

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  })

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] = useState(
    false,
  )

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logout()
      setCheckedIsCustomerLoggedIn(true)
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true)
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ])

  const { values, setValues, onSubmit, onChange } = useForm(
    editCustomerCallback,
    {
      email: '',
      password: '',
      verificationKey: '',
      verified: false,
      campusId: '',
      campusLogoUrl: '',
      campusName: '',
      fieldsToEdit: [],
    },
  )

  // const { data, refetch: refetchCampusById } = useQuery(GET_CAMPUS_BY_ID, {
  //   variables: { campusId: values.campusId },
  //   client: customerClient,
  //   onCompleted() {
  //     console.log('Successfully completed getCampusById()!')
  //   },
  //   onError(err) {
  //     console.log('getCampusById() Error!')
  //     console.log(err)
  //   },
  // })

  const [editCustomer, { loading: loadingEditCustomer }] = useMutation(
    EDIT_CUSTOMER,
    {
      refetchQueries: [
        {
          query: FETCH_CUSTOMER,
        },
      ],
      update(proxy, { data: { editCustomer: editedCustomer } }) {
        setErrors({})
        console.log('editCustomer() success (new)!')
        navigate('/dashboard')
      },
      onError(err) {
        console.log('editCustomer() error!')
        console.log(values)
        console.log(err)
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0])
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          })
        }
      },
      variables: { ...values },
      client: customerClient,
    },
  )

  function editCustomerCallback() {
    editCustomer()
  }

  useEffect(() => {
    if (targetCustomer) {
      setValues((prevValues) => ({
        ...prevValues,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        verified: targetCustomer.verified,
      }))
      // refetchCampusById({ campusId: targetCustomer.campusId })
    }
  }, [targetCustomer, setValues])

  // useEffect(() => {
  //   if (data && data.getCampusById) {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       campusName: data.getCampusById.name,
  //       campusLogoUrl: data.getCampusById.logoImageUrl,
  //       dormImageUrl: data.getCampusById.dormImageUrl,
  //     }))
  //   }
  // }, [data, setValues])

  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL, {
    update() {
      console.log('sendVerificationEmail() success!')
      setIsVerificationCodeBoxOpen(!isVerificationCodeBoxOpen)
    },
    onError(err) {
      console.log('sendVerificationEmail() error!')
      console.log(values)
      console.log(err)
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        })
      }
    },
    variables: values,
    client: customerClient,
  })

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar page="login" />

      <CodeForm
        title={'Verification code'}
        name={'verificationKey'}
        isCodeBoxOpen={isVerificationCodeBoxOpen}
        setIsCodeBoxOpen={setIsVerificationCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={0}
        setErrors={setErrors}
      />

      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
      {[1, 2, 3, 4, 5].map((_, index) => (
        <button
          className={`${
            index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
          } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
          key={index}
        >
          <img
            src={profilePic}
            className="border-white border-2 box-border rounded-full "
          />
        </button>
      ))}
      <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
        <p className="text-3xl text-black font-semibold">Someboody Sum</p>
        <p className="text-lg text-gray-600">
          Spacer since 06/22 - Earns US$100/mo
        </p>
      </div>
    </div> */}
      <div className="w-full flex flex-1 flex-col items-center justify-start">
        <div className="flex mb-20 sm:mb-10 sm:flex-row flex-col items-start z-10 w-full max-w-5xl  space-y-6 sm:space-y-0 sm:space-x-10 xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="w-full flex flex-col px-4 sm:px-0">
            <div className="w-full flex flex-col space-y-4">
              <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                Reset your password
              </p>
              <TextInputField
                title="School email"
                name="email"
                values={values}
                setValues={setValues}
                overlayBtnText="Verify"
                overlayDivText={values.verified ? 'Verified' : null}
                errors={errors}
                onClickOverlay={(e) => {
                  e.preventDefault()
                  setErrors({})
                  sendVerificationEmail()
                  setIsVerificationCodeBoxOpen(true)
                }}
                disabled={values.verified}
                overlayBtnLoading={loadingEditCustomer}
                onChange={onChange}
              />
              <TextInputField
                title="New password"
                name="password"
                values={values}
                onChange={onChange}
                type="password"
                setValues={setValues}
                errors={errors}
                disabled={!values.verified}
              />
              <TextInputField
                title="Confirm password"
                name="confirmPassword"
                type="password"
                values={values}
                onChange={onChange}
                setValues={setValues}
                errors={errors}
                disabled={!values.verified}
              />
            </div>
            <SubmitButton
              onClickFunc={onSubmit}
              title={'Submit'}
              isNotLoading={!loadingEditCustomer}
              disabled={
                !values.fieldsToEdit.includes('password') ||
                !values.fieldsToEdit.includes('confirmPassword')
              }
            />
          </div>

          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
              <img
                src={stashDormImage}
                className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                alt={'Stash Room'}
              />
              {values && values.campusName ? (
                <img
                  src={values.dormImageUrl}
                  className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={values.campusName}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
              <img
                src={values.campusLogoUrl || stashLogoWhite}
                className="object-contain w-full shrink-0 h-full"
                alt={values.campusName || 'StashCampus'}
              />
            </div>
            <div className="flex px-4 sm:px-10 my-6 text-gray-500 text-base items-center justify-center space-x-2">
              <p>TIP: Invite a friend to get $10 off your next booking.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
      />
    </div>
  )
}

export default ForgotPassword
