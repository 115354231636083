import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../util/hooks";
import CardInputField from "./CardInputField";
import CodeField from "./CodeField";
import PaymentPlanSelection from "./PaymentPlanSelection";

export default function PaymentInfo({
  values,
  setValues,
  errors,
  bookingId,
  clientSecret,
  onChange,
  isPaymentPending,
  setIsPaymentPending,
  customer,
  payment,
  setErrors,
  refetchPaymentStatus,
  setIsProcessingPayment,
  isCardComplete,
  setIsCardComplete,
}) {
  useWindowSize();

  const stripe = useStripe();
  const elements = useElements();

  const [cardElem, setCardElem] = useState(false);
  const [cardElemIsFocused, setCardElemIsFocused] = useState(false);

  let navigate = useNavigate();

  const handleConfirmBooking = useCallback(async () => {
    console.log("jii");
    if (!stripe || !elements) {
      return;
    }

    /* Lower payment pending flag right before processing to avoid double-processing. */
    setIsPaymentPending(false);

    console.log("in here with values ");
    console.log(values);

    if (values.paymentPlan === "NOW") {
      console.log("in the case");

      if (!isCardComplete) {
        setErrors({ paymentPlan: " - Required" });
      }
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElem,
            billing_details: {
              name: `${customer.firstName} ${customer.lastName}`,
              email: `${customer.email}`,
              address: {
                country: "CA",
              },
            },
          },
        })
        .then((result) => {
          console.log("refetching");
          if (result.error) {
            setErrors({ card: " - Something went wrong" });
          } else {
            /* Check if the payment was successful. If so, go to dashboard. Otherwise, stay on this page. */
            refetchPaymentStatus({ bookingId: values.bookingId });
          }
        });
    } else {
      const result = await stripe.confirmKlarnaPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: `${customer.firstName} ${customer.lastName}`,
            email: `${customer.email}`,
            address: {
              country: "CA",
            },
          },
        },
        /* Check if the payment was successful (using useEffect() on main page. If so, go to dashboard. Otherwise, stay on this page. */
        return_url: `${
          process.env.NODE_ENV === "production"
            ? "https://stashcampus.com"
            : "http://localhost:3000"
        }/bookSpace/${bookingId || "-"}/payment-info`,
      });
      if (result.error) {
        console.log("got this klarna error");
        console.log(result.error);
        setErrors({ paymentPlan: " - Something went wrong" });
      }
      setIsProcessingPayment(false);
    }
  }, [
    clientSecret,
    values,
    stripe,
    customer,
    isCardComplete,
    bookingId,
    setIsProcessingPayment,
    elements,
    cardElem,
    refetchPaymentStatus,
    setIsPaymentPending,
    setErrors,
  ]);

  /* If they click submit and have a client secret, confirm the booking. */
  useEffect(() => {
    if (clientSecret && isPaymentPending) {
      handleConfirmBooking();
    }
  }, [clientSecret, isPaymentPending, handleConfirmBooking]);

  // console.log("isCardComplete ", isCardComplete);
  // console.log("cardElemIsFocused ", cardElemIsFocused)

  return (
    <div className="w-full flex flex-col pb-4 sm:pb-10 space-y-4">
      <div className="relative">
        <button
          onClick={(e) => {
            navigate(`/bookSpace/${bookingId || "-"}/student-info`);
          }}
          className=" group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
        >
          <svg
            className="h-3 rotate-180 fill-current"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className=" -translate-x-1 group-hover:translate-x-0 transition-all"
              d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
            />
            <rect
              className="opacity-0 group-hover:opacity-100 transition-all"
              y="4"
              width="9"
              height="2"
            />
          </svg>
        </button>
        <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
          Payment info
        </p>
      </div>

      <CodeField
        title="Invite code (optional)"
        name="referralCode"
        values={values}
        setValues={setValues}
        errors={errors}
        onChange={onChange}
        setErrors={setErrors}
        typeOfCode={1}
        disabled={
          payment && (payment.referreeDiscount || payment.referralDiscount)
        }
      />

      <PaymentPlanSelection
        values={values}
        setValues={setValues}
        onChange={onChange}
        errors={errors}
      />

      {values.paymentPlan === "NOW" && (
        <div className="w-full">
          <div className="flex items-center font-semibold justify-between text-sm sm:text-base">
            <label htmlFor="paymentInfo" className={"text-black"}>
              Card details{" "}
              {errors["card"] && (
                <span className="text-red-600">{errors["card"]}</span>
              )}
            </label>
            {1 ? <></> : <button className="text-purple-500">Edit</button>}
          </div>
          <CardInputField
            cardElemIsFocused={cardElemIsFocused}
            setCardElemIsFocused={setCardElemIsFocused}
            // error={errors && errors.paymentError}
            errors={errors}
            setErrors={setErrors}
            setIsCardComplete={setIsCardComplete}
            disabled={false}
            title="Payment info"
            cardElem={cardElem}
            setCardElem={setCardElem}
            elements={elements}
          />
        </div>
      )}
    </div>
  );
}
