import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

export default function ProfilePicInputField({
  title,
  value,
  name,
  onChangeMedia,
  editFunc,
  error,
  shouldNotEdit,
  values,
}) {
  const [previewImageURL, setPreviewImageURL] = useState('')

  const onDrop = async (files) => {
    if (files && files.length > 0) {
      var fileType = files[0].type

      onChangeMedia(name, files[0], fileType)

      const imageTempUrl = URL.createObjectURL(files[0])
      // console.log(files[0]);
      setPreviewImageURL(imageTempUrl)

      if (shouldNotEdit) {
        // console.log(imageTempUrl);
      } else {
        // console.log("iji");
        // console.log(files[0]);
        editFunc({
          variables: { ...values, fieldsToEdit: [name], [name]: files[0] },
        })
      }
    }
  }

  const {
    getRootProps,
    getInputProps,
    isdragactive,
    isdragaccept,
    isdragreject,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png,image/jpg',
    maxSize: 10485760, //10 MB
    multiple: false,
  })

  // useEffect(() => {
  //   if (value) {
  //     setPreviewImageURL(value)
  //   }
  // }, [value])

  return (
    <div className="flex items-start  shrink-0 flex-col justify-start">
      <div className="flex mb-1 items-center justify-between font-semibold">
        {title} {error && <span className="text-red-600">{error}</span>}
      </div>
      <button
        {...getRootProps({
          isdragactive,
          isdragaccept,
          isdragreject,
        })}
        type="button"
        className={`${
          isdragactive ? 'bg-purple-800 animate-pulse' : ''
        } bg-white group relative flex-shrink-0 shadow-sm hover:bg-purple-800 transition-all overflow-hidden rounded-full aspect-square h-12`}
      >
        <input {...getInputProps()} />

        <div className="inset-0  m-auto items-center flex justify-center absolute group-hover:opacity-100 transition-opacity opacity-0 ">
          <svg
            className="h-1/2 m-auto fill-current text-gray-100"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        </div>

        {previewImageURL || value ? (
          <img
            src={previewImageURL || value}
            className="border-white bg-white w-full aspect-square object-cover border-2 box-border rounded-full "
            alt={title}
          />
        ) : (
          <svg
            className="h-1/2 m-auto fill-current text-purple-800"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
            />
          </svg>
        )}
      </button>
    </div>
  )
}
