import { gql, useMutation, useQuery } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import AddressField from "../components/AddressField";
import { GET_LAST_STATUS_BY_BOOKING } from "../components/BookingBox";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
import CodeForm from "../components/CodeForm";
import DateTimeField from "../components/DateTimeField";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import PaymentDetails from "../components/PaymentDetails";
import PaymentInfo from "../components/PaymentInfo";
import SpaceSelection from "../components/SpaceSelection";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import stashClosetImage from "../images/stash_closet.png";
import stashCornerImage from "../images/stash_corner.png";
import stashDormImage from "../images/stash_dorm.png";
import stashLogoWhite from "../images/stash_logo_white.svg";
import { useForm, useWindowSize } from "../util/hooks";
import usePageTracking from "../util/usePageTracking";
import { GET_BOOKINGS_BY_STEPS } from "./Dashboard";
import { CREATE_CUSTOMER, EDIT_CUSTOMER } from "./Signup";

export default function BookSpace(props) {
  // const customerContext = useContext(CustomerAuthContext)

  // Don't worry, this is the Stripe publishable key (which is safe to commit).
  const stripePromise = loadStripe(
    process.env.NODE_ENV === "production"
      ? "pk_live_51NyjIvGbzUWF8sy46xXpxmbNLgHQWrk9fvkZSLuvScMe0FhHb7r2Pr8TZbEFsUlucYy44AMRTDcngzRXEe9eTNuJ00mQrwtjME"
      : "pk_test_51NyjIvGbzUWF8sy49vBIfWf6ADDCCr78eBKOVPgjJP2mpmYP03wN5RcwVx1vPWbwsMV1aHbJtazPVT2vePWokTeS00mSTzPcXT"
  );

  // TODO: Add tip field.
  // TODO: Make sure they can click "back" on each page.
  // TODO: Make page load cleanly.

  const { bookingId, bookingStep } = useParams();
  const [clientSecret, setClientSecret] = useState();
  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false); // no-unused-vars

  let navigate = useNavigate();

  usePageTracking();

  const resourcesRef = useRef();
  const productsRef = useRef();
  const pricingRef = useRef();
  const pageTopRef = useRef();

  useEffect(() => {
    document.title = "Book space | StashCampus";
  }, []);

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const { guest, logoutGuest, loginGuest } = useContext(GuestAuthContext);

  useWindowSize();

  const [isVerificationCodeBoxOpen, setIsVerificationCodeBoxOpen] =
    useState(false);

  const stripeOptions = {
    clientSecret,
  };

  /* If another booking has been started, redirect them to the book space of that page. If not, let them continue booking here. */
  const {
    data: { getBookingsBySteps: createdBookings } = {},
    loading: loadingGetBookingBySteps,
  } = useQuery(GET_BOOKINGS_BY_STEPS, {
    client: customer ? customerClient : guestClient,
    variables: {
      steps: ["CREATED"],
    },
    onError(err) {
      console.log("getBookingsBySteps() (CREATED) error!");
      console.log(err);
    },
  });

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    // refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const { data: { getGuest: targetGuest } = {}, loading: loadingGuestInfo } =
    useQuery(FETCH_GUEST, { client: guestClient });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  const [checkedIsGuestLoggedIn, setCheckedIsGuestLoggedIn] = useState(false);

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  useEffect(() => {
    if (guest && !loadingGuestInfo && !targetGuest && !checkedIsGuestLoggedIn) {
      logoutGuest();
      setCheckedIsGuestLoggedIn(true);
    } else if (!guest) {
      setCheckedIsGuestLoggedIn(true);
    }
  }, [
    guest,
    loadingGuestInfo,
    targetGuest,
    logoutGuest,
    checkedIsGuestLoggedIn,
  ]);

  const { values, setValues, onChange, onChangeDate } = useForm(
    createBookingCallback,
    {
      bookingId: bookingId || "",
      email: "",
      verified: false,
      pickupFullStreetAddress: "",
      pickupProvince: "",
      pickupCity: "",
      pickupCountry: "",
      pickupPostalCode: "",
      pickupLine2: "",
      pickupSpecialInstructions: "",
      dropoffFullStreetAddress: "",
      dropoffProvince: "",
      dropoffCity: "",
      dropoffCountry: "",
      dropoffPostalCode: "",
      dropoffLine2: "",
      dropoffSpecialInstructions: "",
      startDate: moment().add(2, "days").toDate(),
      startTime: "MORNING",
      endDate: moment().add(4, "months").add(2, "days").toDate(),
      endTime: "MORNING",
      size: "CLOSET",
      campusId: "",
      imageUrl:
        "https://static.wikia.nocookie.net/shipping/images/f/ff/Tadashi_and_Hiro.jpg/revision/latest?cb=20190430141645",
      paymentPlan: "NOW",
      campusLogoUrl: "",
      dormImageUrl: "",
      closetImageUrl: "",
      cornerImageUrl: "",
      campusName: "",
      promoCode: "",
      verificationKey: "",
      referralCode: "",
      ip: "",
      country: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      payPalEmail: "",
      subtotal: 0,
      tax: 0,
      referrerDiscount: 0,
      referreeDiscount: 0,
      tip: 0,
      total: 0,
      ownerTotal: 0,
    }
  );

  const {
    data: { getLastStatusByBooking: lastStatus } = {},
    loading: loadingLastStatus,
  } = useQuery(GET_LAST_STATUS_BY_BOOKING, {
    variables: { bookingId: values.bookingId },
    client: customer ? customerClient : guestClient,
  });

  const [errors, setErrors] = useState({});

  const [createBooking, { loading: loadingCreateBooking }] = useMutation(
    CREATE_BOOKING,
    {
      refetchQueries: [
        {
          query: GET_LAST_STATUS_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST },
        {
          query: GET_BOOKING_BY_ID,
          variables: { bookingId: values.bookingId },
        },
        {
          query: GET_PAYMENT_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
      ],
      update(proxy, { data: { createBooking: booking } }) {
        console.log("createBooking() completed successfully");
        // console.log(booking);

        setValues({ ...values, bookingId: booking.id });

        if (booking.guestToken) {
          console.log("got a guest token");
          loginGuest({ token: booking.guestToken });
        }

        // console.log(targetCustomer);

        if (
          targetCustomer &&
          targetCustomer.hasSetPassword &&
          targetCustomer.firstName &&
          targetCustomer.lastName &&
          targetCustomer.phoneNumber
        ) {
          /* Need valid customer for payment intent. Create payment intent right before going to payment-info (1). */
          console.log("have a ready customer");
          createPaymentIntent();
          // navigate(`/bookSpace/${booking.id}/payment-info`);
        } else {
          console.log("dont have a ready customer");
          navigate(`/bookSpace/${booking.id}/student-info`);
        }
        setErrors({});
      },
      onError(err) {
        console.log("createBooking() Error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log("have some proper errors");
          console.log(err.graphQLErrors[0]);
          // if (err.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
          //   navigate('/login')
          // }
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customer ? customerClient : guestClient,
    }
  );

  function createBookingCallback() {
    createBooking();
  }

  const {
    data: { getPaymentByBooking: payment } = {},
    loading: loadingPayment,
    // refetch: refetchGetPaymentByBooking,
  } = useQuery(GET_PAYMENT_BY_BOOKING, {
    client: customerClient,
    variables: { bookingId: values.bookingId },
    onError(err) {
      console.log("getPaymentByBooking() error!");
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
  });

  const {
    data: { getPaymentStatusByBooking: paymentStatus } = {},
    loading: loadingGetPaymentStatusByBooking,
    refetch: refetchPaymentStatus,
  } = useQuery(GET_PAYMENT_STATUS_BY_BOOKING, {
    client: customer ? customerClient : guestClient,
    variables: { bookingId: values.bookingId },
    onError(err) {
      console.log("getPaymentStatusByBooking() error!");
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
  });

  const [
    processSuccessfulPayment,
    { loading: loadingProcessSuccessfulPayment },
  ] = useMutation(PROCESS_SUCCESSFUL_PAYMENT, {
    refetchQueries: [
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: [
            "CREATED",
            "BOOKED",
            "PAID_OWNER_HALF",
            "CONFIRMED",
            "PICKED",
          ],
        },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["CREATED"],
        },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"],
        },
      },
    ],
    update(proxy, { data: { processSuccessfulPayment: paymentStatus } }) {
      console.log("processSuccessfulPayment() success!");
      setIsProcessingPayment(false);
      navigate(`/dashboard`);
    },
    onError(err) {
      console.log("processSuccessfulPayment() error!");
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (paymentStatus === "SUCCESS") {
      setErrors({});
      processSuccessfulPayment();
    } else if (paymentStatus === "FAIL") {
      /* Two concerns: 1) invalid card number. 2) invalid payment.
       * 1) Invalid card number is handled within the card element itself. We also don't set isPaymentPending to true if there's an error here.
       * 2) Invalid payment will be handled here - if we see the payment FAILed (different from PENDING or UNINITIATED), then we put an error.
       */
      console.log("Payment was not successful");
      setErrors((prevErrors) => ({
        ...prevErrors,
        paymentPlan: "- Payment failed",
      }));
    }
    /* If payment status is PENDING or UNINITIATED, we don't care. */
  }, [paymentStatus, processSuccessfulPayment, setErrors, values.paymentPlan]);

  const [createPaymentIntent, { loading: loadingClientSecret }] = useMutation(
    CREATE_PAYMENT_INTENT,
    {
      refetchQueries: [
        {
          query: GET_LAST_STATUS_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST },
        {
          query: GET_BOOKING_BY_ID,
          variables: { bookingId: values.bookingId },
        },
        {
          query: GET_PAYMENT_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
      ],
      update(proxy, { data: { createPaymentIntent: newClientSecret } }) {
        console.log("createPaymentIntent() success!");
        setClientSecret(newClientSecret);
        if (bookingStep !== "payment-info") {
          navigate(`/bookSpace/${values.bookingId}/payment-info`);
        } else {
          setIsPaymentPending(true);
        }
      },
      onError(err) {
        console.log("createPaymentIntent() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customer ? customerClient : guestClient,
    }
  );

  const [editCustomer, { loading: loadingEditCustomer }] = useMutation(
    EDIT_CUSTOMER,
    {
      refetchQueries: [
        {
          query: FETCH_CUSTOMER,
        },
        { query: FETCH_GUEST },
        {
          query: GET_BOOKING_BY_ID,
          variables: { bookingId: values.bookingId },
        },
      ],
      update(proxy, { data: { editCustomer: editedCustomer } }) {
        console.log("editCustomer() success (new)!");
        /* Need valid customer for payment intent. Create payment intent right before going to payment-info (2). */
        createPaymentIntent();
        setErrors({});
        // navigate(`/bookSpace/${values.bookingId}/payment-info`);
      },
      onError(err) {
        console.log("editCustomer() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      // variables: {
      //   ...values,
      //   fieldsToEdit: ["firstName", "lastName", "phoneNumber"],
      // },
      client: customer ? customerClient : guestClient,
    }
  );

  const [editBooking, { loading: loadingEditBooking }] = useMutation(
    EDIT_BOOKING,
    {
      refetchQueries: [
        {
          query: GET_LAST_STATUS_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST },
        {
          query: GET_BOOKING_BY_ID,
          variables: { bookingId: values.bookingId },
        },
        {
          query: GET_PAYMENT_BY_BOOKING,
          variables: { bookingId: values.bookingId },
        },
      ],
      update(proxy, { data: { editBooking: editedBooking } }) {
        console.log("editBooking() success!");
        // When they click submit, if the card/payment plan is not valid, nothing should happen.

        if (
          !errors.card &&
          !errors.paymentPlan &&
          bookingStep === "payment-info"
        ) {
          createPaymentIntent();

          /* Process card or Klarna payment. */
          // console.log("trying to pay...");
          // setIsPaymentPending(true);
        } else {
          setIsPaymentPending(false);
          setIsProcessingPayment(false);
        }
      },
      onError(err) {
        console.log("editBooking() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
        setIsPaymentPending(false);
      },
      variables: { ...values, fieldsToEdit: ["paymentPlan"] },
      client: customer ? customerClient : guestClient,
    }
  );

  const {
    data: { getBookingById: targetBooking } = {},
    refetch: refetchGetBookingById,
    loading: loadingGetBookingById,
  } = useQuery(GET_BOOKING_BY_ID, {
    variables: { bookingId: values.bookingId },
    client: customer ? customerClient : guestClient,
    onCompleted() {
      console.log("getBookingById() completed successfully!");
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
  });

  useEffect(() => {
    if (
      bookingId &&
      values.bookingId &&
      !loadingGetBookingById &&
      !targetBooking
    ) {
      navigate("/bookSpace");
    }
  }, [values, bookingId, targetBooking, loadingGetBookingById, navigate]);
  const [isBookingInfoInitialized, setIsBookingInfoInitialized] =
    useState(false);
  /* Redirect to previously created booking. */
  useEffect(() => {
    // console.log("have these createdBOokings");
    // console.log(createdBookings);
    if (
      createdBookings &&
      createdBookings.length > 0 &&
      createdBookings[0].id !== bookingId
    ) {
      setValues({ ...values, bookingId: createdBookings[0].id });
      refetchGetBookingById({ bookingId: createdBookings[0].id });
      navigate(`/bookSpace/${createdBookings[0].id}`);
      setIsBookingInfoInitialized(false);
    }
  }, [
    createdBookings,
    navigate,
    refetchGetBookingById,
    bookingId,
    values,
    setValues,
  ]);
  useEffect(() => {
    if (
      payment &&
      values.subtotal !== payment.subtotal &&
      bookingStep === "payment-info" &&
      isBookingInfoInitialized
    ) {
      console.log("GOT A NEW PAYMENT");

      setValues({
        ...values,
        subtotal: payment.subtotal,
        tax: payment.tax,
        referrerDiscount: payment.referrerDiscount,
        tip: payment.tip,
        total: payment.total,
      });
    }
  }, [payment, setValues, values, isBookingInfoInitialized, bookingStep]);

  const {
    data: { getCampusById: targetCampus } = {},
    // loading: loadingGetCampusById,
  } = useQuery(GET_CAMPUS_BY_ID, {
    variables: { campusId: values.campusId },
    client: customer ? customerClient : guestClient,
  });
  // console.log(values.campusId);
  useEffect(() => {
    if (targetCustomer && values.email !== targetCustomer.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        verified: targetCustomer.verified,
      });
    }
  }, [
    targetCustomer,
    setValues,
    bookingId,
    targetBooking,
    values,
    isBookingInfoInitialized,
  ]);

  useEffect(() => {
    if (!isBookingInfoInitialized && targetBooking) {
      setValues({ ...values, ...targetBooking, bookingId: targetBooking.id });
      setIsBookingInfoInitialized(true);
    }
    if (lastStatus && lastStatus.step !== "CREATED") {
      /* Redirect to dashboard if booking is past CREATED. */
      navigate("/dashboard");
    }
  }, [
    values,
    isBookingInfoInitialized,
    setValues,
    targetBooking,
    lastStatus,
    navigate,
  ]);

  useEffect(() => {
    if (targetCampus && !values.campusName) {
      setValues({
        ...values,
        campusName: targetCampus.name,
        campusLogoUrl: targetCampus.logoUrl,
        dormImageUrl: targetCampus.dormImageUrl,
        closetImageUrl: targetCampus.closetImageUrl,
        cornerImageUrl: targetCampus.cornerImageUrl,
      });
    }
  }, [
    targetCampus,
    values,
    setValues,
    loadingGetBookingById,
    loadingGetBookingBySteps,
    bookingId,
    isBookingInfoInitialized,
    targetBooking,
  ]);

  const [createCustomer, { loading: loadingCreateCustomer }] = useMutation(
    CREATE_CUSTOMER,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER }, { query: FETCH_GUEST }],
      update(proxy, { data: { createCustomer: createdCustomer } }) {
        setIsVerificationCodeBoxOpen(true);
        // Need to refetch after creating.
        // refetchCustomer();
        console.log("createCustomer() success!");
      },
      onError(err) {
        console.log("createCustomer() error!");
        console.log(values);
        if (err.graphQLErrors[0]) {
          console.log("jii");
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customer ? customerClient : guestClient,
    }
  );
  useEffect(() => {
    async function getData() {
      const res = await axios.get("https://geolocation-db.com/json/");

      if (
        res &&
        res.data &&
        res.data.IPv4 &&
        res.data.IPv4.split(".").length > 2
      ) {
        setValues({
          ...values,
          country: !values.country ? res.data.country_name : "",
          ip: res.data.IPv4,
        });
      } else {
        setValues({
          ...values,

          ip: "12321." + Math.floor(1000 + Math.random() * 9000),
        });
      }
    }
    //passing getData method to the lifecycle method
    if (
      (!values.ip || values.ip === "") &&
      !customer &&
      (!bookingId || (targetBooking && values.dropoffCity))
    ) {
      getData();
    }
  }, [setValues, values, customer, bookingId, targetBooking]);

  return (
    <div
      ref={pageTopRef}
      className="w-screen relative bg-light-beige overflow-x-hidden h-full flex flex-col min-h-screen"
    >
      {/* {isPaymentPending && <Loading />} */}
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}
      <NavBar
        page="bookSpace"
        productsRef={productsRef}
        resourcesRef={resourcesRef}
        pricingRef={pricingRef}
        pageTopRef={pageTopRef}
        shouldHideLogin={
          errors && errors.email && errors.email === "- Please sign in first"
            ? false
            : true
        }
      />

      <CodeForm
        title={"Verification code"}
        name={"verificationKey"}
        isCodeBoxOpen={isVerificationCodeBoxOpen}
        setIsCodeBoxOpen={setIsVerificationCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={0}
        setErrors={setErrors}
      />

      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
        {[1, 2, 3, 4, 5].map((_, index) => (
          <button
            className={`${
              index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
            } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
            key={index}
          >
            <img
              src={profilePic}
              className="border-white border-2 box-border rounded-full "
            />
          </button>
        ))}
        <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
          <p className="text-3xl text-black font-semibold">Someboody Sum</p>
          <p className="text-lg text-gray-600">
            Spacer since 06/22 - Earns US$100/mo
          </p>
        </div>
      </div> */}
      <div className="w-full flex-1 flex flex-col items-center justify-start">
        <div className="flex mb-20 sm:mb-10 sm:flex-row flex-col items-start z-10 w-full max-w-5xl  space-y-6 sm:space-y-0 sm:space-x-10 xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="flex w-full flex-col space-y-6 sm:space-y-0 sm:space-x-10 sm:flex-row items-start justify-start px-4 sm:px-0">
            <div className="w-full flex flex-col">
              {bookingStep === "booking-details" || !bookingStep ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate("/");
                      }}
                      className=" group absolute hidden sm:right-full sm:mr-8 sm:flex items-center justify-center rounded-full aspect-square hover:bg-white/20 p-4  transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Booking details
                    </p>
                  </div>
                  <CampusSearchDropdown
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <SpaceSelection
                    values={values}
                    setValues={setValues}
                    onChange={onChange}
                  />
                  <AddressField
                    values={values}
                    setValues={setValues}
                    setErrors={setErrors}
                    errors={errors}
                    onChange={onChange}
                    typeOfAddress={0}
                  />
                  <DateTimeField
                    values={values}
                    setValues={setValues}
                    onChange={onChangeDate}
                    typeOfDate={0}
                    errors={errors}
                  />
                  <AddressField
                    values={values}
                    setErrors={setErrors}
                    errors={errors}
                    setValues={setValues}
                    onChange={onChange}
                    typeOfAddress={1}
                  />
                  <DateTimeField
                    values={values}
                    setValues={setValues}
                    onChange={onChangeDate}
                    typeOfDate={1}
                  />
                </div>
              ) : bookingStep === "student-info" ? (
                <div className="w-full flex flex-col pb-4 sm:pb-10 space-y-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        navigate(
                          `/bookSpace/${bookingId || "-"}/booking-details`
                        );
                      }}
                      className=" group absolute right-full px-3 py-4 sm:p-4 sm:mr-8 flex items-center justify-center rounded-full aspect-square hover:bg-white/20   transition-all"
                    >
                      <svg
                        className="h-3 rotate-180 fill-current"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                          d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                        />
                        <rect
                          className="opacity-0 group-hover:opacity-100 transition-all"
                          y="4"
                          width="9"
                          height="2"
                        />
                      </svg>
                    </button>
                    <p className="text-black font-bold text-3xl sm:text-4xl leading-heading">
                      Student info
                    </p>
                  </div>

                  <TextInputField
                    title="School email"
                    name="email"
                    values={values}
                    setValues={setValues}
                    overlayBtnText="Verify"
                    overlayDivText={values.verified ? "Verified" : null}
                    errors={errors}
                    onClickOverlay={(e) => {
                      e.preventDefault();
                      setErrors({});
                      createCustomer();
                    }}
                    disabled={values.verified}
                    overlayBtnLoading={loadingCreateCustomer}
                    onChange={onChange}
                  />
                  <div
                    className={`transition-all grid grid-cols-2 gap-2 sm:gap-3`}
                  >
                    <TextInputField
                      title="First name"
                      name="firstName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      onChange={onChange}
                      disabled={values.verified ? false : true}
                    />
                    <TextInputField
                      title="Last name"
                      name="lastName"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      onChange={onChange}
                      disabled={!values.verified}
                    />
                  </div>
                  <div className={`w-full flex flex-col space-y-4`}>
                    <TextInputField
                      title="Phone number"
                      name="phoneNumber"
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      onChange={onChange}
                      disabled={!values.verified}
                    />

                    {!values.hasSetPassword && (
                      <>
                        <TextInputField
                          title="Set a password"
                          name="password"
                          values={values}
                          onChange={onChange}
                          type="password"
                          setValues={setValues}
                          errors={errors}
                          disabled={!values.verified}
                        />
                        <TextInputField
                          title="Confirm password"
                          name="confirmPassword"
                          type="password"
                          values={values}
                          onChange={onChange}
                          setValues={setValues}
                          errors={errors}
                          disabled={!values.verified}
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <PaymentInfo
                    refetchPaymentStatus={refetchPaymentStatus}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    bookingId={bookingId}
                    payment={payment}
                    setErrors={setErrors}
                    clientSecret={clientSecret}
                    onChange={onChange}
                    isPaymentPending={isPaymentPending}
                    setIsPaymentPending={setIsPaymentPending}
                    customer={targetCustomer}
                    isCardComplete={isCardComplete}
                    setIsCardComplete={setIsCardComplete}
                    setIsProcessingPayment={setIsProcessingPayment}
                  />
                </Elements>
              )}
            </div>
          </div>
          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
              <img
                src={
                  values.size === "CORNER"
                    ? stashCornerImage
                    : values.size === "CLOSET"
                    ? stashClosetImage
                    : stashDormImage
                }
                className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                alt={"Stash Room"}
              />
              {values && values.campusName ? (
                <img
                  src={
                    values.size === "CORNER"
                      ? values.cornerImageUrl
                      : values.size === "CLOSET"
                      ? values.closetImageUrl
                      : values.dormImageUrl
                  }
                  className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={values.campusName}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
              <img
                src={values.campusLogoUrl || stashLogoWhite}
                className="object-contain w-full shrink-0 h-full"
                alt={values.campusName || "StashCampus"}
              />
            </div>
            {/* {payment ? ( */}
            <PaymentDetails
              payment={payment}
              values={values}
              targetCustomer={targetCustomer}
              bookingStep={bookingStep ? bookingStep : "booking-details"}
            />
            {/* // ) : ( // <div className="h-1"></div>
            // )} */}
            {bookingStep === "student-info" && !values.verified ? (
              <></>
            ) : (
              <SubmitButton
                onClickFunc={(e) => {
                  // console.log("have these values");
                  // console.log(values);
                  e.preventDefault();
                  if (!bookingStep || bookingStep === "booking-details") {
                    createBooking();
                  } else if (bookingStep === "student-info") {
                    editCustomer({
                      variables: {
                        ...values,
                        fieldsToEdit: ["firstName", "lastName", "phoneNumber"],
                      },
                    });
                  } else if (bookingStep === "payment-info") {
                    if (
                      !isCardComplete &&
                      values.paymentPlan === "NOW" &&
                      !errors.card
                    ) {
                      setErrors({ card: " - Required" });
                      setIsProcessingPayment(false);
                    } else {
                      /* payment-info. */
                      setIsProcessingPayment(
                        true
                      ); /* Just to trigger loading state (set to false afterwards). */
                      // console.log("In payment-info");
                      const { paymentPlan, ...remainingErrors } = errors;
                      setErrors(remainingErrors);
                      editBooking();
                    }
                  }
                }}
                title={
                  bookingStep === "payment-info"
                    ? "Confirm booking"
                    : "Continue"
                }
                disabled={
                  loadingCreateBooking ||
                  loadingEditCustomer ||
                  isProcessingPayment ||
                  isPaymentPending
                }
                isNotLoading={
                  !(
                    loadingCreateBooking ||
                    loadingProcessSuccessfulPayment ||
                    loadingGetBookingBySteps ||
                    loadingCustomerInfo ||
                    loadingGuestInfo ||
                    loadingLastStatus ||
                    loadingPayment ||
                    loadingGetPaymentStatusByBooking ||
                    loadingProcessSuccessfulPayment ||
                    loadingClientSecret ||
                    loadingEditCustomer ||
                    isProcessingPayment ||
                    loadingEditBooking ||
                    loadingGetBookingById ||
                    loadingCreateCustomer
                  )
                }
                errors={errors}
                isFixedButton={true}
              />
            )}
            <p className="text-sm absolute w-full text-purple-950 text-center mt-4 mb-2">
              {bookingStep === "payment-info"
                ? "Applied 30% Early Goose Discount"
                : "Includes 30% Early Goose Discount"}
            </p>
          </div>
        </div>
      </div>

      <Footer
        navigate={navigate}
        customer={customer}
        logout={logout}
        keepDefaultColor={true}
      />
    </div>
  );
}

const CREATE_BOOKING = gql`
  mutation createBooking(
    $bookingId: String
    $pickupFullStreetAddress: String!
    $pickupProvince: String!
    $pickupCity: String!
    $pickupCountry: String!
    $pickupPostalCode: String!
    $dropoffFullStreetAddress: String!
    $dropoffProvince: String!
    $dropoffCity: String!
    $dropoffCountry: String!
    $dropoffPostalCode: String!
    $startDate: DateTime!
    $startTime: Slot!
    $endDate: DateTime!
    $endTime: Slot!
    $size: Size!
    $campusId: String!
    $customerId: String
    $guestId: String
    $ip: String
    $country: String
    $pickupLine2: String
    $pickupSpecialInstructions: String
    $dropoffLine2: String
    $dropoffSpecialInstructions: String
    $paymentPlan: String!
  ) {
    createBooking(
      bookingId: $bookingId
      pickupFullStreetAddress: $pickupFullStreetAddress
      pickupProvince: $pickupProvince
      pickupCity: $pickupCity
      pickupCountry: $pickupCountry
      pickupPostalCode: $pickupPostalCode
      dropoffFullStreetAddress: $dropoffFullStreetAddress
      dropoffProvince: $dropoffProvince
      dropoffCity: $dropoffCity
      dropoffCountry: $dropoffCountry
      dropoffPostalCode: $dropoffPostalCode
      startDate: $startDate
      startTime: $startTime
      endDate: $endDate
      endTime: $endTime
      size: $size
      campusId: $campusId
      customerId: $customerId
      guestId: $guestId
      ip: $ip
      country: $country
      pickupLine2: $pickupLine2
      pickupSpecialInstructions: $pickupSpecialInstructions
      dropoffLine2: $dropoffLine2
      dropoffSpecialInstructions: $dropoffSpecialInstructions
      paymentPlan: $paymentPlan
    ) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      guestToken
      lastUpdatedAt
      createdAt
    }
  }
`;

export const GET_BOOKING_BY_ID = gql`
  query getBookingById($bookingId: String!) {
    getBookingById(bookingId: $bookingId) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      lastUpdatedAt
      createdAt
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query getCustomer {
    getCustomer {
      id
      email
      ip
      country
      referralId
      firstName
      lastName
      hasSetPassword
      phoneNumber
      verified
      token
      campusId
      pendingReferrals
      ogGuestId
      payPalEmail
      stripeCustomerId
      profilePicUrl
      shouldReceivePromoEmails
      createdAt
    }
  }
`;

const FETCH_GUEST = gql`
  query getGuest {
    getGuest {
      id
      ip
      country
      lastActiveAt
      createdAt
    }
  }
`;

export const GET_CAMPUS_BY_ID = gql`
  query getCampusById($campusId: String!) {
    getCampusById(campusId: $campusId) {
      id
      address
      name
      shortForm
      numOfUniqueStudents
      totalRevenue
      logoImageUrl
      campusImageUrls
      dormImageUrl
      closetImageUrl
      cornerImageUrl
      spaceImageUrls
      textImageUrl
      createdAt
      lastUpdatedAt
    }
  }
`;

const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($bookingId: String!) {
    createPaymentIntent(bookingId: $bookingId)
  }
`;

const EDIT_BOOKING = gql`
  mutation editBooking(
    $fieldsToEdit: [String]!
    $bookingId: String
    $pickupFullStreetAddress: String
    $pickupCity: String
    $pickupProvince: String
    $pickupCountry: String
    $pickupLine2: String
    $pickupPostalCode: String
    $dropoffProvince: String
    $dropoffCity: String
    $pickupSpecialInstructions: String
    $dropoffFullStreetAddress: String
    $dropoffLine2: String
    $dropoffCountry: String
    $dropoffPostalCode: String
    $dropoffSpecialInstructions: String
    $startTime: Slot
    $startDate: DateTime
    $endDate: DateTime
    $endTime: Slot
    $size: Size
    $paymentPlan: String
    $campusId: String
  ) {
    editBooking(
      fieldsToEdit: $fieldsToEdit
      bookingId: $bookingId
      pickupFullStreetAddress: $pickupFullStreetAddress
      pickupCity: $pickupCity
      pickupProvince: $pickupProvince
      pickupCountry: $pickupCountry
      pickupLine2: $pickupLine2
      pickupPostalCode: $pickupPostalCode
      dropoffProvince: $dropoffProvince
      dropoffCity: $dropoffCity
      pickupSpecialInstructions: $pickupSpecialInstructions
      dropoffFullStreetAddress: $dropoffFullStreetAddress
      dropoffLine2: $dropoffLine2
      dropoffCountry: $dropoffCountry
      dropoffPostalCode: $dropoffPostalCode
      dropoffSpecialInstructions: $dropoffSpecialInstructions
      startTime: $startTime
      startDate: $startDate
      endDate: $endDate
      endTime: $endTime
      size: $size
      paymentPlan: $paymentPlan
      campusId: $campusId
    ) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      lastUpdatedAt
      createdAt
    }
  }
`;

const PROCESS_SUCCESSFUL_PAYMENT = gql`
  mutation processSuccessfulPayment($bookingId: String!) {
    processSuccessfulPayment(bookingId: $bookingId) {
      id
      bookingId
      step
      description
      createdAt
    }
  }
`;

const GET_PAYMENT_STATUS_BY_BOOKING = gql`
  query getPaymentStatusByBooking($bookingId: String!) {
    getPaymentStatusByBooking(bookingId: $bookingId)
  }
`;

export const GET_PAYMENT_BY_BOOKING = gql`
  query getPaymentByBooking($bookingId: String!) {
    getPaymentByBooking(bookingId: $bookingId) {
      id
      bookingId
      subtotal
      tax
      referrerDiscount
      referreeDiscount
      tip
      total
      createdAt
      ownerTotal
    }
  }
`;
