import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  guest: null,
};

if (localStorage.getItem("guestJwtToken")) {
  const decodedToken = jwtDecode(localStorage.getItem("guestJwtToken"));

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("guestJwtToken");
  } else {
    initialState.guest = decodedToken;
  }
}

const GuestAuthContext = createContext({
  guest: null,
  loginGuest: (guestData) => {},
  logoutGuest: () => {},
});

function guestAuthReducer(state, action) {
  switch (action.type) {
    case "LOGIN_GUEST":
      return {
        ...state,
        guest: action.payload,
      };
    case "LOGOUT_GUEST":
      return {
        ...state,
        guest: null,
      };
    default:
      return state;
  }
}

function GuestAuthProvider(props) {
  const [state, dispatch] = useReducer(guestAuthReducer, initialState);

  function loginGuest(guestData) {
    localStorage.setItem("guestJwtToken", guestData.token);
    dispatch({
      type: "LOGIN_GUEST",
      payload: guestData,
    });
  }

  function logoutGuest() {
    localStorage.removeItem("guestJwtToken");
    dispatch({ type: "LOGOUT_GUEST" });
  }

  return (
    <GuestAuthContext.Provider
      value={{
        guest: state.guest,
        loginGuest,
        logoutGuest,
      }}
      {...props}
    />
  );
}

export { GuestAuthContext, GuestAuthProvider };
