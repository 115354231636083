import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import { useForm, useWindowSize } from "../util/hooks";
import { LOGIN_CUSTOMER } from "./AccountDetails";

function FAQ({ initialUserType }) {
  const navigate = useNavigate();

  // const [errors, setErrors] = useState({})

  useEffect(() => {
    document.title = "FAQ | StashCampus";
  }, []);
  const { guest, logoutGuest } = useContext(GuestAuthContext);

  const {
    customer,
    logoutCustomer,
    loginCustomer: loginCust,
  } = useContext(CustomerAuthContext);
  useWindowSize();
  const { values, setValues } = useForm(loginCallback, {
    email: "",
    password: "",
    payPalEmail: "",
    shouldReceivePromoEmails: false,
    searchTerm: "",
  });
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER, {
    refetchQueries: [],
    update(proxy, { data: { loginCustomer: loggedInCust } }) {
      console.log("Login success!");

      logoutGuest();
      loginCust(loggedInCust);
    },
    onError(err) {
      console.log("loginCustomer() Error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        // setErrors({
        //   ...err.graphQLErrors[0].extensions.errors,
        // });
      }
    },
    variables: values,
    client: guest ? guestClient : customerClient,
  });

  function loginCallback() {
    loginCustomer();
  }

  const [openFAQBoxes, setOpenFAQBoxes] = useState([]);

  const { data: { getFaqsBySearchTerm: faqs } = {}, loading: loadingFaqs } =
    useQuery(GET_FAQS_BY_SEARCH_TERM, {
      variables: values,
    });

  const [tempSearchTerm, setTempSeachTerm] = useState("");

  useEffect(() => {
    // Set a delay (e.g., 300 milliseconds) before updating the debouncedValue
    const timer = setTimeout(() => {
      setValues({ ...values, searchTerm: tempSearchTerm });
    }, 300);

    // Clear the timer if the user continues typing within the delay
    return () => {
      clearTimeout(timer);
    };
  }, [tempSearchTerm, values, setValues]);

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar page="faq" />

      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
      {[1, 2, 3, 4, 5].map((_, index) => (
        <button
          className={`${
            index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
          } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
          key={index}
        >
          <img
            src={profilePic}
            className="border-white border-2 box-border rounded-full "
          />
        </button>
      ))}
      <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
        <p className="text-3xl text-black font-semibold">Someboody Sum</p>
        <p className="text-lg text-gray-600">
          Spacer since 06/22 - Earns US$100/mo
        </p>
      </div>
    </div> */}
      <div className="w-full flex flex-1 flex-col items-center justify-start">
        <div className="flex flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start pb-8 sm:py-14 ">
          <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start pb-8 sm:py-14 ">
            <div className="w-full flex flex-col">
              <div className="w-full z-10 flex flex-col">
                <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                  Frequently Asked Questions
                </p>
                <div className="flex mb-1 mt-4 items-center justify-between font-semibold">
                  <p>Search</p>
                </div>
                <div className="w-full h-12 py-2 px-2 bg-white rounded shadow-sm flex items-center justify-start space-x-3">
                  <div className="rounded-full bg-black flex items-center justify-center p-2">
                    {loadingFaqs ? (
                      <svg
                        className={`w-4 m-auto stroke-current text-white motion-safe:animate-spin`}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.636 3.636A9 9 0 0119 10"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-4 text-white fill-current"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.5508 8.83346C16.5508 12.6075 13.4913 15.6669 9.71731 15.6669C5.9433 15.6669 2.88385 12.6075 2.88385 8.83346C2.88385 5.05945 5.9433 2 9.71731 2C13.4913 2 16.5508 5.05945 16.5508 8.83346ZM14.5529 16.2271C13.1637 17.1375 11.5023 17.6669 9.71731 17.6669C4.83873 17.6669 0.88385 13.7121 0.88385 8.83346C0.88385 3.95488 4.83873 0 9.71731 0C14.5959 0 18.5508 3.95488 18.5508 8.83346C18.5508 11.204 17.617 13.3564 16.0973 14.943L21.484 20.3297L20.0697 21.7439L14.5529 16.2271Z"
                        />
                      </svg>
                    )}
                  </div>
                  <input
                    className=" text-2xl w-full focus:outline-none"
                    value={tempSearchTerm}
                    onChange={(e) => {
                      const { value } = e.target;
                      setTempSeachTerm(value);
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex items-start justify-start space-y-4 w-full flex-col mt-8">
                  {faqs &&
                    faqs.length > 0 &&
                    faqs.map((faq, index) => (
                      <div
                        className={`${
                          openFAQBoxes.includes(faq.id)
                            ? "shadow-xl"
                            : "h-24 shadow-sm"
                        } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
                      >
                        <button
                          onClick={(e) => {
                            if (!openFAQBoxes.includes(faq.id)) {
                              setOpenFAQBoxes([...openFAQBoxes, faq.id]);
                            } else {
                              var copiedFAQBoxes = [...openFAQBoxes];
                              const valIndex = openFAQBoxes.indexOf(faq.id);
                              if (valIndex > -1) {
                                // only splice array when item is found
                                copiedFAQBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
                              }
                              setOpenFAQBoxes(copiedFAQBoxes);
                            }
                          }}
                          className="flex text-left shrink-0 items-center pl-4 pr-5 sm:px-12 text-white  rounded justify-between w-full h-24 bg-purple-900 hover:bg-purple-800 transition-all"
                        >
                          <h1 className="text-xl sm:text-2xl leading-tight font-semibold truncate w-full sm:leading-tight mr-2 sm:mr-0">
                            {faq.question}
                          </h1>
                          <svg
                            className={`${
                              openFAQBoxes.includes(faq.id) ? "rotate-180" : ""
                            } transform transition-all h-2 fill-current`}
                            viewBox="0 0 24 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                        <div
                          className={`${
                            openFAQBoxes.includes(faq.id)
                              ? "scale-y-100 px-4 sm:px-12"
                              : "scale-y-0 "
                          } text-left sm:text-lg text-gray-600 transform origin-top rounded-b w-full py-8 bg-white transition-all`}
                        >
                          {faq.answer}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logoutCustomer}
        keepDefaultColor={true}
      />
    </div>
  );
}
const GET_FAQS_BY_SEARCH_TERM = gql`
  query getFaqsBySearchTerm($searchTerm: String!) {
    getFaqsBySearchTerm(searchTerm: $searchTerm) {
      id
      question
      answer
      keyWords
      totalClicks
      createdAt
    }
  }
`;

export default FAQ;
