import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import CodeForm, { VERIFY_CUSTOMER } from "../components/CodeForm";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import { CustomerAuthContext } from "../context/customerAuth";
// import { GuestAuthContext } from '../context/guestAuth'
import { customerClient } from "../GraphqlApolloClients";
import stashClosetImage from "../images/stash_closet.png";
import stashCornerImage from "../images/stash_corner.png";
import stashDormImage from "../images/stash_dorm.png";
import stashLogoWhite from "../images/stash_logo_white.svg";
import { useForm } from "../util/hooks";

function Signup({ initialUserType }) {
  const navigate = useNavigate();

  const [isVerificationCodeBoxOpen, setIsVerificationCodeBoxOpen] =
    useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Sign up | StashCampus";
  }, []);

  const { customer, logoutCustomer } = useContext(CustomerAuthContext);

  const { values, setValues, onChange } = useForm(createCustomerCallback, {
    guestId: null,
    email: "",
    country: "CA",
    ip: null,
    customerId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    payPalEmail: null,
    verificationKey: "",
  });
  const [createCustomer, { loading: loadingCustomer }] = useMutation(
    CREATE_CUSTOMER,
    {
      refetchQueries: [],
      update(proxy, { data: { createCustomer: createdCustomer } }) {
        setErrors({});
        console.log("createCustomer() success!");
        // setNewCustomer(createdCustomer)
        console.log(createdCustomer);
        setValues({ ...values, customerId: createdCustomer.id });
        console.log("here are the values");
        console.log(values);
        setErrors({
          ...errors,
          email: undefined,
        });
        setIsVerificationCodeBoxOpen(!isVerificationCodeBoxOpen);
      },
      onError(err) {
        console.log("createCustomer() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...errors,
            email: err.graphQLErrors[0].extensions.errors.email,
            campusId: err.graphQLErrors[0].extensions.errors.campusId,
          });
        }
      },
      variables: values,
      client: customerClient,
    }
  );
  function createCustomerCallback() {
    createCustomer();
  }

  const [editCustomer] = useMutation(EDIT_CUSTOMER, {
    refetchQueries: [],
    update(proxy, { data: { editCustomer: editedCustomer } }) {
      setErrors({});
      console.log("editCustomer() success!");
      navigate("/dashboard");
    },
    onError(err) {
      console.log("editCustomer() error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });
  function editCustomerCallback() {
    editCustomer();
  }

  useForm(verifyCustomerCallback);
  const [verifyCustomer] = useMutation(VERIFY_CUSTOMER, {
    refetchQueries: [],
    update(proxy, { data: { verifyCustomer: tokenizedCustomer } }) {
      console.log("verifyCustomer() success!");
    },
    onError(err) {
      console.log("verifyCustomer() error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });
  function verifyCustomerCallback() {
    verifyCustomer();
  }

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar page="signup" />

      <CodeForm
        title={"Verification code"}
        name={"verificationKey"}
        isCodeBoxOpen={isVerificationCodeBoxOpen}
        setIsCodeBoxOpen={setIsVerificationCodeBoxOpen}
        errors={errors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfCode={0}
        setErrors={setErrors}
      />

      <div className="w-full flex flex-1 flex-col items-center justify-start">
        <div className="flex mb-20 sm:mb-10 sm:flex-row flex-col items-start z-10 w-full max-w-5xl  space-y-6 sm:space-y-0 sm:space-x-10 xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14">
          <div className="w-full flex flex-col px-4 sm:px-0">
            <div className="w-full flex flex-col space-y-4">
              <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                Sign up
              </p>
              <CampusSearchDropdown
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
              />
              <TextInputField
                title="School email"
                name="email"
                values={values}
                onChange={onChange}
                setValues={setValues}
                overlayBtnText="Verify"
                overlayDivText={values.verified ? "Verified" : null}
                errors={errors}
                onClickOverlay={(e) => {
                  e.preventDefault();
                  if (values.verified) {
                    return;
                  }
                  console.log("clicked 'verify'");

                  setErrors({
                    ...errors,
                    email: !values.email ? "- Required!" : undefined,
                    campusId: !values.campusId ? "- Required!" : undefined,
                  });

                  console.log(errors);

                  if (!values.verified && values.campusId && values.email) {
                    setErrors({});
                    createCustomerCallback();
                  }
                }}
                disabled={values.verified}
              />
              <div className="grid grid-cols-2 gap-2 sm:gap-3">
                <TextInputField
                  onChange={onChange}
                  title="First name"
                  name="firstName"
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  disabled={values.verified ? false : true}
                />
                <TextInputField
                  onChange={onChange}
                  title="Last name"
                  name="lastName"
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  disabled={values.verified ? false : true}
                />
              </div>
              <TextInputField
                onChange={onChange}
                title="Phone number"
                name="phoneNumber"
                values={values}
                setValues={setValues}
                errors={errors}
                disabled={values.verified ? false : true}
              />
              <TextInputField
                onChange={onChange}
                title="Password"
                name="password"
                values={values}
                setValues={setValues}
                errors={errors}
                type={"password"}
                disabled={values.verified ? false : true}
              />
              <TextInputField
                onChange={onChange}
                title="Confirm password"
                name="confirmPassword"
                values={values}
                setValues={setValues}
                errors={errors}
                type={"password"}
                disabled={values.verified ? false : true}
              />
            </div>
            <SubmitButton
              onClickFunc={(e) => {
                console.log("entered the submit handling");
                e.preventDefault();

                setErrors({
                  ...errors,
                  campusId: !values.campusId ? "- Required!" : undefined,
                  email: !values.email
                    ? "- Required!"
                    : !values.verified
                    ? "- Must be verified!"
                    : undefined,
                  phoneNumber: !values.phoneNumber ? "- Required!" : undefined,
                  firstName: !values.firstName ? "- Required!" : undefined,
                  lastName: !values.lastName ? "- Required!" : undefined,
                  password: !values.password ? "- Required!" : undefined,
                  confirmPassword: !values.confirmPassword
                    ? "- Required!"
                    : values.password !== values.confirmPassword
                    ? "- Passwords don't match"
                    : undefined,
                });

                if (
                  values.campusId &&
                  values.email &&
                  values.phoneNumber &&
                  values.firstName &&
                  values.lastName &&
                  values.password &&
                  values.confirmPassword &&
                  values.verified
                ) {
                  console.log("in here now!");
                  setErrors({});
                  editCustomerCallback();
                }
              }}
              title={"Submit"}
              isNotLoading={!loadingCustomer}
              disabled={values.verified ? false : true}
            />
            <p className="w-full mt-4 text-sm text-center font-semibold  text-purple-700">
              By clicking submit, you agree to our{" "}
              <a
                href="/terms"
                target={"_blank"}
                className="font-bold hover:text-black transition-all cursor-pointer underline"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href="/terms"
                target={"_blank"}
                className="font-bold hover:text-black transition-all cursor-pointer underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>

          {/* TODO: Show picture of university here. */}
          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
              <img
                src={
                  values.size === "CORNER"
                    ? stashCornerImage
                    : values.size === "CLOSET"
                    ? stashClosetImage
                    : stashDormImage
                }
                className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                alt={"Stash Room"}
              />
              {values && values.campusName ? (
                <img
                  src={
                    values.size === "CORNER"
                      ? values.cornerImageUrl
                      : values.size === "CLOSET"
                      ? values.closetImageUrl
                      : values.dormImageUrl
                  }
                  className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={values.campusName}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
              <img
                src={values.campusLogoUrl || stashLogoWhite}
                className="object-contain w-full shrink-0 h-full"
                alt={values.campusName || "StashCampus"}
              />
            </div>
            <div className="flex px-4 sm:px-10 my-6 text-gray-500 text-base items-center justify-center space-x-2">
              <p>TIP: Invite a friend to get $10 off your next booking.</p>
              {/* <p className="font-semibold">$19.00</p> */}
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logoutCustomer}
        keepDefaultColor={true}
      />
    </div>
  );
}

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $email: String!
    $campusId: String!
    $country: String
    $ip: String
  ) {
    createCustomer(
      email: $email
      campusId: $campusId
      country: $country
      ip: $ip
    ) {
      id
      email
      ip
      country
      referralId
      firstName
      lastName
      hasSetPassword
      phoneNumber
      verified
      token
      campusId
      ogGuestId
      payPalEmail
      stripeCustomerId
      createdAt
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation editCustomer(
    $customerId: String
    $phoneNumber: String
    $email: String
    $firstName: String
    $lastName: String
    $password: String
    $confirmPassword: String
    $payPalEmail: String
    $country: String
    $fieldsToEdit: [String]
    $profilePicFile: Upload
    $shouldReceivePromoEmails: Boolean
  ) {
    editCustomer(
      customerId: $customerId
      phoneNumber: $phoneNumber
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      confirmPassword: $confirmPassword
      payPalEmail: $payPalEmail
      country: $country
      fieldsToEdit: $fieldsToEdit
      profilePicFile: $profilePicFile
      shouldReceivePromoEmails: $shouldReceivePromoEmails
    ) {
      id
      email
      ip
      country
      referralId
      firstName
      lastName
      hasSetPassword
      phoneNumber
      verified
      token
      campusId
      ogGuestId
      payPalEmail
      stripeCustomerId
      createdAt
    }
  }
`;

export default Signup;
