import React from "react";
import lightGoose from "../images/lightgoose.png";

export default function DiscountBar() {
  return (
    <div className="w-full fixed sm:h-auto sm:absolute py-2 space-x-2 sm:space-x-1 border-purple-950 sm:border-t-0 border-t-2 sm:border-b-2 bottom-0 motion-safe:animate-fadeUp z-50 inset-x-0 sm:text-center text-left bg-white flex items-center justify-start pl-2 sm:pl-0 sm:justify-center">
      {/* <img
        className="flex-grow-0 sm:hidden block h-10 sm:h-16 "
        src={goose}
        alt={"Early Goose"}
      /> */}
      <img
        className="flex-grow-0 hidden sm:block h-10 sm:h-16 "
        src={lightGoose}
        alt={"Early Goose"}
      />
      <div className="flex flex-col sm:flex-row text-sm sm:text-lg space-x-0 sm:space-x-1.5 0.5 sm:space-y-0">
        <p className="text-black leading-tight">
          Early Goose Discount ends Oct. 15!{" "}
        </p>
        <a
          href="/bookSpace"
          className="group flex transition-all items-center justify-start space-x-2 text-purple-950 hover:text-black rounded-lg backdrop-blur-sm "
        >
          <p className="font-bold text-lg leading-tight whitespace-nowrap">
            Book now to get 30% off{" "}
          </p>
          <svg
            className="h-2.5 fill-current"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className=" -translate-x-1 group-hover:translate-x-0 transition-all"
              d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
            />
            <rect
              className="opacity-0 group-hover:opacity-100 transition-all"
              y="4"
              width="9"
              height="2"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
