import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import Bookings from "../components/Bookings";
import Earnings from "../components/Earnings";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ReferralDisplay from "../components/ReferralDisplay";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import { customerClient, guestClient } from "../GraphqlApolloClients";
import { useForm, useWindowSize } from "../util/hooks";
import { LOGIN_CUSTOMER } from "./AccountDetails";
import { FETCH_CUSTOMER } from "./BookSpace";

function Dashboard({ initialUserType }) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    customer,
    logoutCustomer: logoutCust,
    loginCustomer: loginCust,
  } = useContext(CustomerAuthContext);

  // console.log('Working with customer ', customer.id)

  const [errors, setErrors] = useState({});

  const [isReferralDisplayOpen, setIsReferralDisplayOpen] = useState(false);

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);
  const [shouldShowBookings, setShouldShowBookings] = useState(
    location.pathname !== "/dashboard/earnings"
  );
  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    // refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logoutCust();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
      navigate("/signup");
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logoutCust,
    navigate,
    checkedIsCustomerLoggedIn,
  ]);

  const { data: { getSpacesByCustomer: spaces } = {} } = useQuery(
    GET_SPACES_BY_CUSTOMER,
    {
      client: customerClient,
      onError(err) {
        console.log("getSpacesByCustomer() error!");
        console.log(err);
      },
    }
  );

  const { data: { getTotalEarningsByCustomer: totalEarnings } = {} } = useQuery(
    GET_TOTAL_EARNINGS_BY_CUSTOMER,
    {
      client: customerClient,
      onError(err) {
        console.log("getTotalEarningsByCustomer() error!");
        console.log(err);
      },
    }
  );

  const { data: { getSpaceOwnerBookingsBySteps: currentSpaceBookings } = {} } =
    useQuery(GET_SPACE_OWNER_BOOKINGS_BY_STEPS, {
      client: customerClient,
      variables: {
        steps: ["CONFIRMED", "PICKED", "PAID_OWNER_HALF"],
      },
      onError(err) {
        console.log("getSpaceOwnerBookingsBySteps() current error!");
        console.log(err);
      },
    });

  const { data: { getBookingRequests: bookingRequests } = {} } = useQuery(
    GET_BOOKING_REQUESTS,
    {
      client: customerClient,
      onError(err) {
        console.log("getBookingRequests() current error!");
        console.log(err);
      },
    }
  );

  const { data: { getRelevantBookingsByCustomer: featuredBookings } = {} } =
    useQuery(GET_RELEVANT_BOOKINGS_BY_CUSTOMER, {
      client: customerClient,
      variables: { limit: 2 },
      onError(err) {
        console.log("getRelevantBookingsByCustomer() current error!");
        console.log(err);
      },
    });

  const { data: { getSpaceOwnerBookingsBySteps: pastSpaceBookings } = {} } =
    useQuery(GET_SPACE_OWNER_BOOKINGS_BY_STEPS, {
      client: customerClient,
      variables: { steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"] },
      onError(err) {
        console.log("getSpaceOwnerBookingsBySteps() past error!");
        console.log(err);
      },
    });

  const { data: { getBookingsBySteps: currentBookings } = {} } = useQuery(
    GET_BOOKINGS_BY_STEPS,
    {
      client: customerClient,
      variables: {
        steps: ["CREATED", "BOOKED", "PAID_OWNER_HALF", "CONFIRMED", "PICKED"],
      },
      onError(err) {
        console.log("getBookingsBySteps() current error!");
        console.log(err);
      },
    }
  );

  const { data: { getBookingsBySteps: pastBookings } = {} } = useQuery(
    GET_BOOKINGS_BY_STEPS,
    {
      client: customerClient,
      variables: { steps: ["DROPPED", "CANCELLED"] },
      onError(err) {
        console.log("getBookingsBySteps() past error!");
        console.log(err);
      },
    }
  );

  const { data: { getTotalReferralCreditsByCustomer: referralCredits } = {} } =
    useQuery(GET_REFERRAL_CREDITS_BY_CUSTOMER, {
      client: customerClient,

      onError(err) {
        console.log("getTotalReferralCreditsByCustomer() current error!");
        console.log(err);
      },
    });

  useEffect(() => {
    document.title = "Dashboard | StashCampus";
  }, []);
  const { guest, logoutGuest } = useContext(GuestAuthContext);

  useWindowSize();
  const { values, setValues } = useForm(loginCallback, {
    email: "",
    password: "",
    payPalEmail: "",
    referralId: "",
    shouldReceivePromoEmails: false,
    spaceId: "",
    fullStreetAddress: "",
    campusLogoUrl: "",
    dormImageUrl: "",
    closetImageUrl: "",
    cornerImageUrl: "",
    campusName: "",
  });
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER, {
    refetchQueries: [],
    update(proxy, { data: { loginCustomer: loggedInCust } }) {
      console.log("Login success!");

      logoutGuest();
      loginCust(loggedInCust);
    },
    onError(err) {
      console.log("loginCustomer() Error!");
      console.log(values);
      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0]);
        // setErrors({
        //   ...err.graphQLErrors[0].extensions.errors,
        // });
      }
    },
    variables: values,
    client: guest ? guestClient : customerClient,
  });

  function loginCallback() {
    loginCustomer();
  }

  useEffect(() => {
    if (targetCustomer && targetCustomer.email !== values.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        verified: targetCustomer.verified,
        referralId: targetCustomer.referralId,
      });
    }
  }, [targetCustomer, setValues, values]);

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-purple-950 overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}

      <NavBar
        page="dashboard"
        shouldShowBookings={shouldShowBookings}
        setShouldShowBookings={setShouldShowBookings}
      />

      {targetCustomer && (
        <ReferralDisplay
          title={"Invite a friend"}
          isReferralDisplayOpen={isReferralDisplayOpen}
          setIsReferralDisplayOpen={setIsReferralDisplayOpen}
          referralId={targetCustomer.referralId}
          setErrors={setErrors}
          errors={errors}
        />
      )}
      {shouldShowBookings ? (
        <Bookings
          values={values}
          setValues={setValues}
          isReferralDisplayOpen={isReferralDisplayOpen}
          setIsReferralDisplayOpen={setIsReferralDisplayOpen}
          navigate={navigate}
          currentBookings={currentBookings}
          pastBookings={pastBookings}
          referralCredits={referralCredits}
        />
      ) : (
        <Earnings
          isReferralDisplayOpen={isReferralDisplayOpen}
          setIsReferralDisplayOpen={setIsReferralDisplayOpen}
          navigate={navigate}
          spaces={spaces}
          earnings={totalEarnings}
          currentBookings={currentSpaceBookings}
          pastBookings={pastSpaceBookings}
          bookingRequests={[
            ...(bookingRequests || []),
            ...(featuredBookings || []).filter(
              (booking) =>
                !(bookingRequests || []).some((req) => req.id === booking.id)
            ),
          ]}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      <Footer
        page="dashboard"
        navigate={navigate}
        customer={customer}
        logout={logoutCust}
        keepDefaultColor={true}
      />
    </div>
  );
}

export default Dashboard;

export const GET_BOOKINGS_BY_STEPS = gql`
  query getBookingsBySteps($steps: [Step]) {
    getBookingsBySteps(steps: $steps) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      lastUpdatedAt
      createdAt
    }
  }
`;

export const GET_SPACE_OWNER_BOOKINGS_BY_STEPS = gql`
  query getSpaceOwnerBookingsBySteps($steps: [Step]) {
    getSpaceOwnerBookingsBySteps(steps: $steps) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      lastUpdatedAt
      createdAt
    }
  }
`;

export const GET_BOOKING_REQUESTS = gql`
  query getBookingRequests {
    getBookingRequests {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      requestedOwnerIds
      lastUpdatedAt
      createdAt
    }
  }
`;

export const GET_RELEVANT_BOOKINGS_BY_CUSTOMER = gql`
  query getRelevantBookingsByCustomer($limit: Int) {
    getRelevantBookingsByCustomer(limit: $limit) {
      id
      spaceId
      guestId
      customerId
      adminId
      campusId
      pickupFullStreetAddress
      pickupProvince
      pickupCity
      pickupCountry
      pickupPostalCode
      pickupLine2
      pickupSpecialInstructions
      dropoffFullStreetAddress
      dropoffProvince
      dropoffCity
      dropoffCountry
      dropoffPostalCode
      dropoffLine2
      dropoffSpecialInstructions
      referralId
      startDate
      startTime
      endDate
      endTime
      size
      paymentPlan
      paymentIntentId
      requestedOwnerIds
      lastUpdatedAt
      createdAt
    }
  }
`;

export const GET_SPACES_BY_CUSTOMER = gql`
  query getSpacesByCustomer {
    getSpacesByCustomer {
      id
      campusId
      city
      customerId
      size
      fullStreetAddress
      province
      country
      postalCode
      imageUrl
      line2
      specialInstructions
      capacity
      capacityLastUpdatedAt
      isAvailable
      createdAt
    }
  }
`;

export const GET_REFERRAL_CREDITS_BY_CUSTOMER = gql`
  query getTotalReferralCreditsByCustomer {
    getTotalReferralCreditsByCustomer
  }
`;

export const GET_TOTAL_EARNINGS_BY_CUSTOMER = gql`
  query getTotalEarningsByCustomer {
    getTotalEarningsByCustomer
  }
`;
