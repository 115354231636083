import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { customerClient } from "../GraphqlApolloClients";
import { GET_CAMPUS_BY_ID, GET_PAYMENT_BY_BOOKING } from "../pages/BookSpace";
import {
  GET_BOOKINGS_BY_STEPS,
  GET_BOOKING_REQUESTS,
  GET_REFERRAL_CREDITS_BY_CUSTOMER,
  GET_RELEVANT_BOOKINGS_BY_CUSTOMER,
  GET_SPACES_BY_CUSTOMER,
  GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
  GET_TOTAL_EARNINGS_BY_CUSTOMER,
} from "../pages/Dashboard";
import ActionButton from "./ActionButton";
import {
  GET_LAST_STATUS_BY_BOOKING,
  GET_STATUSES_BY_BOOKING,
  SLOT_TO_TIME,
  formatDate,
  isDateStringToday,
} from "./BookingBox";
import BookingStatus from "./BookingStatus";
import SpaceSearchDropdown from "./SpaceSearchDropdown";

export default function BookingRequestBox({
  navigate,
  booking,
  openBookingBoxes,
  setOpenBookingBoxes,
  isPast,
  values,
  setValues,
  errors,
  setErrors,
}) {
  /* Any booking that is shown here is necessarily CREATED and BOOKED (i.e. paid for). */
  const { data: { getCampusById: campus } = {} } = useQuery(GET_CAMPUS_BY_ID, {
    variables: { campusId: booking.campusId },
    client: customerClient,
  });

  const [
    linkBookingAndSpace,
    // { loading: loadingLinkBookingAndSpace },
  ] = useMutation(LINK_BOOKING_AND_SPACE, {
    refetchQueries: [
      { query: GET_RELEVANT_BOOKINGS_BY_CUSTOMER, variables: { limit: 2 } },
      { query: GET_BOOKING_REQUESTS },
      { query: GET_TOTAL_EARNINGS_BY_CUSTOMER },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["CONFIRMED", "PICKED", "PAID_OWNER_HALF"],
        },
      },
      {
        query: GET_SPACES_BY_CUSTOMER,
      },
      {
        query: GET_TOTAL_EARNINGS_BY_CUSTOMER,
      },
      {
        query: GET_SPACE_OWNER_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["DROPPED", "PAID_OWNER_FULL", "CANCELLED"],
        },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: [
            "CREATED",
            "BOOKED",
            "PAID_OWNER_HALF",
            "CONFIRMED",
            "PICKED",
          ],
        },
      },
      {
        query: GET_BOOKINGS_BY_STEPS,
        variables: {
          steps: ["DROPPED", "CANCELLED"],
        },
      },
      {
        query: GET_REFERRAL_CREDITS_BY_CUSTOMER,
      },
    ],
    update(proxy, { data: { linkBookingAndSpace: status } }) {
      setErrors({});
      console.log("linkBookingAndSpace() completed successfully");
      console.log(status);
      // The page should now refresh, and the top booking requests section should go away.
    },
    onError(err) {
      console.log("linkBookingAndSpace() Error!");

      console.log(err);
      if (err.graphQLErrors[0]) {
        console.log("have some proper errors");
        console.log(err.graphQLErrors[0]);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: { spaceId: values.spaceId, bookingId: booking.id },
    client: customerClient,
  });

  const {
    data: { getPaymentByBooking: payment } = {},
    // loading: loadingPayment,
    // refetch: refetchGetPaymentByBooking,
  } = useQuery(GET_PAYMENT_BY_BOOKING, {
    client: customerClient,
    variables: { bookingId: booking.id },
  });

  const { data: { getStatusesByBooking: statuses } = {} } = useQuery(
    GET_STATUSES_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
    }
  );

  const { data: { getLastStatusByBooking: lastStatus } = {} } = useQuery(
    GET_LAST_STATUS_BY_BOOKING,
    {
      variables: { bookingId: booking.id },
      client: customerClient,
    }
  );

  // Note: For the intents of this page, the CANCELLED, PAID_OWNER_HALF, and PAID_OWNER_FULL states are ignored. The last state is DROPPED.

  function getDescriptionByStep(step, completed) {
    const status = completed
      ? statuses.find((status) => status.step === step)
      : null;

    switch (step) {
      case "BOOKED":
        /* Necessarily THE ONLY STEP completed. */
        return `Booked on ${formatDate(status.createdAt)}`;
      case "PICKED":
        return `Dropped off between ${SLOT_TO_TIME[booking.startTime]} ${
          "on " + formatDate(booking.startDate)
        }`;
      case "PAID_OWNER_HALF":
        return `First payment of $${(payment.ownerTotal / 2 / 100).toFixed(
          2
        )} received`;
      case "PAID_OWNER_FULL":
        return `Final payment of $${(payment.ownerTotal / 2 / 100).toFixed(
          2
        )} received`;
      case "DROPPED":
        return `Picked up between ${SLOT_TO_TIME[booking.endTime]} ${
          "on " + formatDate(booking.endDate)
        }`;
      default:
        break;
    }
  }

  const steps = [
    "BOOKED",
    "PICKED",
    "PAID_OWNER_HALF",
    "DROPPED",
    "PAID_OWNER_FULL",
  ];

  useEffect(() => {
    if (campus && !values.campusName) {
      setValues({
        ...values,
        campusName: campus.name,
        campusLogoUrl: campus.logoUrl,
        dormImageUrl: campus.dormImageUrl,
        closetImageUrl: campus.closetImageUrl,
        cornerImageUrl: campus.cornerImageUrl,
      });
    }
  }, [campus, values, setValues]);

  // TODO: Maybe add date to summary view in case they make identical bookings.
  return statuses && campus && lastStatus && payment && booking ? (
    <div
      className={`${
        openBookingBoxes.includes(booking.id) ? "shadow-xl" : "h-24 shadow-sm"
      } flex items-start transition-all bg-white transform flex-col justify-start w-full rounded `}
    >
      <button
        onClick={(e) => {
          if (!openBookingBoxes.includes(booking.id)) {
            setOpenBookingBoxes([...openBookingBoxes, booking.id]);
          } else {
            var copiedBookingBoxes = [...openBookingBoxes];
            const valIndex = openBookingBoxes.indexOf(booking.id);
            if (valIndex > -1) {
              // only splice array when item is found
              copiedBookingBoxes.splice(valIndex, 1); // 2nd parameter means remove one item only
            }
            setOpenBookingBoxes(copiedBookingBoxes);
          }
        }}
        className={`flex text-left shrink-0 items-center p-2 pr-3 sm:pr-6 text-purple-950  rounded justify-between w-full h-24 bg-light-beige hover:bg-opacity-80 transition-all`}
      >
        {isDateStringToday(lastStatus.createdAt) && (
          <p className="px-1 right-2 top-2 absolute font-semibold text-white rounded-sm bg-purple-950 tracking-wide uppercase text-xs">
            New
          </p>
        )}
        <div className="h-full aspect-square bg-white rounded">
          {campus && (
            <img
              className="flex-grow-0 w-full h-full top-0 object-cover p-1 z-10"
              src={
                booking.size === "DORM"
                  ? campus.dormImageUrl
                  : booking.size === "CLOSET"
                  ? campus.closetImageUrl
                  : campus.cornerImageUrl
              }
              alt={"Campus URL"}
            />
          )}
        </div>
        <div className="flex items-start justify-center flex-col ml-2 sm:ml-3 w-full truncate flex-1">
          {isDateStringToday(lastStatus.createdAt) && (
            <p className="px-1 right-2 top-2 absolute font-semibold text-white rounded-sm bg-purple-950 tracking-wide uppercase text-xs">
              New
            </p>
          )}
          <h1 className="text-xl sm:text-2xl leading-tight font-semibold truncate w-full sm:leading-tight">
            {campus?.name}
          </h1>
          <p className="sm:text-base text-sm leading-tight truncate w-full">
            <b>
              <i>{booking.size}</i> •
            </b>{" "}
            {"Dropoff on " + formatDate(booking.startDate)}
            {/* {isPast ? 'Completed' : timeline[0] + ' till ' + timeline[1]} */}
          </p>
        </div>
        <svg
          className={`${
            openBookingBoxes.includes(booking.id) ? "rotate-180" : ""
          } transform transition-all h-2 fill-current shrink-0`}
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.75 0.375L12 11.625L23.25 0.375H0.75Z" />
        </svg>
      </button>
      <div
        className={`${
          openBookingBoxes.includes(booking.id) ? "scale-y-100" : "scale-y-0 "
        } text-left flex flex-col sm:text-lg text-gray-600 transform origin-top rounded-b w-full px-2 bg-white transition-all`}
      >
        <p className="sm:italic tracking-wide text-sm sm:text-base leading-tight mt-4">
          <b>Booking:</b> {booking.id}
          {values.spaceId && (
            <>
              {" "}
              • <b>Space:</b> {values.spaceId}
            </>
          )}
        </p>
        <SpaceSearchDropdown
          values={values}
          setValues={setValues}
          errors={errors}
        />

        <div className="flex flex-col items-start justify-start w-full h-full sm:pb-0 relative">
          <div className="w-0.5 bg-purple-950 absolute left-[0.7rem] top-2 bottom-7"></div>
          {/* We can go through each known status and show it. */}
          {steps.map((step, index) => (
            <div key={index}>
              <BookingStatus
                text={getDescriptionByStep(
                  step,
                  statuses.some((status) => status.step === step) // true if completed, false otherwise
                )}
                completed={statuses.some((status) => status.step === step)} // true if completed, false otherwise
                last={step === "PAID_OWNER_FULL"}
              />
              {step === "PICKED" && (
                // Show additional state if the item has been picked
                <BookingStatus
                  text={"Belongings stored with care"}
                  completed={statuses.some((status) => status.step === step)}
                  last={false}
                />
              )}
            </div>
          ))}
        </div>
        <div className="w-full pb-2 items-center justify-center flex space-x-1">
          <div className="w-full">
            <ActionButton
              onClickFunc={(e) => {
                linkBookingAndSpace();
              }}
              text="Accept"
              type={0}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export const LINK_BOOKING_AND_SPACE = gql`
  mutation LinkBookingAndSpace($bookingId: String!, $spaceId: String!) {
    linkBookingAndSpace(bookingId: $bookingId, spaceId: $spaceId) {
      bookingId
      step
      createdAt
      amount
      amount
      description
      id
    }
  }
`;
