import React, { useEffect } from 'react'
import { Gradient } from '../util/Gradient'

export default function BackgroundAnimation() {
  useEffect(() => {
    const gradient = new Gradient()

    gradient.initGradient('#gradient-canvas-beige')
  }, [])

  return (
    <div className="z-10 w-screen h-full bg-purple-950 origin-top-left  object-cover absolute inset-0 ">
      <canvas
        id={`gradient-canvas-beige`}
        className="motion-safe:animate-fadeInFast"
        data-js-darken-top
        data-transition-in
      />
    </div>
  )
}
