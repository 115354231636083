import React from 'react'

export default function ToggleInputField({
  value,
  errors,
  setValues,
  title,
  isInPopUp,
  name,
  values,
}) {
  return (
    <button
      onClick={(e) => {
        var newFieldsToEdit = null
        if (values.fieldsToEdit) {
          newFieldsToEdit = [...values.fieldsToEdit]

          if (!newFieldsToEdit.includes(name)) {
            newFieldsToEdit.push(name)
          }
        }
        setValues({ ...values, [name]: !value, fieldsToEdit: newFieldsToEdit })
      }}
      className="w-full bg-purple-900 hover:bg-purple-800 transition-all group px-4 py-2 rounded shadow-sm  h-12 flex items-center justify-between"
    >
      <div className="flex w-full items-center font-semibold justify-between text-sm sm:text-base">
        <label htmlFor="streetAddress" className="text-white">
          {title}{' '}
          {errors.streetAddress && (
            <span className="text-red-600">{errors.streetAddress}</span>
          )}
        </label>
      </div>
      <div className="rounded-full w-10 relative h-5 bg-white">
        <div
          className={`${
            value ? 'translate-x-[0.91rem] sm:translate-x-[1.04rem]' : ''
          } transform inset-x-0.5 h-4 aspect-square bg-purple-900  group-hover:bg-purple-800 rounded-full absolute inset-y-0.5 transition-all`}
        ></div>
      </div>
    </button>
  )
}
