import React, { useState } from "react";
import { useWindowSize } from "../util/hooks";
import ActionButton from "./ActionButton";
import SpaceBookingBox from "./SpaceBookingBox";
import SpaceBox from "./SpaceBox";
// import BookingBox from './BookingBox'
import BookingRequestBox from "./BookingRequestBox";

export default function Earnings({
  navigate,
  spaces,
  currentBookings,
  pastBookings,
  earnings,
  isReferralDisplayOpen,
  setIsReferralDisplayOpen,
  bookingRequests,
  values,
  setValues,
  errors,
  setErrors,
}) {
  useWindowSize();
  const [openBookingBoxes, setOpenBookingBoxes] = useState([]);
  const [openSpaceBoxes, setOpenSpaceBoxes] = useState([]);
  const [openBookingRequestBoxes, setOpenBookingRequestBoxes] = useState([]);

  // We keep track of the space owners that we requested for this booking. Then here we show them all those bookings that they've been requested for and that haven't yet been confirmed. There is a dropdown to let them pick from only the eligible spaces (must add validation for this in the backend).
  // We show them 2 featured bookings, in case they make their place available afterwards and don't get the email. We remove duplicates using a useEffect() here. And we just show it as a part of the bookingRequests section.

  /* The current and past bookings here necessarily have spaces associated that are owned by this user. */

  // Instead of querying the space by ID, just look within the larger space array.
  function getSpaceById(spaceId) {
    if (spaces) {
      for (const space of spaces) {
        if (space.id === spaceId) {
          return space;
        }
      }
    }
  }

  // console.log("have current bookings");
  // console.log(currentBookings);

  // console.log("have past bookings");
  // console.log(pastBookings);

  return (
    <div className="w-full flex flex-col items-center min-h-screen justify-start">
      <div
        className={`flex z-10 flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start`}
      >
        <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start py-14">
          <div className="w-full flex flex-col space-y-10 mb-6 sm:mb-2">
            {spaces &&
              spaces.length === 0 &&
              pastBookings &&
              pastBookings.length === 0 &&
              currentBookings &&
              currentBookings.length === 0 &&
              bookingRequests &&
              bookingRequests.length === 0 && (
                <div className="w-full flex flex-col space-y-4">
                  <p className="text-white font-bold mb-2 text-4xl leading-heading">
                    Welcome to StashCampus!
                  </p>
                  <p className="text-lg text-white">
                    You'll be able to manage your spaces here.
                  </p>
                </div>
              )}
            {bookingRequests && bookingRequests.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <div className="w-full flex items-start space-x-1 justify-start">
                  <p className="text-white font-bold mb-6 text-4xl leading-heading">
                    Booking requests
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    className="mt-1"
                  >
                    <circle cx="5" cy="5" r="5" fill="#FF0000" />
                  </svg>
                </div>
                {bookingRequests.map((bookingRequest, index) => (
                  <BookingRequestBox
                    key={index}
                    navigate={navigate}
                    isPast={false}
                    booking={bookingRequest}
                    openBookingBoxes={openBookingRequestBoxes}
                    setOpenBookingBoxes={setOpenBookingRequestBoxes}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ))}
              </div>
            )}
            {spaces && spaces.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <p className="text-white font-bold mb-6 text-4xl leading-heading">
                  Spaces
                </p>
                {spaces.map((currentSpace, index) => (
                  <SpaceBox
                    key={index}
                    navigate={navigate}
                    space={currentSpace}
                    openSpaceBoxes={openSpaceBoxes}
                    setOpenSpaceBoxes={setOpenSpaceBoxes}
                  />
                ))}
              </div>
            )}
            {currentBookings && currentBookings.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <p className="text-white font-bold mb-6 text-4xl leading-heading">
                  Active Bookings
                </p>
                {currentBookings.map((currentBooking, index) => (
                  <SpaceBookingBox
                    navigate={navigate}
                    key={index}
                    values={values}
                    setValues={setValues}
                    isPast={false}
                    space={getSpaceById(currentBooking.spaceId)}
                    booking={currentBooking}
                    openBookingBoxes={openBookingBoxes}
                    setOpenBookingBoxes={setOpenBookingBoxes}
                  />
                ))}
              </div>
            )}
            {pastBookings && pastBookings.length > 0 && (
              <div className="w-full flex flex-col space-y-4">
                <p className="text-white font-bold mb-6 text-4xl leading-heading">
                  Past Bookings
                </p>
                {pastBookings.map((pastBooking, index) => (
                  <SpaceBookingBox
                    navigate={navigate}
                    isPast={true}
                    space={getSpaceById(pastBooking.spaceId)}
                    booking={pastBooking}
                    values={values}
                    setValues={setValues}
                    openBookingBoxes={openBookingBoxes}
                    setOpenBookingBoxes={setOpenBookingBoxes}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
            {/* <div className="bg-gray-100 rounded-lg w-full h-64"></div> */}

            <div className="flex text-gray-500 flex-col text-base items-center justify-center h-80 p-2 short:p-0">
              <h2 className="mt-4">Your total earnings</h2>

              {/* <button
                  onClick={(e) => {
                    navigate("/bookSpace");
                  }}
                  className="group flex sm:h-20 w-full text-xl hover:bg-opacity-80 border-purple-950  border-2 transition-all items-center justify-center uppercase space-x-2 bg-purple-950 text-white rounded-lg backdrop-blur-sm pl-5 pr-4 sm:pl-12 sm:pr-11 py-5"
                >
                  <p className="font-bold">Book for next term</p>
                  <svg
                    className="h-2.5 fill-current"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                      d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                    />
                    <rect
                      className="opacity-0 group-hover:opacity-100 transition-all"
                      y="4"
                      width="9"
                      height="2"
                    />
                  </svg>
                </button> */}

              <div className="flex motion-safe:animate-fadeInFast items-center justify-center leading-none flex-1 pb-6 font-extrabold text-purple-900 text-6xl">
                {earnings ? "$" + (earnings / 100).toFixed(2) : "-"}
              </div>

              {/* <button
                onClick={(e) => {
                  navigate('/shareSpace')
                }}
                className="group flex sm:h-20 w-full text-xl hover:bg-opacity-80 transition-all items-center justify-center  space-x-2 bg-red-600 text-white border-2 border-red-600 rounded-lg backdrop-blur-sm px-3 py-3"
              >
                <div className="flex leading-tight items-center justify-center flex-col">
                  <p className="text-base">Invite a friend and get</p>

                  <p className="font-bold italic">GET $10 OFF NEXT TIME!</p>
                </div>
              </button> */}

              <div className="w-full items-center justify-center flex flex-col space-y-2">
                <div className="w-full items-center justify-center grid grid-cols-2 gap-2">
                  <ActionButton
                    onClickFunc={(e) => {
                      navigate("/bookSpace");
                    }}
                    type={0}
                    text={"Book space"}
                  />

                  <ActionButton
                    onClickFunc={(e) => {
                      navigate("/shareSpace");
                    }}
                    type={1}
                    text="Share space"
                  />
                </div>
                <ActionButton
                  onClickFunc={(e) => {
                    setIsReferralDisplayOpen(true);
                  }}
                  type={2}
                  text="Invite a friend"
                />
              </div>
            </div>

            {/* <div className="flex mt-4 px-10 text-gray-500 text-base items-center justify-between space-x-2">
                <p>Taxes for this month</p>
                <p className="font-semibold">$5.52</p>
              </div>

              <div className="flex px-10 mt-4 text-gray-500 text-base items-center justify-between space-x-2">
                <p>Easter egg discount</p>
                <p className="font-semibold">$4.00</p>
              </div>

              <div className="flex px-10 mt-4 mb-6 text-black text-base items-center justify-between space-x-2">
                <p>Total</p>
                <p className="font-semibold">CA$14.00</p>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
