import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampusSearchDropdown from "../components/CampusSearchDropdown";
// import { HostAuthContext } from "../context/hostAuth";
// import { GuestAuthContext } from "../context/guestAuth";
import { customerClient } from "../GraphqlApolloClients";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ProfilePicInputField from "../components/ProfilePicInputField";
import SubmitButton from "../components/SubmitButton";
import TextInputField from "../components/TextInputField";
import ToggleInputField from "../components/ToggleInputField";
import { CustomerAuthContext } from "../context/customerAuth";
import stashDormImage from "../images/stash_dorm.png";
import stashLogoWhite from "../images/stash_logo_white.svg";
import { useForm, useWindowSize } from "../util/hooks";
import usePageTracking from "../util/usePageTracking";
import { FETCH_CUSTOMER, GET_CAMPUS_BY_ID } from "./BookSpace";
import { EDIT_CUSTOMER } from "./Signup";

function AccountDetails({ initialUserType }) {
  const { customer, logoutCustomer } = useContext(CustomerAuthContext);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  usePageTracking();

  useEffect(() => {
    document.title = "Account Details | StashCampus";
  }, []);

  useWindowSize();
  const { values, setValues, onChange, onChangeMedia } = useForm(
    editCustomerCallback,
    {
      email: "",
      password: "",
      payPalEmail: "",
      shouldReceivePromoEmails: false,
      campusId: "",
      campusLogoUrl: "",
      campusName: "",
      profilePicUrl: "",
      profilePicFile: null,
      fieldsToEdit: [],
      customerId: "",
    }
  );

  const [editCustomer, { loading: loadingEditCustomer }] = useMutation(
    EDIT_CUSTOMER,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        {
          query: GET_CAMPUS_BY_ID,
          variables: { campusId: values.campusId },
        },
      ],
      update(proxy, { data: { editCustomer: editedCustomer } }) {
        setErrors({});
        setValues({ ...values, fieldsToEdit: [] });
        console.log("editCustomer() success!");
      },
      onError(err) {
        console.log("editCustomer() error!");
        console.log(values);
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customerClient,
    }
  );

  function editCustomerCallback() {
    editCustomer();
  }

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const { data, refetch: refetchCampusById } = useQuery(GET_CAMPUS_BY_ID, {
    variables: { campusId: values.campusId },
    client: customerClient,
    onCompleted() {
      console.log("Successfully completed getCampusById()!");
    },
    onError(err) {
      console.log("getCampusById() Error!");
      console.log(err);
    },
  });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      customer &&
      !loadingCustomerInfo &&
      !targetCustomer &&
      !checkedIsCustomerLoggedIn
    ) {
      logoutCustomer();
      setCheckedIsCustomerLoggedIn(true);
    } else if (!customer) {
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logoutCustomer,
    checkedIsCustomerLoggedIn,
  ]);

  useEffect(() => {
    if (targetCustomer) {
      setValues((prevValues) => ({
        ...prevValues,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        payPalEmail: targetCustomer.payPalEmail,
        campusId: targetCustomer.campusId,
        shouldReceivePromoEmails: targetCustomer.shouldReceivePromoEmails
          ? true
          : false,
        profilePicUrl: targetCustomer.profilePicUrl,
        customerId: targetCustomer.customerId,
      }));
      refetchCampusById({ campusId: targetCustomer.campusId });
    }
  }, [targetCustomer, setValues, refetchCampusById]);

  useEffect(() => {
    if (data && data.getCampusById) {
      setValues((prevValues) => ({
        ...prevValues,
        campusName: data.getCampusById.name,
        campusLogoUrl: data.getCampusById.logoImageUrl,
        dormImageUrl: data.getCampusById.dormImageUrl,
      }));
    }
  }, [data, setValues]);

  return (
    <div className="w-screen flex flex-col items-center justify-start relative bg-light-beige overflow-x-hidden h-full min-h-screen">
      <div className="max-w-[67rem] pointer-events-none inset-x-0 px-2 lg:px-0 m-auto z-10 w-full flex items-center justify-between  h-full absolute">
        <div className="border-l h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30"></div>
        <div className="border-l border-dashed hidden sm:block h-full border-purple-300/30 "></div>
        <div className="border-l border-dashed hidden lg:block h-full border-purple-300/30 "></div>
        <div className="border-l h-full border-purple-300/30 "></div>
      </div>

      {/* <div className="w-screen relative min-h-[85vh] sm:min-h-[55vh] xl:min-h-screen pb-16 xl:pb-0"> */}
      <NavBar page="account" />
      {/* </div> */}

      {/* <div className="flex items-center group justify-start max-w-5xl xl:px-0 px-6 mt-10 mx-auto">
      {[1, 2, 3, 4, 5].map((_, index) => (
        <button
          className={`${
            index !== 0 ? "-ml-10 sm:group-hover:ml-5" : ""
          } flex-shrink-0 shadow-lg hover:bg-purple-700 transition-all bg-black overflow-hidden p-2 rounded-full aspect-square w-20 sm:w-24`}
          key={index}
        >
          <img
            src={profilePic}
            className="border-white border-2 box-border rounded-full "
          />
        </button>
      ))}
      <div className="ml-5 hidden sm:block sm:ml-10 flex-shrink-0 whitespace-nowrap">
        <p className="text-3xl text-black font-semibold">Someboody Sum</p>
        <p className="text-lg text-gray-600">
          Spacer since 06/22 - Earns US$100/mo
        </p>
      </div>
    </div> */}
      <div className="w-full flex flex-1 flex-col items-center justify-start">
        <div className="flex flex-col items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start pb-8 sm:py-14">
          <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-10 items-start w-full max-w-5xl xl:px-0 px-2 sm:px-8 mx-auto justify-start sm:py-14 ">
            <div className="w-full flex flex-col">
              <div className="w-full flex flex-col space-y-4">
                <p className="text-black font-bold mb-6 text-3xl sm:text-4xl leading-heading">
                  Account details
                </p>
                <div className="flex items-start justify-start space-x-4">
                  <ProfilePicInputField
                    title="Profile picture"
                    name="profilePicFile"
                    onChangeMedia={onChangeMedia}
                    value={values.profilePicUrl}
                    shouldNotEdit={true}
                    values={values}
                    error={errors.profilePicFile}
                  />
                  <CampusSearchDropdown
                    values={values}
                    disabled={true}
                    setValues={setValues}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </div>
                <TextInputField
                  title="School email"
                  name="email"
                  values={values}
                  setValues={setValues}
                  disabled={true}
                  errors={errors}
                />
                <div className="grid grid-cols-2 gap-2 sm:gap-3">
                  <TextInputField
                    title="First name"
                    name="firstName"
                    values={values}
                    onChange={onChange}
                    setValues={setValues}
                    errors={errors}
                  />
                  <TextInputField
                    title="Last name"
                    name="lastName"
                    values={values}
                    onChange={onChange}
                    setValues={setValues}
                    errors={errors}
                  />
                </div>
                <TextInputField
                  title="Phone number"
                  name="phoneNumber"
                  values={values}
                  setValues={setValues}
                  onChange={onChange}
                  errors={errors}
                />
                <TextInputField
                  title="Password"
                  name="password"
                  values={values}
                  onChange={onChange}
                  setValues={setValues}
                  errors={errors}
                />
                <TextInputField
                  title="Confirm password"
                  name="confirmPassword"
                  values={values}
                  onChange={onChange}
                  setValues={setValues}
                  errors={errors}
                />

                <TextInputField
                  title="PayPal email (for receiving funds)"
                  name="payPalEmail"
                  values={values}
                  setValues={setValues}
                  errors={errors}
                />
              </div>

              <div className="mt-7 z-10 w-full">
                <ToggleInputField
                  title={
                    values.shouldReceivePromoEmails
                      ? "Receive promo emails"
                      : "Don't receive promo emails"
                  }
                  name="shouldReceivePromoEmails"
                  value={values.shouldReceivePromoEmails}
                  setValues={setValues}
                  errors={errors}
                  values={values}
                />
              </div>
            </div>
            <div className="w-full text-lg relative bg-white rounded-xl shadow p-1">
              <div className="bg-gray-100 py-2 relative rounded-lg w-full h-80">
                <img
                  src={stashDormImage}
                  className="object-contain inset-0 z-0 absolute bg-blend-overlay w-full shrink-0 h-full"
                  alt={"Stash Room"}
                />
                {values && values.campusName ? (
                  <img
                    src={values.dormImageUrl}
                    className="object-contain motion-safe:animate-fadeIn inset-0 z-10 absolute bg-blend-overlay w-full shrink-0 h-full"
                    alt={values.campusName}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="w-12 h-12 px-3 overflow-hidden aspect-square top-4 left-4 bg-black rounded-full absolute">
                <img
                  src={values.campusLogoUrl || stashLogoWhite}
                  className="object-contain w-full shrink-0 h-full"
                  alt={values.campusName || "StashCampus"}
                />
              </div>
              {/* <div className="flex px-4 sm:px-10 my-6 text-gray-500 text-base items-center justify-center space-x-2">
                <p>TIP: Invite a friend to get $10 off your next booking.</p>
              </div> */}
              <SubmitButton
                isNotLoading={!loadingEditCustomer}
                disabled={
                  !values.fieldsToEdit || values.fieldsToEdit.length === 0
                }
                onClickFunc={(e) => {
                  e.preventDefault();

                  console.log(values);
                  editCustomer();
                }}
                isFixedButton={true}
                title={"Edit"}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer
        page="login"
        navigate={navigate}
        customer={customer}
        logout={logoutCustomer}
        keepDefaultColor={true}
      />
    </div>
  );
}

export const LOGIN_CUSTOMER = gql`
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(email: $email, password: $password) {
      id
      email
      ip
      country
      referralId
      firstName
      lastName
      hasSetPassword
      phoneNumber
      verified
      token
      campusId
      ogGuestId
      payPalEmail
      stripeCustomerId
      createdAt
    }
  }
`;

export default AccountDetails;
