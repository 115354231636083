import React, { useState } from 'react'
import AddressForm from './AddressForm'

export const TYPE_TO_FULL_STREET_ADDRESS = {
  0: 'pickupFullStreetAddress',
  1: 'dropoffFullStreetAddress',
  2: 'fullStreetAddress',
}
export const TYPE_TO_PROVINCE = {
  0: 'pickupProvince',
  1: 'dropoffProvince',
  2: 'province',
}
export const TYPE_TO_CITY = { 0: 'pickupCity', 1: 'dropoffCity', 2: 'city' }
export const TYPE_TO_COUNTRY = {
  0: 'pickupCountry',
  1: 'dropoffCountry',
  2: 'country',
}
export const TYPE_TO_POSTAL_CODE = {
  0: 'pickupPostalCode',
  1: 'dropoffPostalCode',
  2: 'postalCode',
}
export const TYPE_TO_LINE2 = { 0: 'pickupLine2', 1: 'dropoffLine2', 2: 'line2' }
export const TYPE_TO_SPECIAL_INSTRUCTIONS = {
  0: 'pickupSpecialInstructions',
  1: 'dropoffSpecialInstructions',
  2: 'specialInstructions',
}

const TYPE_TO_TITLE = {
  0: 'Pick up address',
  1: 'Drop off address',
  2: 'Address',
}

export default function AddressField({
  title,
  description,
  values,
  setValues,
  onChange,
  typeOfAddress,
  errors,
  setErrors,
}) {
  const [isAddressBoxOpen, setIsAddressBoxOpen] = useState(false)

  // typeOfAddress = 1 => dropoff, 0 => pickup, 2 => regular.

  const error = errors[TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]] ?? null

  return (
    <div className="w-full">
      <AddressForm
        title={TYPE_TO_TITLE[typeOfAddress]}
        isAddressBoxOpen={isAddressBoxOpen}
        setIsAddressBoxOpen={setIsAddressBoxOpen}
        errors={errors}
        setErrors={setErrors}
        values={values}
        setValues={setValues}
        onChange={onChange}
        typeOfAddress={typeOfAddress}
      />

      <div className="flex mb-1 items-center justify-between font-semibold text-sm sm:text-base">
        <p>
          {TYPE_TO_TITLE[typeOfAddress]}
          {error ? <span className="text-red-600 ml-1">{error}</span> : ''}
        </p>
        {1 ? <></> : <button className="text-purple-500">Edit</button>}
      </div>
      <div className="w-full h-12 flex items-center justify-start space-x-3">
        <button
          onClick={(e) => {
            setIsAddressBoxOpen(true)
          }}
          className="h-full w-full cursor-text text-left bg-white px-3 transition-all rounded shadow-sm"
        >
          {values[TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]] ? (
            <p className="truncate">
              {values[TYPE_TO_FULL_STREET_ADDRESS[typeOfAddress]]}
              <span className="font-semibold">
                {values[TYPE_TO_LINE2[typeOfAddress]]
                  ? ' - ' + values[TYPE_TO_LINE2[typeOfAddress]]
                  : ''}
              </span>
            </p>
          ) : (
            ''
          )}
        </button>
      </div>
    </div>
  )
}
