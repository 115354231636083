import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerClient } from "../GraphqlApolloClients";
import { CustomerAuthContext } from "../context/customerAuth";
import { FETCH_CUSTOMER } from "../pages/BookSpace";

export default function NavBar({
  page,
  productsRef,
  resourcesRef,
  pricingRef,
  pageTopRef,
  shouldHideLogin,
  shouldShowBookings,
  setShouldShowBookings,
}) {
  let navigate = useNavigate();
  // let location = useLocation()

  const { customer } = useContext(CustomerAuthContext);

  const [values, setValues] = useState({});
  const { data: { getCustomer: targetCustomer } = {} } = useQuery(
    FETCH_CUSTOMER,
    {
      client: customerClient,
    }
  );

  useEffect(() => {
    if (targetCustomer && values.email !== targetCustomer.email) {
      setValues({
        ...values,
        email: targetCustomer.email,
        firstName: targetCustomer.firstName,
        lastName: targetCustomer.lastName,
        hasSetPassword: targetCustomer.hasSetPassword,
        phoneNumber: targetCustomer.phoneNumber,
        payPalEmail: targetCustomer.payPalEmail,
        campusId: targetCustomer.campusId,
        shouldReceivePromoEmails: targetCustomer.shouldReceivePromoEmails
          ? true
          : false,
        profilePicUrl: targetCustomer.profilePicUrl,
        customerId: targetCustomer.customerId,
      });
    }
  }, [targetCustomer, setValues, values]);

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [shouldShowMiniNav, setShouldShowMiniNav] = useState(false);
  return (
    <div
      className={`relative w-full max-w-5xl mx-auto xl:px-0 ${
        page === "landing" ? "" : "px-4"
      } sm:px-8 pb-10 lg:pb-0`}
    >
      <div
        className={`${
          shouldShowMiniNav
            ? "motion-safe:animate-fadeInFast block"
            : !isFirstTime
            ? "motion-safe:animate-fadeOutFast"
            : "hidden"
        } overflow-hidden absolute mt-2 left-2 sm:left-1/2 right-2 bg-white pt-[1.1rem]  z-40 shadow-2xl rounded-lg`}
      >
        <div className="flex px-4 items-center justify-between">
          <button
            onClick={(e) => {
              navigate("/");
            }}
            className="transition-all text-purple-950 hover:opacity-50 flex items-center justify-start space-x-2"
          >
            <svg
              className={`h-8 fill-current `}
              viewBox="0 0 71 77"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.5 0C23.1137 0 9.12673 5.36113 0 12.8333C0 40.3544 16.3655 64.4875 35.5 77C54.6345 64.4875 71 40.3544 71 12.8333C61.8733 5.36113 47.9153 0 35.5 0Z" />
              <path
                d="M35.5 9.67892C26.5503 9.67892 16.444 13.36 9.84949 18.4906C9.84949 37.3873 21.6744 53.9576 35.5 62.549C49.3257 53.9576 61.1505 37.3873 61.1505 18.4906C54.556 13.36 44.4707 9.67892 35.5 9.67892Z"
                fill={"white"}
              />
              <path d="M27.9557 49.5809C31.9793 50.5784 40.0265 50.5784 41.0325 47.087L7.20621 25.1409H6.83189C5.39752 36.4862 6.43686 30.4299 13.3701 42.0993C13.3701 42.0993 23.9321 48.5833 27.9557 49.5809Z" />
              <path d="M36.5059 20.1532C44.0501 20.1532 62.6595 27.6348 62.6595 27.6348L64.1684 33.6201L59.1387 38.1091L26.9498 23.6446C26.9498 23.6446 28.9617 20.1532 36.5059 20.1532Z" />
            </svg>
            <p className="text-xl font-semibold">StashCampus</p>
          </button>

          <button
            onClick={(e) => {
              setIsFirstTime(false);
              setShouldShowMiniNav(false);
            }}
            className="mr-1.5 hover:opacity-50 transition-all"
          >
            <svg
              className="h-3  text-gray-700 fill-current"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
            </svg>
          </button>
        </div>
        <div className="mx-2 flex flex-col items-center justify-start mb-2">
          <button
            onClick={(e) => {
              if (customer) {
                navigate("/dashboard");
              } else {
                navigate("/bookSpace");
              }
            }}
            className="mt-4 group border-b border-gray-300 border-dashed bg-gray-100 hover:bg-gray-50 text-gray-700 flex items-center rounded-b-none justify-between text-left rounded-lg px-3 py-5 w-full"
          >
            <p className="font-semibold text-xl text-gray-700">
              {customer ? "Dashboard" : "Book a space"}
            </p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
          {!customer && (
            <button
              onClick={(e) => {
                if (customer) {
                  navigate("/dashboard");
                } else {
                  navigate("/shareSpace");
                }
              }}
              className="group bg-gray-100 hover:bg-gray-50 text-gray-700 flex items-center justify-between text-left rounded-t-none rounded-lg px-3 py-5 w-full"
            >
              <p className="font-semibold text-xl text-gray-700">
                {customer ? "Dashboard" : "Share your space"}
              </p>
              <svg
                className="h-3 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </button>
          )}
          <button
            onClick={(e) => {
              navigate("/product");
            }}
            className="group border-b-2 border-dashed text-gray-700 flex items-center justify-between border-gray-100 text-left px-3 py-5 w-full"
          >
            <p className="font-semibold text-xl text-gray-700">Product</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              if (pricingRef && pricingRef.current) {
                pricingRef.current.scrollIntoView({
                  behavior: "smooth",
                  // block: "start",
                  // inline: "nearest",
                });
              } else {
                navigate("/pricing");
              }
            }}
            className="group border-b-2 border-dashed text-gray-700 border-gray-100 text-left flex items-center justify-between px-3 py-5 w-full"
          >
            <p className="font-semibold text-xl">Pricing</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>

          <button
            onClick={() => {
              if (resourcesRef && resourcesRef.current) {
                resourcesRef.current.scrollIntoView({
                  behavior: "smooth",
                  // block: "start",
                  // inline: "nearest",
                });
              } else {
                navigate("/reviews");
              }
            }}
            className="group border-b-2 border-dashed text-gray-700 flex items-center justify-between border-gray-100 text-left px-3 py-5 w-full"
          >
            <p className="font-semibold text-xl text-gray-700">Reviews</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>

          <button
            onClick={(e) => {
              navigate("/faq");
            }}
            // target={'_blank'}
            // rel="noreferrer"
            className="group text-gray-700 flex items-center justify-between text-left px-3 py-5 w-full"
          >
            <p className="font-semibold text-xl text-gray-700">FAQ</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
          {/* <button className=" border-b-2 border-dashed border-gray-100 text-left px-3 py-5 w-full">
            <p className="font-semibold text-xl">Contact us</p>
          </button> */}
        </div>

        <div className="m-2 mt-6 bg-gray-100 py-5 rounded">
          <button
            onClick={(e) => {
              if (page === "login") {
                navigate("/signup");
              } else if (customer) {
                navigate("/dashboard");
              } else {
                navigate("/login");
              }
            }}
            className="group mx-auto flex-shrink-0 flex transition-all items-center justify-center space-x-2 hover:bg-black bg-purple-950 text-white rounded-lg backdrop-blur-sm pl-4 pr-3 py-1"
          >
            <p className="font-bold">
              {page === "login"
                ? "Sign up instead"
                : page === "signup"
                ? "Sign in instead"
                : customer
                ? "Dashboard"
                : "Sign in"}
            </p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={`${
          page === "dashboard" ? "text-light-beige" : "text-purple-950"
        } text-lg short:text-base sm:text-sm relative shrink-0 z-10 py-6 sm:py-8 xl:py-5 flex items-center justify-between w-full`}
      >
        <div className="w-1/3 flex items-center justify-start">
          <button
            onClick={(e) => {
              e.preventDefault();

              // pageTopRef.current.scrollIntoView({
              //   behavior: "smooth",
              //   // block: "start",
              //   // inline: "nearest",
              // });
              navigate("/");
            }}
            className={`${
              page === "dashboard" ? "text-white" : "text-purple-950"
            } transition-all flex rounded items-center justify-start space-x-2 hover:opacity-50`}
          >
            <svg
              className={`h-8 fill-current`}
              viewBox="0 0 71 77"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.5 0C23.1137 0 9.12673 5.36113 0 12.8333C0 40.3544 16.3655 64.4875 35.5 77C54.6345 64.4875 71 40.3544 71 12.8333C61.8733 5.36113 47.9153 0 35.5 0Z" />
              <path
                d="M35.5 9.67892C26.5503 9.67892 16.444 13.36 9.84949 18.4906C9.84949 37.3873 21.6744 53.9576 35.5 62.549C49.3257 53.9576 61.1505 37.3873 61.1505 18.4906C54.556 13.36 44.4707 9.67892 35.5 9.67892Z"
                fill={page === "dashboard" ? "rgb(59, 7, 100)" : "white"}
              />
              <path d="M27.9557 49.5809C31.9793 50.5784 40.0265 50.5784 41.0325 47.087L7.20621 25.1409H6.83189C5.39752 36.4862 6.43686 30.4299 13.3701 42.0993C13.3701 42.0993 23.9321 48.5833 27.9557 49.5809Z" />
              <path d="M36.5059 20.1532C44.0501 20.1532 62.6595 27.6348 62.6595 27.6348L64.1684 33.6201L59.1387 38.1091L26.9498 23.6446C26.9498 23.6446 28.9617 20.1532 36.5059 20.1532Z" />
            </svg>
            <p className="text-xl font-semibold">StashCampus</p>
          </button>
        </div>
        {page === "login" || page === "signup" ? (
          <div className="hidden sm:block sm:static absolute top-20 sm:top-0 inset-x-0">
            {/* <button
              onClick={(e) => {
                if (page === "login") {
                  navigate("/signup");
                } else if (page === "signup") {
                  navigate("/login");
                }
              }}
              className="rounded-full group space-x-1 border-purple-950 border pl-3 pr-4 whitespace-nowrap w-full sm:w-72 h-8 bg-purple-50 flex items-center justify-center"
            >
              <p className=" tracking-wide">
                {page === "login" ? "Sign up" : "Login"} instead
              </p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </button> */}
          </div>
        ) : page === "bookSpace" || page === "createSpace" ? (
          <div className=" sm:static hidden top-20 sm:top-0 inset-x-0">
            <button className="rounded-full px-4 whitespace-nowrap w-full sm:w-72 h-8 bg-purple-50 flex items-center">
              <p className="w-1/3 text-left font-semibold tracking-wide">
                🥚 1/2
              </p>
              <p className="w-1/3 text-red-600 font-bold text-lg text-center">
                $4 off
              </p>
              <p className="w-1/3 text-right font-semibold tracking-wide">
                04:16:00
              </p>
            </button>
          </div>
        ) : page === "faq" ? (
          <div className="hidden sm:block sm:static absolute top-20 sm:top-0 inset-x-0">
            <button
              onClick={(e) => {
                navigate("/bookSpace");
              }}
              className="rounded-full group space-x-1 border-purple-950 border-2 pl-3 pr-4 whitespace-nowrap w-full sm:w-72 h-8 bg-purple-50 flex items-center justify-center"
            >
              <p className=" tracking-wide">Start your booking</p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </button>
          </div>
        ) : page === "account" ? (
          <div className="hidden sm:block sm:static absolute top-20 sm:top-0 inset-x-0">
            <button
              onClick={(e) => {
                navigate("/dashboard");
              }}
              className="rounded-full group space-x-1 border-purple-950 border-2 pl-3 pr-4 whitespace-nowrap w-full sm:w-72 h-8 bg-purple-50 flex items-center justify-center"
            >
              <p className=" tracking-wide">Dashboard</p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </button>
          </div>
        ) : page === "dashboard" ? (
          <div className=" sm:static absolute top-20 sm:top-0 inset-x-0">
            <div className="rounded-full relative p-0.5 whitespace-nowrap w-full sm:w-72 h-12 short:h-10 sm:h-8 bg-purple-50 flex items-center">
              <div
                className={`${
                  !shouldShowBookings
                    ? "right-0.5 bg-purple-950"
                    : "left-0.5 bg-purple-950"
                } inset-y-0.5 z-10 transform  w-1/2 absolute rounded-full`}
              ></div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldShowBookings(true);
                  navigate(`/dashboard/bookings`);
                }}
                className={`${
                  shouldShowBookings ? " text-white" : "hover:text-opacity-50"
                } z-10 w-full h-full  transition-all rounded-full text-purple-950 text-center font-semibold tracking-wide`}
              >
                Bookings
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldShowBookings(false);
                  navigate(`/dashboard/earnings`);
                }}
                className={`${
                  !shouldShowBookings ? " text-white" : "hover:text-opacity-50"
                } z-10 w-full h-full transition-all text-purple-950 text-center font-semibold tracking-wide`}
              >
                Earnings
              </button>
            </div>
          </div>
        ) : (
          <div className="hidden items-center justify-center space-x-10 lg:flex">
            <button
              className="font-semibold hover:opacity-50 transition-all"
              onClick={() => {
                navigate("/product");
              }}
            >
              Product
            </button>

            <button
              className="font-semibold hover:opacity-50 transition-all"
              onClick={() => {
                if (pricingRef && pricingRef.current) {
                  pricingRef.current.scrollIntoView({
                    behavior: "smooth",
                    // block: "start",
                    // inline: "nearest",
                  });
                } else {
                  navigate("/pricing");
                }
              }}
            >
              Pricing
            </button>
            {/* <button
              className="font-semibold hover:opacity-50 transition-all"
              onClick={() => {
                if (resourcesRef && resourcesRef.current) {
                  resourcesRef.current.scrollIntoView({
                    behavior: 'smooth',
                    // block: "start",
                    // inline: "nearest",
                  })
                } else {
                  navigate('/reviews')
                }
              }}
            >
              Reviews
            </button> */}
            <a
              className="font-semibold hover:opacity-50 transition-all"
              href="https://instagram.com/stashcampus"
              target="_blank"
              rel="noreferrer"
            >
              Community
            </a>
            <button
              className="font-semibold hover:opacity-50 transition-all"
              onClick={() => navigate("/faq")}
            >
              FAQ
            </button>
          </div>
        )}

        <div className="w-1/3 flex items-center justify-end">
          {customer ? (
            <button
              onClick={(e) => {
                e.preventDefault();

                if (page === "dashboard") {
                  navigate("/account");
                } else {
                  navigate("/dashboard");
                }
              }}
              className={`p-1 rounded-full ${
                page !== "dashboard" ? "bg-purple-950" : "bg-light-beige"
              } h-8 aspect-square hover:opacity-80 transition-all`}
            >
              <div className="items-center justify-center bg-white text-purple-950 rounded-full h-full aspect-square flex">
                {values.profilePicUrl ? (
                  <img
                    src={values.profilePicUrl}
                    className="border-white bg-white w-full aspect-square object-cover rounded-full "
                    alt={"Profile"}
                  />
                ) : (
                  <>{values.firstName ? values.firstName[0] : "S"}</>
                )}
              </div>
            </button>
          ) : !shouldHideLogin ? (
            <button
              onClick={(e) => {
                if (page === "login") {
                  navigate("/signup");
                } else if (page === "signup") {
                  navigate("/login");
                } else if (customer) {
                  navigate("/dashboard");
                } else {
                  navigate("/login");
                }
              }}
              className="group flex-shrink-0 hidden lg:flex hover:bg-opacity-90 text-white transition-all items-center justify-center space-x-2 bg-purple-950 rounded-lg backdrop-blur-sm pl-4 pr-3 py-1"
            >
              <p className="font-bold">
                {page === "login"
                  ? "Sign up instead"
                  : page === "signup"
                  ? "Sign in instead"
                  : customer
                  ? "Dashboard"
                  : "Sign in"}
              </p>
              <svg
                className="h-2.5 fill-current"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                  d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
                />
                <rect
                  className="opacity-0 group-hover:opacity-100 transition-all"
                  y="4"
                  width="9"
                  height="2"
                />
              </svg>
            </button>
          ) : (
            <></>
          )}
          {!customer && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsFirstTime(false);
                setShouldShowMiniNav(true);
              }}
              className={`${
                page === "landing" || page === "dashboard"
                  ? "bg-opacity-20 text-white "
                  : "bg-opacity-50 text-purple-400 "
              } group flex-shrink-0 lg:hidden flex hover:bg-opacity-30 transition-all items-center justify-center space-x-2 bg-white rounded-lg backdrop-blur-sm px-4 py-2.5`}
            >
              <svg
                className="h-3 stroke-current"
                viewBox="0 0 28 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="1" y1="1" x2="27" y2="1" strokeWidth="3" />
                <line x1="1" y1="8" x2="27" y2="8" strokeWidth="3" />
                <line x1="1" y1="15" x2="27" y2="15" strokeWidth="3" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
