import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { customerClient } from "../GraphqlApolloClients";
import { CustomerAuthContext } from "../context/customerAuth";
import { GuestAuthContext } from "../context/guestAuth";
import {
  FETCH_CUSTOMER,
  GET_BOOKING_BY_ID,
  GET_PAYMENT_BY_BOOKING,
} from "../pages/BookSpace";
import SubmitButton from "./SubmitButton";

export default function CodeForm({
  title,
  name,
  isCodeBoxOpen,
  setIsCodeBoxOpen,
  errors,
  values,
  setValues,
  onChange,
  typeOfCode,
  setErrors,
}) {
  const { guest, logoutGuest } = useContext(GuestAuthContext);

  const { loginCustomer } = useContext(CustomerAuthContext);
  const [hasBeenOnOnce, setHasBeenOnOnce] = useState(false);

  const [verifyCustomer, { loading: loadingVerifyCustomer }] = useMutation(
    VERIFY_CUSTOMER,
    {
      refetchQueries: [{ query: FETCH_CUSTOMER }],
      update(proxy, { data: { verifyCustomer: tokenizedCustomer } }) {
        if (guest) {
          logoutGuest();
        }
        loginCustomer(tokenizedCustomer);

        setValues({ ...values, verified: true });

        setIsCodeBoxOpen(false);
      },
      onError(err) {
        console.log(err);
        console.log("verifyCustomer() error!");

        setValues({ ...values, verified: false, verificationKey: "" });

        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0]);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: {
        email: values.email,
        verificationKey: values.verificationKey,
      },
      client: customerClient,
    }
  );
  function verifyCustomerCallback() {
    setErrors({});
    verifyCustomer();
  }

  const [
    applyReferralCode,
    // { loading: loadingApplyReferralCode },
  ] = useMutation(APPLY_REFERRAL_CODE, {
    refetchQueries: [
      {
        query: GET_BOOKING_BY_ID,
        variables: { bookingId: values.bookingId },
      },
      {
        query: GET_PAYMENT_BY_BOOKING,
        variables: { bookingId: values.bookingId },
      },
    ],
    update(proxy, { data: { applyReferralCode: appliedReferral } }) {
      console.log("applyReferralCode() success!");
      setIsCodeBoxOpen(false);
    },
    onError(err) {
      console.log("applyReferralCode() error!");

      setValues({ ...values, referralCode: "" });

      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: {
      bookingId: values.bookingId,
      referralCode: values.referralCode,
    },
    client: customerClient,
  });

  useEffect(() => {
    if (isCodeBoxOpen && !hasBeenOnOnce) {
      setHasBeenOnOnce(true);
    }
  }, [isCodeBoxOpen, hasBeenOnOnce]);
  const firstCharRef = useRef();
  const secondCharRef = useRef();
  const thirdCharRef = useRef();
  const fourthCharRef = useRef();
  const fifthCharRef = useRef();
  const sixthCharRef = useRef();
  const inputRefs = [
    firstCharRef,
    secondCharRef,
    thirdCharRef,
    fourthCharRef,
    fifthCharRef,
    sixthCharRef,
  ];
  const [initializedCodeModal, setInitializedCodeModal] = useState(false);
  const handleInputChange = (event, index) => {
    var newValue = event.target.value ? event.target.value.trim() : " ";
    // console.log(newValue)
    if (
      values[name] &&
      values[name][index] &&
      values[name][index] !== " " &&
      newValue.length > 1 &&
      newValue &&
      newValue !== " "
    ) {
      var tempVal =
        newValue.length === 2 && newValue[0] === newValue[1] ? newValue[0] : "";
      // console.log(values[name][index]);
      newValue = newValue.split(values[name][index]).join("") || tempVal;
      // console.log(newValue);
    }
    // Update the values in your state or wherever you store them
    const updatedValues = [...values[name].split("")];
    updatedValues[index] = newValue;

    // Update the state with the new values
    setValues({
      ...values,
      [name]: updatedValues.join("").slice(0, 6).toUpperCase(),
    });

    // Move focus to the next input if available
    if (newValue && newValue !== " " && index < 5) {
      const nextInputRef = index < 5 ? inputRefs[index + 1] : null;
      if (nextInputRef) {
        nextInputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (!initializedCodeModal && isCodeBoxOpen) {
      if (firstCharRef.current) {
        firstCharRef.current.focus();
      }

      setInitializedCodeModal(true);
    }
  }, [initializedCodeModal, isCodeBoxOpen, typeOfCode, firstCharRef]);

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const formattedPastedText = pastedText.slice(0, 6).toUpperCase(); // Restrict to the first 6 characters

    if (formattedPastedText && formattedPastedText.length === 6) {
      setValues({ ...values, [name]: formattedPastedText });
    }

    setErrors({});
    if (!typeOfCode) {
      /* typeOfCode = 0 => verifyCustomer(). */
      verifyCustomer({
        variables: {
          email: values.email,
          verificationKey: formattedPastedText,
        },
      });
    } else {
      /* typeOfCode = 1 => applyReferralCode(). */
      applyReferralCode({
        variables: {
          bookingId: values.bookingId,
          referralCode: formattedPastedText,
        },
      });
    }
  };

  return (
    <div
      className={`pointer-events-none fixed pt-24 inset-0 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsCodeBoxOpen(false);
        }}
        className={`${
          isCodeBoxOpen
            ? "motion-safe:animate-fadeInFast"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutFast"
            : "hidden"
        } pointer-events-auto bg-black/60 hide-button-flash overscroll-contain motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute`}
      />
      <div
        className={`${
          isCodeBoxOpen
            ? "motion-safe:animate-fadeUpFast pointer-events-auto"
            : hasBeenOnOnce
            ? "motion-safe:animate-fadeOutDownFast pointer-events-none"
            : "hidden pointer-events-none"
        } sm:h-auto  overflow-x-hidden max-h-full flex-shrink overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl short:p-4 p-8 sm:p-10 bg-white shadow-xl rounded-b-none sm:rounded-3xl`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsCodeBoxOpen(false);
          }}
          className="p-2 short:p-1 right-4 short:top-2 short:right-2 top-4 absolute bg-purple-100 hover:bg-purple-200 transition-all rounded-full z-20"
        >
          <svg
            className="w-4 fill-current text-gray-700"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" />
          </svg>
        </button>

        <p className="text-purple-900 z-10 text-4xl text-center font-bold w-full">
          {title.split(" (optional)")[0]}
        </p>
        {typeOfCode === 0 ? (
          <p
            className={`text-gray-600 text-center leading-tight w-full  text-sm sm:text-base z-40 mt-2 sm:mb-0`}
          >
            Please enter the 6-character code we sent to your school email.
            <br /> It may take a couple minutes to get the code so hang tight!
          </p>
        ) : (
          <p
            className={`text-gray-600 max-w-sm m-auto text-center leading-tight w-full  text-sm sm:text-base z-40 mt-2 sm:mb-0`}
          >
            Enter the 6-character code your friend gave you to unlock $10 off
            for both of you
          </p>
        )}

        <svg
          className="w-full z-0 absolute pointer-events-none inset-x-0 bottom-0"
          viewBox="0 0 568 460"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 432H5C12.1797 432 18 437.82 18 445V450C18 454.971 13.9706 459 9 459V459C4.02944 459 0 454.971 0 450V432Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 17C470 7.61116 477.611 0 487 0H568V192H487C477.611 192 470 184.389 470 175V17Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="367"
            y="77"
            width="95.3484"
            height="176.71"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="303"
            y="261"
            width="158.818"
            height="197.88"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="230"
            y="344"
            width="65.32"
            height="115.212"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="82"
            y="401"
            width="140.087"
            height="57.606"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <rect
            x="26"
            y="423"
            width="48.4074"
            height="35.5517"
            rx="17"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
          <path
            d="M470 217C470 207.611 477.611 200 487 200H568V442C568 451.389 560.389 459 551 459H487C477.611 459 470 451.389 470 442V217Z"
            fill="#D9F8FF"
            fillOpacity="0.5"
          />
        </svg>

        <div className="flex flex-row uppercase items-start z-40 justify-center space-x-2 sm:space-x-4 mx-auto w-full mt-10 mb-4">
          {inputRefs.map((inputRef, index) => (
            <div className="w-16 h-16" key={index}>
              <input
                className="w-full h-full flex flex-col uppercase items-center justify-center text-center short:px-2 px-4 sm:px-5 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-purple-700"
                type="text"
                name=""
                id=""
                ref={inputRef}
                value={
                  values[name] && values[name][index] ? values[name][index] : ""
                }
                onChange={(event) => handleInputChange(event, index)}
                onKeyDown={(event) => {
                  if (
                    event.key === "Backspace" &&
                    (!event.target.value || event.target.value === " ") &&
                    index > 0
                  ) {
                    const prevInputRef = inputRefs[index - 1];
                    if (prevInputRef) {
                      prevInputRef.current.focus();
                    }
                  }
                }}
                onPaste={handlePaste}
              />
            </div>
          ))}
        </div>

        <SubmitButton
          onClickFunc={(e) => {
            console.log(typeOfCode);
            if (!typeOfCode) {
              verifyCustomerCallback();
            } else {
              applyReferralCode();
              // setIsCodeBoxOpen(false)
            }
          }}
          title={typeOfCode === 0 ? "Continue" : "Apply"}
          errors={errors}
          disabled={loadingVerifyCustomer}
        />

        <p
          className={`${
            errors ? "text-red-600" : "text-purple-900"
          } text-center leading-tight w-full  text-sm sm:text-base z-40 mt-4 sm:mb-0 mb-2`}
        >
          {errors[name] ? errors[name] : ""}
        </p>
      </div>
    </div>
  );
}

export const VERIFY_CUSTOMER = gql`
  mutation verifyCustomer($email: String!, $verificationKey: String!) {
    verifyCustomer(email: $email, verificationKey: $verificationKey) {
      id
      email
      ip
      country
      referralId
      firstName
      lastName
      hasSetPassword
      phoneNumber
      verified
      token
      campusId
      ogGuestId
      payPalEmail
      stripeCustomerId
      createdAt
    }
  }
`;

export const APPLY_REFERRAL_CODE = gql`
  mutation applyReferralCode($referralCode: String!, $bookingId: String!) {
    applyReferralCode(referralCode: $referralCode, bookingId: $bookingId) {
      id
      code
      customerIds
      createdAt
    }
  }
`;
